import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // axios_url: 'http://localhost:5003',
    // axios_url: 'https://api.yahome-test.com.tw/CSWebApi',
    axios_url: 'https://api.yahome-test.com.tw/CSWebAPI',
    // axios_url: 'http://220.133.235.3:5010',
    //axios_url: window.BaseUrl.axios_url,
    //axios_local_url: 'https://7401-211-21-243-31.ngrok-free.app',
    // axios_local_url: 'https://api.yahome-test.com.tw/CSWebAPINet8'
    axios_local_url:  'https://api.yahome-test.com.tw/CSWebAPINet8',

    isLoading: false,
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    LOADING(state, status) {
      state.isLoading = status;
    },
  },
  actions: {
    updateLoading(context, payload) {
      context.commit("LOADING", payload);
    },
  },
  modules: {
    
  }
})
