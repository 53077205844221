<template>
  <!-- 加入Touch Test -->
  <div @touchend="endTouch()">
    <!-- 公告 -->
    <DxPopup :visible="isInfoPopupVisible" :drag-enabled="true" :hide-on-outside-click="true" :close-on-outside-click="true"
      :show-close-button="true" :show-title="true" :width="500" :height="400" container=".dx-viewport" title="News"
      >
      <DxPosition my="center" />

      <!-- <DxToolbarItem widget="dxButton" location="after" toolbar="bottom" :options="closeInfoButtonOptions" /> -->
      <DxScrollView width="100%" height="100%">
        <div id="ctl00_MasterPage_Content_ctl00_xPopupControl_ReportInfo_CSD-1" class="dxpcContentPaddings_Glass"
          style="overflow: auto; margin-right: 0px">
          <table v-for="(item, imageIndex) in menus" :key="imageIndex" :imageIndex="item.name">
            <tbody>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span style="font-size: large; color: #000000">Subject({{imageIndex+1}}):
                    <span style="font-size: medium; color: #000000">{{item.name}}</span>
                    <p>
                      <span style="font-size: large; color: #000000">Infomation:</span><br />
                      <span style="font-size: small; color: #000000" v-html="item.description"></span>
                    </p>
                    <p>
                      <span style="font-size: small; color: #000000">Period: {{item.enddate}}</span>
                    </p>
                    <hr />                   
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DxScrollView>

      <!-- <DxToolbarItem>
        <div>Custom Item</div>
      </DxToolbarItem> -->
    </DxPopup>

    <!-- 錯誤訊息 -->
    <!-- 20230330 CCL* 右上角X Close button啟用 :show-close-button="false" -->
    <DxPopup :visible="isMsgVisible" :drag-enabled="true" :hide-on-outside-click="true" :close-on-outside-click="true" @hidden="hiddenPopup('isMsgVisible')"
      :show-close-button="true" :show-title="true" :width="500" :height="200" container=".dx-viewport"
      title="Warning">
      <DxPosition my="center" />

      <div id="ctl00_MasterPage_Content_ctl00_xPopupControl_ReportInfo_CSD-1" class="dxpcContentPaddings_Glass"
        style="overflow: auto; margin-right: 0px">
        <table>
          <tbody>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td>
                <img src="@/assets/img/Warning01.png" alt="" style="margin-right: 16px;">
              </td>

              <td>
                <!-- 20230616 CCL+ for Error Msg -->
                <span style="color: #000000">
                <p style="margin-top: 0px;" >
                  <span style="font-size: large; font-style:italic; font-weight:bold; color: #000000">{{ MsgTitle }}</span>
                </p>  
                <p style="margin-top: 0px;">                 
                  <span style="font-size: medium; color: #000000">{{MsgContent}}</span>
                </p>
                </span>     
                <!-- 20230630 CCL+ 加入OK Button 顯示完後,可進入Home頁面 --> 
                <!-- 20230810 CCL* 加上div,style改成float:right; -->
                <div>
                  <span style="float: right; margin-right: 26px;" >
                    <button v-if="isMsgOKBtnVisible" class="btn btn-primary" @click.prevent="LoginOK()">                
                      <img src="@/assets/img/ICO_OK.png" alt="">
                      OK
                    </button>
                </span>       
              </div>
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>
    </DxPopup>

    <!-- 忘記密碼 -->
    <DxPopup :visible="isForgetPassword" :drag-enabled="true" :hide-on-outside-click="true" :close-on-outside-click="true" @hidden="hiddenPopup('isForgetPassword')"
      :show-close-button="true" :show-title="true" :width="500" :height="450" container=".dx-viewport"
      title="Forgot Password">

      <div id="ctl00_MasterPage_Content_ctl00_xPopupControl_ReportInfo_CSD-1" class="dxpcContentPaddings_Glass"
        style="overflow: auto; margin-right: 0px">
        <form class="reset-password-form" @submit.prevent="onSubmitForgetPassword">
          <div class="dx-field" style="width: 53%">
              
            <div class="dx-field-value">
              <DxRadioGroup
                :items="priorities"
                :value="priorities[0]"
                layout="horizontal"
                @valueChanged="changeSelectionPriority"
              />
            </div>
          </div>

          <dx-form :form-data="forgetPasswordFormData">      
            <!-- 20230626 CCL- 取名錯誤 data-field="account:" --> 
            <dx-item v-if="SelectionPriority=='Account'"
              data-field="account"
              editor-type="dxTextBox"
              :editor-options="{ stylingMode: 'filled', mode: 'account' }"
            >
              <DxRequiredRule message="account is required" />        
              <dx-label :visible="true" location="left"/>
            </dx-item>
            
            <!-- 20230626 CCL- 取名錯誤 data-field="Email Address:" -->
            <dx-item v-else-if="SelectionPriority=='Email Address'"
              data-field="email"
              editor-type="dxTextBox"
              :editor-options="{ stylingMode: 'filled', mode: 'email' }"
            >
              <dx-required-rule message="Email is required" />
              <dx-email-rule message="Email is invalid" />
              <dx-label :visible="true" location="left"/>
            </dx-item>

            <dx-item css-class="errorMessage">
              <template #default>
                <div v-if="forgetPasswordErrorMessage.length > 0">
                  <strong class="text-danger">Information:</strong>
                  <p class="text-danger">{{ forgetPasswordErrorMessage }}</p>
                </div>
              </template>
            </dx-item>

            <dx-button-item horizontal-alignment="left">
              <dx-button-options
                :element-attr="{ class: 'submit-button' }"
                width="60%"
                type="default"
                template="resetTemplate"
                :use-submit-behavior="true"
              >
              </dx-button-options>
            </dx-button-item>

            <template #resetTemplate>
              <div>
                <span class="function-container">
                  <button type="submit" class="btn">
                    <img src="@/assets/img/ICO_OK.png" alt="">
                    Submit
                  </button>

                  <!--20230628 CCL- 無法阻止onsubmit event改用別種方式; button class="btn btn-danger" @click.prevent="hiddenPopup('isForgetPassword')" -->
                  <button class="btn btn-danger" @click.prevent="cancelClick">
                    <img src="@/assets/img/ICO_Cls.png" alt="">
                    Cancel
                  </button>
                </span>
              </div>
            </template>
          </dx-form>
        </form>
      </div>
    </DxPopup>

    <form class="login-form" @submit.prevent="onSubmit">
      <dx-form :form-data="formData" :disabled="loading">
        <!-- editor-options加入 value:'' 預設值,JS判斷才會一致用''來判斷為空,而非一開始為null -->
        <dx-item data-field="account" editor-type="dxTextBox"
          :editor-options="{ stylingMode: 'filled', placeholder: 'Account', value: ''  }">
          <!-- <dx-required-rule message="Email is required" /> -->
          <!-- <dx-email-rule message="Email is invalid" /> -->
          <dx-label :visible="false" />
        </dx-item>
        <!-- editor-options加入 value:'' 預設值,JS判斷才會一致用''來判斷為空,而非一開始為null -->
        <dx-item data-field='password' editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password', value: ''  }">
          <!-- <dx-required-rule message="Password is required" /> -->
          <dx-label :visible="false" />
        </dx-item>

        <DxGroupItem css-class="btn-container"> 

          <dx-item >
            <div style="justify-content: start;">
              <span>
                <a  href="www.google.com" >Forgot Password</a>
              </span>
            </div>
          </dx-item>

          <dx-button-item css-class="btn-group">
            <dx-button-options width="100%" type="default" template="btn-group">
            </dx-button-options>
          </dx-button-item>
        </DxGroupItem>
      
        <dx-button-item css-class="eService-btn" horizontal-alignment="left">
          <dx-button-options width="30%" type="default" template="eService" :onClick="goToeService">
          </dx-button-options>
        </dx-button-item>

        <template #eService>
          <div>
            <span class="dx-button-text">
              <img src="@/assets/img/Icon_New.png" alt="">
              <span>eService</span>
            </span>
          </div>
        </template>

        <template #btn-group>
          <div>
            <span class="function-container">
              <span @click.prevent="openForgetPassword">Forgot Password</span>

              <button type="submit" class="btn">
                <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
                <div v-else style="display: flex;align-items: center;">
                  <img src="@/assets/img/ICO_OK.png" alt="">
                  Login
                </div>
              </button>
            </span>
          </div>
        </template>
      </dx-form>
    </form>
  </div>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DxForm, {
  DxItem,
  // DxEmailRule,
  // DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxGroupItem,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/form";
import DxRadioGroup from 'devextreme-vue/radio-group';
import notify from 'devextreme/ui/notify';

// import auth from "../auth";
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);


export default {
  data() {
    return {      
      formData: {},
      LoginUserinfo:{},
      loading: false,
      isInfoPopupVisible: false,
      isMsgVisible: false,
      isForgetPassword: false,
      MsgContent:'',
      MsgTitle:'', //先行判斷當Account 和 Password為空, 跳出Message Box
      isSupportTouch: false,  //Load window.navigator.userAgent ; Detect Touch
      isPushCancelBtn: false, //20230628 CCL+ 無法阻止onsubmit event改用別種方式;
      isMsgOKBtnVisible: false, //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面
      closeInfoButtonOptions: {
        text: "Close",
        onClick: () => {
          this.isInfoPopupVisible = false;
        },
      },
      closeMsgButtonOptions: {
        text: "Close",
        onClick: () => {
          this.isMsgVisible = false;
          this.MsgContent='';
        },
      },
      menus: [],

      priorities :['Account', 'Email Address'],
      SelectionPriority:'Account',
      forgetPasswordFormData: {},
      forgetPasswordErrorMessage: '',
    };
  },
  mounted() {
    //sessionStorage.setItem("LoginUserinfo",null);
    sessionStorage.clear();
    //this.$store.state.axios_url=this.$store.state.msetting.axios_url;
    this.LoadNews();
    //加入Loading userAgent and save to localstorage
    this.LoadUserAgent();
  },
  methods: {
    onCreateAccountClick() {
      this.$router.push("/create-account");
    },
    onSubmit: async function () {
      this.Login();
      //notify('result.message', "ok", 2000);

      //this.$router.push("/home");

      // const { email, password } = this.formData;
      // this.loading = true;

      // const result = await auth.logIn(email, password);
      // if (!result.isOk) {
      //   this.loading = false;
      //   notify(result.message, "error", 2000);
      // } else {
      //   this.$router.push(this.$route.query.redirect || "/home");
      // }
    },
    // async Load() {

    //   const config = {
    //     headers: {
    //       Authorization: 'bearer ' + sessionStorage.getItem("token"),
    //     }
    //   };
    //   // const bodyParameters = {
    //   //   title: '演講公告'
    //   // };
    //   axios.get(this.$store.state.axios_url + "/api/FrontNews/GetAll", config)
    //     .then((resp) => {
    //       console.log(resp.data);
    //       if (resp.data.success) {
    //         this.menus = resp.data.payload;
    //       }
    //       else {
    //         console.log(resp.data.message);
    //       }


    //     }).catch(err => {
    //       console.log("error " + err);
    //       //this.$router.push('/');
    //     });
    // },
    async LoadNews() {
      //
      const config = {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem("token"),
        }
      };
      // const bodyParameters = {
      //   title: '演講公告'
      // };
      //console.log("connect:"+this.$store.state.msetting.axios_url);
      // let date = new Date();
      // let getdate=date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours() ).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2);
     
      // let mysetting=require("/public/setting.json"); 
      // console.log("require:"+mysetting.axios_url + ' '+ getdate);
      console.log(this.$store.state.axios_url + "/api/Login/getNews");
      axios.post(this.$store.state.axios_url + "/api/Login/getNews", config)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data.success) {
            this.menus = resp.data.payload;
            if(this.menus.length>0){
              this.isInfoPopupVisible = true;
            }
            
          }
          else {
            console.log(resp.data.message);
          }
        }).catch(err => {
          console.log("error " + err);
          //this.$router.push('/');
        });
    },
    async Login() {
      //alert(this.$store.state.axios_url);
      const config = {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem("token"),
        }
      };
      const bodyParameters = {
        ACCOUNT: this.formData.account,
        PASSWORD: await this.encryption_password(this.formData.password), 
      };
      
      //先行判斷當Account 和 Password為空, 跳出Message Box
      if( this.formData.account == '')
      {
            //沒有輸入帳號
            this.MsgTitle = 'Invalid Account Specified:';
            this.MsgContent='Please enter your Account you registered with.';
            //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
            this.isMsgOKBtnVisible=false; //預設不顯示OK Button
            this.isMsgVisible=true;
            
            //clear status
            this.formData.account = '';
            this.formData.password = '';

      } else if( this.formData.password == '')
      {
            //有輸入帳號,沒輸入密碼
            this.MsgTitle = 'Invalid Password Specified:';
            this.MsgContent='Please enter your Password you registered with.';
            //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
            this.isMsgOKBtnVisible=false; //預設不顯示OK Button
            this.isMsgVisible=true;
            
            //clear status
            this.formData.account = '';
            this.formData.password = '';
      }
      else 
      {
        axios.post(this.$store.state.axios_url + "/api/Login/Login", bodyParameters, config)
          .then((resp) => {
            console.log(resp.data);
            //20230907 CCL* 加上儲存從後端取回的Token
            var apiRet = resp.data.apiRet;
            //20230907 CCL- if (resp.data.success) {
            if (apiRet.success) {
              this.LoginUserinfo = apiRet.payload; //20230907 CCL* 加上儲存從後端取回的Token //resp.data.payload;
              sessionStorage.setItem("LoginUserinfo",JSON.stringify(this.LoginUserinfo));
              //console.log(JSON.parse(sessionStorage.getItem("LoginUserinfo")));
              //notify(this.LoginUserinfo.msgFlag, "ok", 2000);

              //20230907 CCL+ 加上儲存從後端取回的Token
              this.token = resp.data.token;
              sessionStorage.setItem("token", this.token);

              if(this.LoginUserinfo.msgFlag===0)
              {
                this.$router.push("/home");
              }              
              else if(this.LoginUserinfo.msgFlag===1)
              {
                //密碼錯誤
                this.MsgTitle = 'Invalid Password Specified:';
                this.MsgContent='Please enter your Password you registered with.';

                //this.MsgContent='Login Failed';
                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
                this.isMsgOKBtnVisible=false; //預設不顯示OK Button
                this.isMsgVisible=true;
                
                //notify({ message: 'Login Failed' , position: 'top center',direction: 'down-stack'},"error",3000);

                //clear status
                this.formData.account = '';
                this.formData.password = '';
          
              }
              else if(this.LoginUserinfo.msgFlag===2)
              {

                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面
                var expiredatestr = '[' + this.getExpiredDate(6,7) + ']'; //超過6個月+7天後    

                //密碼在變更通知期間內
                this.MsgTitle = '';
                //20230630 CCL- this.MsgContent='Please modify your Password due to its expiration date will be out of date.';
                this.MsgContent='Please modify your Password due to its expiration date will be out of date' + 
                              ' on ' + expiredatestr + '. '; //20230630 CCL* 改成顯示訊息就好,不導向更新密碼頁面

                //this.MsgContent='start Alert Change Password';
                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
                this.isMsgOKBtnVisible=true; //顯示OK Button  
                this.isMsgVisible=true;

                //clear status
                this.formData.account = '';
                this.formData.password = '';
                //notify({ message: 'start Alert Change Password' , position: 'top center',direction: 'down-stack'},"error",3000);
                //20230630 CCL- 改成顯示訊息就好,不導向更新密碼頁面 this.$router.push("/change-password/1"); 

              }
              else if(this.LoginUserinfo.msgFlag===3)
              {
                //密碼超過變更期限
                this.MsgTitle = '';
                this.MsgContent='The given Account had been locked due to the expiration date of Password was out of date, please contact with Administrator.';

                //this.MsgContent='Account Locked';
                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
                this.isMsgOKBtnVisible=false; //預設不顯示OK Button
                this.isMsgVisible=true;
                //notify({ message: 'Account Locked' , position: 'top center',direction: 'down-stack'},"error",3000);
          
                //clear status
                this.formData.account = '';
                this.formData.password = '';

              }
              else if(this.LoginUserinfo.msgFlag===4)
              {
                //
                this.MsgTitle = '';

                this.MsgContent='imposible';
                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
                this.isMsgOKBtnVisible=false; //預設不顯示OK Button
                this.isMsgVisible=true;
                //notify({ message: 'imposible' , position: 'top center',direction: 'down-stack'},"error",3000);
          
                //clear status
                this.formData.account = '';
                this.formData.password = '';        

              }
              else if(this.LoginUserinfo.msgFlag===9)
              {     
                //帳號不存在
                this.MsgTitle = 'Invalid Account Specified:';
                this.MsgContent='Please enter your Account you registered with.';
              
                
                //this.MsgContent='no this account';
                //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
                this.isMsgOKBtnVisible=false; //預設不顯示OK Button
                this.isMsgVisible=true;  
                //notify({ message: 'no this account' , position: 'top center',direction: 'down-stack'},"error",3000);
                
                //clear status
                this.formData.account = '';
                this.formData.password = '';  

              }

            }
            else {
              notify({ message: resp.data.message , position: 'top center',direction: 'down-stack'},"error",3000);
          
              console.log(resp.data.message);
            }
          }).catch(err => {
            console.log("error " + err);
            //this.$router.push('/');
          });

      }

    },

    //20230630 CCL+ 改成顯示訊息就好,不導向更新密碼頁面
    LoginOK() {
      //20230630 CCL- 改成顯示訊息就好,不導向更新密碼頁面, 並且多一個OK Button就好
      this.isMsgOKBtnVisible=false; //Hidden OK Button
      //20230630 CCL- 改成顯示訊息就好,導向Home頁面
      this.$router.push("/home");

    },
    getExpiredDate(expiremonth, maxexpireday) {
      let dateOri = new Date(new Date().setMonth(new Date().getMonth() + expiremonth));
      dateOri.setDate(dateOri.getDate() + maxexpireday);    //超過6個月+7天後 

      let inDate = new Date(dateOri);
      //                     1   2   3   4   5   6   7   8   9  10  11  12月
      let daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let strYear = inDate.getFullYear();
      let strMonth = inDate.getMonth() ;
      let strDay = inDate.getDate();
      //一、解決閏年平年的二月份天數 //平年28天、閏年29天//能被4整除且不能被100整除的為閏年,或能被100整除且能被400整除
      if (((strYear % 4) === 0) && ((strYear % 100) !== 0) || ((strYear % 400) === 0)) {
          daysInMonth[2] = 29;
      }
      //二、解決跨年問題
      if (strMonth + 1 === 13)
      {
          strYear += 1;
          strMonth = 1;
      }
      else {
          strMonth += 1;
      }
      //三、解決當月最後一日，例如2.28的下一個月日期是3.31；6.30下一個月日期是7.31；3.31下一個月是4.30
      if (strMonth == 2 || strMonth == 4 || strMonth == 6 || strMonth == 9 || strMonth == 11) {
          strDay = Math.min(strDay, daysInMonth[strMonth]);
      }
      else {
          if (strDay >= 28) {
              strDay = Math.max(strDay, daysInMonth[strMonth]);
          }
          else {
              strDay = Math.min(strDay, daysInMonth[strMonth]);
          }
          
      }

      //四、給個位數的月、日補零
      if (strMonth < 10)
      {
          strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
          strDay = "0" + strDay;
      }
      let datastr = strYear + "/" + strMonth + "/" + strDay;
      return datastr;       
    },

    hiddenPopup(name) {
      //  關閉 Popup 會呼叫的
      this[name] = false;
    },

    goToeService() {
      location.assign('https://eservice.aseglobal.com/eService/');
    },
    openForgetPassword() {  //  打開忘記密碼的 modal
      //20230628 CCL+ 無法阻止onsubmit event改用別種方式;
      this.forgetPasswordFormData.account = null;
      this.forgetPasswordFormData.email = null;

      this.isForgetPassword = true
    },
    changeSelectionPriority(e) {  //  忘記密碼中切換所使用的項目
      this.SelectionPriority = e.value;
    },
    onSubmitForgetPassword: async function() {
      
      //20230628 CCL+ 無法阻止onsubmit event改用別種方式;
      if(this.isPushCancelBtn == true)
      {
        this.forgetPasswordFormData.account = null;
        this.forgetPasswordFormData.email = null;
        this.isPushCancelBtn = false; //當按下的是Cancel Button 就不送出Submit Request
        this.isForgetPassword = false;
        return false;
      } ///

      const { account,email } = this.forgetPasswordFormData;
      const result = await this.resetPassword(account,email);
      
      if(!result.success)
      {
        this.forgetPasswordErrorMessage = result.message;
      }
      else{  
        notify({ message: result.message , position: 'top center',direction: 'down-stack'},"success",3000);
      }
     
    },
    async resetPassword(account,email) {

      const config = {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem("token"),
        }
      };
      if(this.SelectionPriority=="Account")
      {
        email=null;
      }
      else{
        account=null;
      }

      const bodyParameters = {
        account: account,
        email: email
      };

      const result = await axios.post(this.$store.state.axios_url + "/api/Login/ForgetPassword", bodyParameters, config)

      return result.data;
      
    },
    //Load window.navigator.userAgent
    endTouch() {
        //有此事件代表Screen支持touch
        this.isSupportTouch =  true;
    },
    LoadUserAgent() {
        //先宣告local var
        var Mode = "";
        var Device = "";
        var OSName = "";
        var BrowserName = "Other";
        var BrowserVersion = "0";
        var Orientation = 0;
        var Screen = "";
        var OnTouch = false;
        var ua = "";
        var OSList = [];
        var ClientOSInfo = "";
        var screenWidth, screenHeight;

        //每次先清除localstorage old data
        localStorage.clear();
        //取得userAgent
        var useragentstr = window.navigator.userAgent.toLowerCase();

        if((useragentstr.indexOf("compatible") > -1) || (useragentstr.indexOf("macintosh") > -1))
        {
          //a.
          if(useragentstr.indexOf("compatible") > -1)
          {
            Device = "compatible";
          } else {
            Device = "macintosh";
          }
          Mode = "pc";

          
        } else if((useragentstr.indexOf("pad") > -1) || (useragentstr.indexOf("android") > -1))
        {
          //b.
          Device = "tablet";
          Mode = "mobile";
        } else if((useragentstr.indexOf("phone") > -1))
        {
          //c.
          Device = "phone";
          Mode = "mobile";
        } else {
          //d.都不符合上述a~c
          Device = "compatible";
          Mode = "pc";
        }

        //e.找出OS資訊
        ua = useragentstr;
        OSList = ["window", "android", "linux", "mac os", "iphone os"];
        ClientOSInfo = ua.substring(ua.indexOf('(') + 1, ua.indexOf(')')).split(";");

        for(var i=0; i<OSList.length; i++)
        {
          for(var j=0; j<ClientOSInfo.length; j++)
          {
            if(ClientOSInfo[j].indexOf(OSList[i]) > -1)
            {
              OSName = ClientOSInfo[j];
              break;
            }
          }
        }

        if(OSName == "") {
          OSName = "Other";
          Mode = "pc"
        }

        //f.找出BrowserName
        var userAgent = useragentstr;
        if(BrowserName == "Other" && Mode == "mobile" && /safari/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("safari/")+7);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "Safari";
        }

        if(BrowserName == "Other" && Mode == "pc" && /firefox/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("firefox/")+8);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "Firefox";
        }
        //test
        //if(BrowserName == "Other" && Mode == "pc" && /mozilla/.test(userAgent))
        //{
        //  userAgent = userAgent.substring(userAgent.indexOf("mozilla/")+8);
        //  userAgent = userAgent.substring(0, userAgent.indexOf("."));
        //  BrowserVersion = userAgent;
        //  BrowserName = "Mozilla";
        //}

        if(BrowserName == "Other" && Mode == "pc" && /opera/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("version/")+8);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "Opera";
          Mode = "pc";
        }

        if(BrowserName == "Other" && Mode == "pc" && /opr/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("opr/")+4);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "Opera";
          Mode = "pc";
        }

        if(BrowserName == "Other" && Mode == "pc" && /chrome/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("chrome/")+7);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "Chrome";
          Mode = "pc";
        }

        if(BrowserName == "Other" && Mode == "pc" && /.net/.test(userAgent))
        {
          userAgent = userAgent.substring(userAgent.indexOf("rv:")+3);
          userAgent = userAgent.substring(0, userAgent.indexOf("."));
          BrowserVersion = userAgent;
          BrowserName = "IE";          
        }

        BrowserName = window.navigator.platform.toLowerCase() + '-' + BrowserName;

        //g.找出方向 PS:window.orientation 目前瀏覽器已廢棄此屬性
        //Orientation = (window.orientation)? Math.abs(window.orientation) : 0;
        //|| window.matchMedia("(orientation: landscape)") 
        //|| window.matchMedia("(orientation: portrait)") 
        var tmporientation = "";
        tmporientation =  window.orientation 
                          || window.screen.orientation && window.screen.orientation.type
                          || window.mozOrientation || window.msOrientation ;
        //上面的Browser抓不到的話,改用新寫法matchMedia
        if(!tmporientation || tmporientation == "")
        {
          if(window.matchMedia("(orientation: landscape)").matches) {
            
            tmporientation = "landscape";
            
          } else {
            tmporientation = "portrait";
          }
        }
        Orientation = tmporientation;

        //h.找出Screen大小
        Screen = (window.screen && screen.width > screen.height)? "Wide":"High";

        //i. 改用Vue3的方式, 如果支援 @touchend="endTouch(item)事件,就代表支援Touch"
        //$.extend($.support, {touch: "ontouchend" in document});
        //OnTouch = ($.support.touch)? "True":"False";
        OnTouch = (this.isSupportTouch)? "True": "False";

        //j. IE8 X
        screenWidth = window.innerWidth; 
        //k.
        screenHeight = window.innerHeight;

        //紀錄至localStorage --> key: "UserAgentInfo"
        var userAgentInfo = {
          data: {
            success: true,
            message: '',
            payload: {
              Mode: Mode,
              Device: Device,
              OSName: OSName,
              BrowserName: BrowserName,
              BrowserVersion: BrowserVersion,
              Orientation: Orientation,
              Screen: Screen,
              OnTouch: OnTouch,
              ua: ua,
              screenWidth: screenWidth,
              screenHeight: screenHeight
            }
          }

        };

        localStorage.setItem("UserAgentInfo", JSON.stringify(userAgentInfo));
        
        //for Testing
        //let jsonobjstr = localStorage.getItem("UserAgentInfo"); //JSON.stringify(userAgentInfo);
        //let jsonobj = JSON.parse(jsonobjstr);
        //this.MsgContent = jsonobj.data.payload;
        //this.isMsgVisible=true;

    },
    //忘記密碼取消按鈕
  cancelClick(){
    this.isPushCancelBtn = true;
    this.isForgetPassword = false;
  }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxPopup,
    DxPosition,
    // DxToolbarItem,
    DxScrollView,
    DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxGroupItem,
    DxRadioGroup,
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.btn-container {
  border-bottom: 2px dashed #666;
}

.eService-btn {
  padding-top: 0 !important;

  .dx-button {
    background-color: transparent !important;
    color: #0000EE !important;
    box-shadow: none !important;

    .dx-button-text {
      display: flex;
      align-items: center;

      text-transform: none !important;
    }
  }
  
}

.btn-group {
  padding-top: 0 !important;

  .dx-button-content {
    padding: 0 !important;
  }

  .dx-button {
    background-color: transparent !important;
    color: #0000EE !important;
    box-shadow: none !important;
    cursor: default;

  
    .function-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      height: 100%;

      .btn {
        display: flex;
        align-items: center;

        color: #fff;
        background-color: #368d96;
        padding: 9px 25px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;

        img {
          margin-right: 6px;
        }
      }
    }
  }
  
}


.submit-button {
  background-color: transparent !important;
  color: #0000EE !important;
  box-shadow: none !important;

  .dx-button-content {
    padding: 0 !important;
    background-color: transparent !important;
    color: #0000EE !important;
    box-shadow: none !important;
    cursor: default;

  
    .function-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      height: 100%;

      .btn {
        display: flex;
        align-items: center;

        color: #fff;
        background-color: #368d96;
        padding: 9px 25px;
        border: none;
        border-radius: 4px !important;
        cursor: pointer;

        img {
          margin-right: 6px;
        }
      }

      .btn-danger {
        background-color: #912F2F;
      }

    }
  }
  
}


.reset-password-form {
  .submit-button {
    margin-top: 10px;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
}

.dx-field-value {
  flex: 1;

  border: 1px solid #7EACB1;
  padding: 6px 5px 6px 11px;
}

.errorMessage {
  padding: 0 !important;
}

.text-danger {
  color: #FF0000;
}


.dx-field-item-required-mark {
  display: none;
}

</style>
