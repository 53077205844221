import Vue from "vue";
import Router from "vue-router";

//import auth from "./auth";

// import Home from "./views/home-page";
// import Profile from "./views/profile-page";
// import Tasks from "./views/tasks-page";
import Login from "./views/login-page";
import Demo from "./views/cp-cycle-time-page";
import Demo2 from "./views/cp-grouping";
import Home from "./views/home-page";
//import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import loginLayout from "./layouts/loginLayout";
import customLayout from "./layouts/customLayout";
import Management from "./views/Management/management-page";
import CompanyList from "./views/Management/CompanyManagement/company-index-page";
import GroupInfo from "./views/Management/CompanyManagement/company-group-info-page";
import MetaFile from "./views/Management/CompanyManagement/company-metafile-index-page";
import MetafileNew from "./views/Management/CompanyManagement/company-metafile-add-page";
import MetafileEdit from "./views/Management/CompanyManagement/company-metafile-edit-page";
import CompanyReport from "./views/Management/CompanyManagement/company-report-authorization-page";

Vue.use(Router);

const router = new Router({
  routes: [
    // {
    //   path: "/home",
    //   name: "home",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Home
    //   }
    // },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Profile
    //   }
    // },
    // {
    //   path: "/tasks",
    //   name: "tasks",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Tasks
    //   }
    // },
    {
      path: "/management/company/report-authoriaztion",
      name: "companyReport",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: CompanyReport
      }
    },
    {
      path: "/management/company/metafile-edit",
      name: "metafileEdit",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: MetafileEdit
      }
    },
    {
      path: "/management/company/metafile-new",
      name: "metafileNew",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: MetafileNew
      }
    },
    {
      path: "/management/company/metafile",
      name: "metaFile",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: MetaFile
      }
    },
    {
      path: "/management/company/group-info",
      name: "groupInfo",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: GroupInfo
      }
    },
    {
      path: "/management/company/index",
      name: "companyList",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: CompanyList
      }
    },
    {
      path: "/management/index",
      name: "management",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: Management
      }
    },
    {
      path: "/demo",
      name: "demo",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: Demo
      }
    },
    {
      path: "/demo2",
      name: "demo2",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: Demo2
      }
    },
    {
      path: "/home",
      name: "home",
      meta: { requiresAuth: false },
      components: {
        layout: customLayout,
        content: Home
      }
    },
    {
      path: "/login",
      name: "login",
      meta: { requiresAuth: false },
      components: {
        layout: loginLayout,
        content: Login
      }
    },
    // {
    //   path: "/login-form",
    //   name: "login-form",
    //   meta: { requiresAuth: false },
    //   components: {
    //     layout: simpleLayout,
    //     content: () =>
    //       import(/* webpackChunkName: "login" */ "./views/login-form")
    //   },
    //   props: {
    //     layout: {
    //       title: "Sign In"
    //     }
    //   }
    // },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/reset-password-form")
      },
      props: {
        layout: {
          title: "Forget Password",
          description: ""
        }
      }
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/create-account-form")
      },
      props: {
        layout: {
          title: "Sign Up"
        }
      }
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/change-password-form")
      },
      props: {
        layout: {
          title: "Change Password"
        }
      }
    },
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: "/recovery",
      redirect: "/login"
    }
  ]
});

// router.beforeEach((to, from, next) => {
  // if (to.name === "login" && auth.loggedIn()) {
    // next({ name: "demo" });
  // }
  
  // const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // if (requiresAuth && !auth.loggedIn()){
    // next({ name: "login" });
  // } else {
    // next();
  // }
// });

export default router;
