<template>
  <div>
    <isLoading :active.sync="isLoading" loader="dots"></isLoading>
    <div class="" style="padding: 0 50px">
      <div class="pivotMenu-block">
        <dxMenu
          id="menu"
          cssClass="pivotMenu"
          :data-source="menus"
          :key="i"
          item-template="item"
          :show-first-submenu-mode="showSubmenuModes"
          :orientation="orientation"
          :hide-submenu-on-mouse-leave="hideSubmenuOnMouseLeave"
          display-expr="name"
          :selectByClick="true"
          ref="menu"
          :onSubmenuShown="onSubmenuShown"
        >
          <DxItem> </DxItem>
          <template #item="{ data }">
            <div>
              <span
                @click="changeSelect(data.name)"
                v-if="data.name !== 'selectMenu'"
              >
                <img width="15" :src="data.icon" alt="" />
                {{ data.name }}
              </span>

              <span v-else>
                <DxRadioGroup
                  :items="summaryTypeList"
                  :value="data.value"
                  cssclass="sumaryChild"
                  @valueChanged="
                    (e) => {
                      changeSelectionPriority(e, data.parentItem);
                    }
                  "
                />
              </span>
            </div>
          </template>
        </dxMenu>
      </div>

      <!-- 左邊設定區塊 -->
      <div class="pivotGrid-block">
        <div class="left-block">
          <div class="dxpgCustForm_Glass">
            <div class="change-mode">
              <button @click="openMode">
                <span></span>
              </button>
            </div>

            <!-- 20230801 CCL+加上ref 好操作該物件 -->
            <DxPivotGridFieldChooser
              ref="gridfieldchooer"
              :data-source="dataSource"
              :width="300"
              :height="400"
              :layout="layout"
              :apply-changes-mode="
                applyChangesMode.length > 0 ? 'onDemand' : 'instantly'
              "
              @optionChanged="chooserChange"
            >
              <DxTexts
                all-fields="Hidden Fields"
                column-fields="Columns"
                data-fields="Data"
                row-fields="Rows"
                filter-fields="Filter"
              />
            </DxPivotGridFieldChooser>
          </div>

          <!-- 20230731 CCL+ 加入 showRowSubTol,showColSubTol功能  -->
          <div class="item-list">
            <label>
              <!-- input type="checkbox" / -->
              <input type="checkbox" value="true" v-model="showRowSubTol" />
              Show Row Sub Total
            </label>

            <label>
              <!-- input type="checkbox" / -->
              <input type="checkbox" vlaue="true" v-model="showColSubTol" />
              Show Column Sub Total
            </label>

            <div>
              <label>
                <input
                  type="checkbox"
                  value="onDemand"
                  v-model="applyChangesMode"
                />
                Defer Layout Update
              </label>

              <div style="margin-top: 16px" v-if="applyChangesMode.length > 0">
                <button class="update-btn" @click.prevent="applyClick()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-block">
          <!-- 樞紐區塊 -->
          <DxPivotGrid
            v-if="applyChangesMode.length > 0"
            ref="grid"
            :allow-sorting-by-summary="true"
            :allow-sorting="true"
            :allow-filtering="true"
            :show-borders="true"
            :data-source="dataSource"
            :show-row-totals="isShowRowTols"
            :show-column-totals="isShowColTols"
            :show-row-grand-totals="isShowRowTols"
            :show-column-grand-totals="isShowColTols"
            :wordWrapEnabled="true"
            width="1300"
            height="600"
          >
            <DxFieldChooser :enabled="false" />
            <DxScrolling mode="virtual" />
          </DxPivotGrid>

          <DxPivotGrid
            v-else
            ref="grid"
            :allow-sorting-by-summary="true"
            :allow-sorting="true"
            :allow-filtering="true"
            :show-borders="true"
            :data-source="dataSource"
            :show-row-totals="showRowSubTol"
            :show-column-totals="showColSubTol"
            :show-row-grand-totals="showRowSubTol"
            :show-column-grand-totals="showColSubTol"
            :wordWrapEnabled="true"
            width="1300"
            height="600"
          >
            <DxFieldChooser :enabled="false" />
            <DxScrolling mode="virtual" />
          </DxPivotGrid>
        </div>
      </div>
    </div>

    <!-- 左邊設定 模式視窗 options -->
    <DxPopup
      :visible="isPopupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :show-close-button="true"
      @hidden="hiddenPopup('isPopupVisible')"
      :show-title="true"
      :width="300"
      height="auto"
      container=".dx-viewport"
      title="options"
    >
      <DxPosition my="center" />

      <div class="option">
        <label
          class="layout-option"
          v-for="(option, index) in layouts"
          :key="index"
        >
          <input v-model="layout" :value="option.key" type="radio" />
          <div class="layout-img" :class="option.name"></div>

          <!--20230818 CCL* 調整layout顯示順序及預設值 -->
          <span v-if="index === 0"
            >Fields Section and Areas Section Stacked</span
          >
          <span v-if="index === 1"
            >Fields Section and Areas Section Side-By-Side</span
          >
          <span v-if="index === 2">Areas Section Only (2 by 2)</span>
        </label>
      </div>
    </DxPopup>

    <!-- 圖表視窗 Chart -->
    <DxPopup
      :visible="isChartPopupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      @hidden="hiddenPopup('isChartPopupVisible')"
      :show-title="true"
      height="auto"
      container=".dx-viewport"
      title="Header"
    >
      <DxPosition my="center" />

      <div class="type-setting">
        <div class="dx-field-label">Chart Type:</div>
        <select v-model="chartType">
          <option
            v-for="singlechartType in chartTypeList"
            :key="singlechartType.label"
            :value="singlechartType.value"
          >
            {{ singlechartType.label }}
          </option>
        </select>
      </div>

      <div class="settings">
        <label for="GenerateSeriesfromColumns">
          <input
            type="checkbox"
            id="GenerateSeriesfromColumns"
            v-model="settings"
            value="GenerateSeriesfromColumns"
          />
          Generate Series from Columns
        </label>

        <label for="ShowRowGrandTotals">
          <input
            type="checkbox"
            id="ShowRowGrandTotals"
            v-model="settings"
            value="ShowRowGrandTotals"
          />
          Show Row Grand Totals
        </label>

        <label for="ShowColumnGrandTotals">
          <input
            type="checkbox"
            id="ShowColumnGrandTotals"
            v-model="settings"
            value="ShowColumnGrandTotals"
          />
          Show Column Grand Totals
        </label>

        <label for="ShowPointLabels">
          <input
            type="checkbox"
            id="ShowPointLabels"
            v-model="settings"
            value="ShowPointLabels"
          />
          Show Point Labels
        </label>

        <label for="ChangeY-AxisScaling">
          <input
            type="checkbox"
            id="ChangeY-AxisScaling"
            v-model="settings"
            value="ChangeY-AxisScaling"
          />
          Change Y-Axis Scaling
        </label>
        <div
          class="scaling"
          v-if="settings.indexOf('ChangeY-AxisScaling') !== -1"
        >
          <!-- 20230724 CCL* 加入Min,Max value  -->
          <input type="number" v-model="AxisRange_Min" />
          <span>~</span>
          <input type="number" v-model="AxisRange_Max" />
        </div>

        <div style="margin-top: 16px">
          <!-- 20230718 CCL+ 加上bind及各項屬性 -->
          <!-- 20230718 CCL- button class="update-btn">Update</button -->
          <button class="update-btn" @click="UpdateChart">Update</button>
        </div>
      </div>

      <!-- 20230718 CCL+ 加上bind及各項屬性 -->
      <!-- 20230719 CCL修正不立即雙向Model Binding,而是等到按下Update才設定 -->
      <div style="max-width: 800; overflow-y: auto; overflow-x: auto">
        <DxChart ref="chart" id="charts" style="width: 100% !important">
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
          <DxAdaptiveLayout :width="450" :keepLabels="isKeepPointLabels" />
          <DxSize :key="chartKey" :height="200" />
          <!--20230815 CCL+ DxLabel要改放在DxCommonSeriesSettings Tag內-->
          <!-- series 資料內容設定 -->
          <DxCommonSeriesSettings
            :type="selChartType"
            :showInLegend="true"
            :visible="true"
            state="cc"
          >
            <DxLabel
              :visible="isKeepPointLabels"
              background-color="#c18e92"
              position="outside"
            />
          </DxCommonSeriesSettings>
        </DxChart>
      </div>
    </DxPopup>

    <!-- 報表資訊視窗 Report Info -->
    <DxPopup
      :visible="isInfoPopupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :close-on-outside-click="true"
      @hidden="onQueryHidden('isInfoPopupVisible')"
      :show-title="true"
      :width="500"
      :height="400"
      container=".dx-viewport"
      title="Report Info"
    >
      <DxPosition my="center" />

      <!-- 20230731 CCL+ 3.7.1 Report Info:報表資訊 顯示 -->
      <DxScrollView
        id="scrollview"
        ref="scrollViewWidget"
        :scroll-by-content="scrollByContent"
        :scroll-by-thumb="scrollByThumb"
        :show-scrollbar="showReportInfoScrollbar"
        :bounce-enabled="pullDown"
        reach-bottom-text="Updating..."
      >
        <div
          id="ctl00_MasterPage_Content_ctl00_xPopupControl_ReportInfo_CSD-1"
          class="dxpcContentPaddings_Glass"
          style="overflow: auto; margin-right: 0px"
        >
          <table>
            <tbody>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <!--20230810 CCL* 拿掉寫死的Report ID CP_Cycle_Time -->
                  <span style="font-size: medium; color: #000000"
                    >Report ID:{{ reportid }}
                    <p>
                      <span style="font-size: medium; color: #000000"
                        >User Login ID:{{ LoginUserinfo.account }}</span
                      >
                    </p>
                    <p>
                      <!--20230731 CCL- span style="font-size: medium; color: #000000">User Role ID:3</span -->
                      <span style="font-size: medium; color: #000000"
                        >User Role ID:{{ LoginUserinfo.roleid }}</span
                      >
                    </p>
                    <p>
                      <span style="font-size: medium; color: #000000"
                        >User Company ID:{{ LoginUserinfo.companyid }}</span
                      >
                    </p>
                    <!-- 20230731 CCL+ 3.7.1 Report Info:報表資訊 顯示 -->
                    <p v-if="LoginUserinfo.roleid == 0">
                      <span style="font-size: medium; color: #000000">
                        &lt;&lt; Seesion lst for CSWEB Admin to debug only
                        &gt;&gt;
                      </span>
                    </p>
                    <!-- 20230704 CCL+ 加入Content Wrap style -->
                    <div
                      style="inline-size: 410px; overflow-wrap: break-word"
                      v-if="LoginUserinfo.roleid == 0"
                      v-html="UserAgentInfoHtml"
                    ></div>
                    <!-- 2.13.1 加入登入者的MAINSQL -->
                    <div
                      style="inline-size: 410px; overflow-wrap: break-word"
                      v-if="LoginUserinfo.roleid == 0"
                      v-html="UserCombineSQLInfoHtml"
                    ></div>
                    <p><br /></p>
                  </span>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
        <!-- 20230731 CCL+ 3.7.1 Report Info:報表資訊 顯示 -->
      </DxScrollView>
    </DxPopup>

    <!-- 欄位顯示隱藏視窗 Hiding/Show -->
    <DxPopup
      :visible="isHiddingShowVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :close-on-outside-click="true"
      :width="1300"
      @hidden="hiddenPopup('isHiddingShowVisible')"
      :height="600"
      container=".dx-viewport"
      title="Hiding/Show"
      :resizeEnabled="true"
    >
      <DxPosition my="center" />

      <!-- 20230711 CCL- DxScrollView width="100%" height="100%" -->
      <!--20230711 CCL+ 調整視窗大小,加上ScrollView -->
      <DxScrollView
        id="scrollview2"
        ref="scrollViewWidget"
        :scroll-by-content="scrollByContent"
        :scroll-by-thumb="scrollByThumb"
        :show-scrollbar="showReportInfoScrollbar"
        :bounce-enabled="pullDown"
        reach-bottom-text="Updating..."
        height="100%"
        width="100%"
      >
        <div id="hiddenField" style="overflow: auto; margin-right: 0px">
          <!--20230810 CCL* 拿掉style="display: inline-block" 改成 style="display: block"-->
          <div
            v-for="(item, index) in groupfields"
            :key="index + 'groupfieldslevel1'"
            style="display: block"
          >
            <label>
              <span style="color: blue; font-weight: bold">
                {{ item.columN_CAPTION }}
              </span>
            </label>
            <br />
            <div
              v-for="(item2, index2) in item.suB_COLUMNS"
              :key="index + '_' + index2 + 'groupfieldslevel2'"
              style="display: inline-block"
            >
              <label>
                <input
                  type="checkbox"
                  v-model="item2.standardflag"
                  @change="setColumnCheckChange($event, item2)"
                />
                <!-- 20230725 CCL+ 改成按下Query Button後才更新畫面 && 寫pivot_column_select項到DB  -->
                {{ item2.columN_CAPTION }}
                <span v-if="item2.grouP_NAME != null"></span>
                <!-- {{ item2.columN_CAPTION + "(" + item2.grouP_NAME + ")" }}<span v-if="item2.grouP_NAME != null"></span> -->
              </label>
            </div>
            <hr />
          </div>

          <!-- 20230725 CCL+ 改成按下Query Button後才更新畫面 && 寫pivot_column_select項到DB  -->
          <div>
            <span
              ><button class="dxButton" @click="ClearPivotColumns(groupfields)">
                Clear
              </button></span
            >
            <span style="margin-left: 30px"
              ><button class="dxButton" @click="QueryPivotColumns">
                Query
              </button></span
            >
            <!--20230731 CCL-For Test div>{{ showpivoT_COLUMN_SELECT }}</div -->
          </div>
        </div>
      </DxScrollView>
    </DxPopup>
  </div>
</template>
<script>
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
  // DxFieldPanel,
} from "devextreme-vue/pivot-grid";

import {
  DxPivotGridFieldChooser,
  DxTexts,
} from "devextreme-vue/pivot-grid-field-chooser";

import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

//import { DxSelectBox } from "devextreme-vue/select-box";

import { DxMenu, DxItem } from "devextreme-vue/menu";
//import { DxButton } from "devextreme-vue/button";

import { DxRadioGroup } from "devextreme-vue/radio-group";
import service from "@/assets/grouping-data.js";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,
  DxSize,
  DxTooltip,
  DxLabel,
  // DxArgumentAxis,
  // DxCommonAxisSettings,
  // DxValueAxis,
  // DxSeries,
  //DxExport,
} from "devextreme-vue/chart"; //20230724 CCL+ DxArgumentAxis增加visual-range,加入DxLabel, DxZoomAndPan

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { jsPDF } from "jspdf";
import { exportPivotGrid as exportPivotGridToExcel } from "devextreme/excel_exporter";
import { exportPivotGrid as exportPivotGridToPdf } from "devextreme/pdf_exporter";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export default {
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
    DxRadioGroup,
    DxPivotGridFieldChooser,
    DxTexts,
    DxPopup,
    DxPosition,
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxTooltip,
    DxMenu,
    DxItem,
    DxScrollView,
    DxLabel,
  },
  data() {
    const showSubmenuModes = {
      name: "onHover",
      delay: { show: 0, hide: 500 },
    };

    const dataSourceInit = new PivotGridDataSource({
      //fields: [],
      store: this.data,

      onChanged: () => {
        // Your code goes here
        //alert('onChanged');
      },
      onLoadingChanged: () => {
        //alert('Loading');
      },
    });
    return {
      data: [],
      isHiddingShowVisible: false,
      hideSubmenuOnMouseLeave: false,
      orientation: "horizontal",
      showSubmenuModes,
      isChartPopupVisible: false,
      isPopupVisible: false,
      dataSource: dataSourceInit,
      // state: dataSourceInit.state(),
      state: null,
      layouts: service.getLayouts(),
      layout: 2,

      applyChangesMode: ["onDemand"],

      SaveButtonOptions: {
        text: "確定",
        onClick: () => {
          this.isPopupVisible = false;
        },
      },
      closeChartButtonOptions: {
        text: "關閉",
        onClick: () => {
          this.isChartPopupVisible = false;
        },
      },
      customizeTooltip(args) {
        const valueText = currencyFormatter.format(args.originalValue);
        return {
          html: `${args.seriesName} | Total<div class='currency'>${valueText}</div>`,
        };
      },
      closeInfoButtonOptions: {
        text: "Close",
        onClick: () => {
          this.isInfoPopupVisible = false;
        },
      },
      closeHiddingShowButtonOptions: {
        text: "Close",
        onClick: () => {
          this.isHiddingShowVisible = false;
        },
      },
      HiddingShowButtonOptions: {
        text: "確定",
        onClick: () => {
          this.hiddingColumn();
          this.isHiddingShowVisible = false;
        },
      },
      i: 0,
      menus: [
        {
          name: "Return Query",
          icon: require("@/assets/img/ReturnQuery.png"),
        },
        {
          name: "Layout",
          icon: require("@/assets/img/Layerout.png"),
          items: [
            // {
            //   name: "Load",
            // },
            {
              name: "Save",
              //items: []
            },
            {
              name: "Save As New Report",
            },
          ],
        },
        {
          name: "Export File",
          icon: require("@/assets/img/Export.png"),
          items: [
            {
              name: "Csv",
              icon: require("@/assets/img/CSV.jpg"),
            },
            {
              name: "Excel",
              icon: require("@/assets/img/CSV.jpg"),
            },
            // {
            //   name: "Pdf",
            //   icon: require("@/assets/img/PDF.jpg"),
            //   //items: []
            // },
            // {
            //   name: "Mht",
            //   icon: require("@/assets/img/MHT.jpg"),
            // },
            // {
            //   name: "Rtf",
            //   icon: require("@/assets/img/RTF.jpg"),
            // },
            // {
            //   name: "Text",
            //   icon: require("@/assets/img/TEXT.jpg"),
            // },
            // {
            //   name: "Html",
            //   icon: require("@/assets/img/HTML.jpg"),
            // },
          ],
        },
        {
          name: "View Chart",
          icon: require("@/assets/img/ViewChart.png"),
        },
        {
          name: "Summary Type",
          icon: require("@/assets/img/FunctionHS.png"),
          items: [],
        },
        {
          name: "Columns",
          icon: require("@/assets/img/TableHS.png"),
          // items: [
          //   {
          //     name: "hiding/showing",
          //   },
          // ],
        },
        {
          name: "Info",
          icon: require("@/assets/img/Help.png"),
        },
      ],

      //20230801 CCL- summaryTypeList: ["Count", "Sum", "Min", "Max"],
      //20230801 CCL* 新增Avg
      summaryTypeList: ["Count", "Sum", "Min", "Max", "Avg"],

      currentMenu: null,

      isInfoPopupVisible: false,

      isSubjectShow: true,
      isStatusShow: true,
      isOwnerShow: true,
      isStartDateShow: true,
      isDueDateShow: true,
      isPriorityShow: true,
      isAssignedEmployeeShow: true,
      isCompletionShow: true,
      isReminderShow: true,
      isReminderDateShow: true,
      // chartTypeList: [
      //   {
      //     label: "Bar",
      //     value: "Bar",
      //   },
      //   {
      //     label: "StackedBar",
      //     value: "StackedBar",
      //   },
      //   {
      //     label: "FullStackedBar",
      //     value: "FullStackedBar",
      //   },
      //   {
      //     label: "SideBySideStackedBar",
      //     value: "StackedBar",
      //   },
      //   {
      //     label: "SideBySideFullStackedBar",
      //     value: "FullStackedBar",
      //   },
      //   {
      //     label: "Pie",
      //     value: "Pie",
      //   },
      //   {
      //     label: "Doughnut",
      //     value: "Doughnut",
      //   },
      //   {
      //     label: "Funnel",
      //     value: "Funnel",
      //   },
      //   {
      //     label: "Point",
      //     value: "Bubble",
      //   },
      //   {
      //     label: "Line",
      //     value: "Line",
      //   },
      // ],
      chartTypeList: [
        {
          label: "area",
          value: "area",
        },
        {
          label: "bar",
          value: "bar",
        },
        //{
        //  label: "bubble",
        //  value: "bubble",
        //},
        // {
        //   label: "candlestick",
        //   value: "candlestick",
        // },
        {
          label: "fullstackedarea",
          value: "fullstackedarea",
        },
        {
          label: "fullstackedbar",
          value: "fullstackedbar",
        },
        // {
        //   label: "fullstackedline",
        //   value: "fullstackedline",
        // },
        // {
        //   label: "fullstackedspline",
        //   value: "fullstackedspline",
        // },
        // {
        //   label: "fullstackedsplinearea",
        //   value: "fullstackedsplinearea",
        // },
        // {
        //   label: "line",
        //   value: "line",
        // },
        // {
        //   label: "rangearea",
        //   value: "rangearea",
        // },
        // {
        //   label: "rangebar",
        //   value: "rangebar",
        // },
        {
          label: "scatter",
          value: "scatter",
        },
        // {
        //   label: "spline",
        //   value: "spline",
        // },
        // {
        //   label: "splinearea",
        //   value: "splinearea",
        // },
        {
          label: "stackedarea",
          value: "stackedarea",
        },
        {
          label: "stackedbar",
          value: "stackedbar",
        },
        {
          label: "stackedline",
          value: "stackedline",
        },
        {
          label: "stackedspline",
          value: "stackedspline",
        },
        {
          label: "stackedsplinearea",
          value: "stackedsplinearea",
        },
        {
          label: "steparea",
          value: "steparea",
        },
        {
          label: "stepline",
          value: "stepline",
        },
        // {
        //   label: "stock",
        //   value: "stock",
        // },
      ],

      chartType: "Bar",
      settings: [],
      chartKey: 0, //  變動

      groupfields: [], //Gridview 2層顯示欄位
      lstmyquery: [], //Post查詢條件 20230725 CCL+ 改成按下Query Button後才更新畫面 && 寫pivot_column_select項到DB

      //20230731 CCL+ 3.7.1 for Login Page UserAgentInfo/////
      UserAgentInfoHtml: "",
      //20230731 CCL+ 3.7.1 加入登入者的MAINSQL
      UserMainSQLInfoHtml: "",
      //20230822 CCL+ 取得User按Query後Condition組合出的CombineSQL
      UserCombineSQLInfoHtml: "",
      //20230711 CCL+ 調整視窗大小,加上ScrollView
      showReportInfoScrollbar: "always", //onScroll, onHover, always, never
      pullDown: false,
      scrollByContent: true,
      scrollByThumb: true,
      //20230719 CCL+ Chart Update
      isGenSeriesfromColumns: false,
      isKeepPointLabels: false,
      selChartType: "Bar", //20230719 CCL修正不立即雙向Model Binding,而是等到按下Update才設定
      //20230724 CCL* DxArgumentAxis增加visual-range
      chart_visualRange: [],
      AxisRange_Min: 0,
      AxisRange_Max: 0,
      //20230725 CCL+ for test
      showpivoT_COLUMN_SELECT: "",
      //20230731 CCL+ 加入 showRowSubTol,showColSubTol功能
      isShowRowTols: false,
      isShowColTols: false,
      showRowSubTol: false, //"",
      showColSubTol: false, //"",
      //20230801 CCL+ for Testing PivotGridFieldChooer AllFields Area
      showPivoGridFields: [],
      //20230823 CCL+ 一段時間後自動跳轉登出(1hr)
      TimerId: null,
      //20230823 CCL+ for SummaryType Scrolling
      CurrentBlockIndex: 0,
      showArray: [],

      //用來存放 本次異動的隱藏顯示欄位
      currentHiddenShowColumn: [],
    };
  },
  computed: {
    dataGrid: function () {
      // return this.$refs[dataGridRef].instance;
      return this.$refs.grid.instance;
    },
    //20230824 CCL+ for SummaryType scroll
    menuobj: function () {
      return this.$refs.menu.instance;
    },

    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  watch: {
    isChartPopupVisible() {
      setTimeout(() => {
        //  改變 key ，讓 圖表寬度調整至 popup 的寬度
        this.chartKey++;
      }, 100);
    },
    
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    /**
     * 載入Gridview的資料
     * @param {bodyParameters} bodyParameters
     */
    async Load(bp) {
      console.log("load data");

      this.$store.dispatch("updateLoading", true);
      setTimeout(() => {
        this.$store.dispatch("updateLoading", false);
      }, "4000");
      
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      let sqlwhere = [];

      let bodyParameters = {
        formrow: 0,
        torow: 0,
        //sql: 'select * from VW_CS_WIP_TURNKEY nolock',
        sqlwhere: sqlwhere,
      };

      if (bp != null) {
        bodyParameters = bp;
      }

      //console.log(bodyParameters);
      let respcount = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/CountStandard",
        bodyParameters,
        config
      );
      if (!respcount.success) {
        this.data = [];
        let countResult = respcount.data.payload;
        // 2024-04-22: 與 QueryForm 同，最多只取前 3000 筆資料
        if (countResult > 3000) {
          countResult = 3000;
        }

        let limit = 1000;
        let page = parseInt(countResult / limit);
        let less = countResult % limit;
        if (less > 0) {
          page += 1;
        }
        for (let i = 0; i < page; i++) {
          let f = parseInt(i * limit);
          let t = parseInt(f + limit);
          bodyParameters.formrow = f;
          bodyParameters.torow = t;
          let resp = await this.axios.post(
            this.$store.state.axios_url + "/api/Standard/GetStandard",
            bodyParameters,
            config
          );
          if (!resp.success) {
            let tmp = resp.data.payload;
            console.log("GetStandard resp.data.payload", resp.data.payload);
            this.data = tmp.sort(function (a, b) {
              if (a > b) {
                return 1;
              } else if (a == b) {
                return 0;
              } else {
                return -1;
              }
            });
            // for (let i = 0; i < tmp.length; i++) {
            //   this.data.push(tmp[i]);
            // }
          }
          //20230822 CCL+ 改成取得GetStandard返回Message,成功撈到Data,Message會傳回成功CombineSql
          if (resp.message != "") {
            this.displayUserCombineSQLHtml(resp.data.message);
          }

          // 每500筆顯示一次
          // this.dataSource = new PivotGridDataSource({
          //   store: this.data,
          // });
        }
        //20230906 CCL* 當CountStandard 回傳0筆時, 不會執行上面那段for迴圈,不會呼叫GetStandard
        //導致ReportInfo視窗內的SQL為空,後端CountStandard也改為message回傳sql語句
        if (respcount.data.message != "" && parseInt(countResult) == 0) {
          //if(respcount.data.message != "" )
          this.displayUserCombineSQLHtml(respcount.data.message);
        }
        //20230811 CCL+ 取出colname,生成要顯示的fields,加上caption
        var dataJsonObj = this.data[0];
        var oneRowFields = this.reAssembleData(dataJsonObj);

        //20230811 CCL+ 進行重組,加上caption
        this.dataSource = new PivotGridDataSource({
          store: this.data,
          fields: oneRowFields,
          key: "dataField", //['dataField', 'caption'],//['dataField'], //20230815 CCL+ 增加key判斷
        });

        //載入State
        this.LoadPivotGridViewStatus();
        // this.dataSource.reload();

        //20230725 CCL+ 根據讀回的pivoT_COLUMN_SELECT 回復狀態
        this.LoadPivotColumnSelectStatus();
      }
    },

    //20230811 CCL+ 取出colname,生成要顯示的fields,加上caption
    /**
     * 樞紐datasource
     * @param {*} oneRowData
     */
    reAssembleData(oneRowData) {
      console.log("reAssembleData oneRowData", oneRowData);
      //20230811 CCL+
      //var AllRowFields = [];
      let tmpfields = [];
      let keysSet = {};
      //let valsSet = {};
      keysSet = Object.keys(oneRowData);
      //valsSet = Object.values(onerowdata);
      //this.showPivoGridFields = keysSet;
      for (var i = 0; i < keysSet.length; i++) {
        let groupName = this.getGroupNameByColNa(keysSet[i]);
        let captionName = this.getCaptionNameByColumnName(keysSet[i]);
        //keysSet[i] = CaptionName;
        let displayName = captionName + "(" + groupName + ")";

        let fieldItem = {
          caption: displayName, //CaptionName,
          width: 150, //20230906 CCL-改150,dxPivotGrid的總width拿掉 120,
          dataField: keysSet[i],
          //value: valsSet[i],
          summaryType: "sum", //20230815 CCL+ summaryType預設值
          area: "column", //20230815 CCL*改為預設為column // area: 'row',
          //expanded: true,
          //visible: true,
          headerfilter: {
            search: {
              enabled: true,
            },
          },
        };

        tmpfields.push(fieldItem);
      }

      //this.showPivoGridFields = tmpfields;
      return tmpfields;
    },

    /**
     * DxPivotGridFieldChooser 變更事件
     * @param e
     */
    chooserChange(e) {
      console.log("chooserChange", e.value);
      this.state = e.value;
      // console.log(e.value);
    },

    /**
     * 左邊chosece 點選update
     */
    applyClick() {
      console.log("applyClick");

      //20230731 CCL+ 加入 showRowSubTol,showColSubTol功能
      if (this.showRowSubTol == true) {
        this.isShowRowTols = true;
      } else {
        this.isShowRowTols = false;
      }
      if (this.showColSubTol == true) {
        this.isShowColTols = true;
      } else {
        this.isShowColTols = false;
      }

      this.state.fields.forEach((item) =>
        this.$set(item, "summaryType", "sum")
      );

      this.dataSource.state(this.state);

      //20230731 CCL+ 加入 showRowSubTol,showColSubTol功能
      this.dataSource.reload();
      //this.dataSource.reload()

      //將結果 回寫至db
      this.savePivotGridViewStatus();
    },

    /**
     * 儲存樞紐表
     */
    async savePivotGridViewStatus() {
      console.log("savePivotGridViewStatus");
      //  儲存 PivotGrid
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      console.log("savePivotGridViewStatus this.state", this.state);
      // console.log("JSON.this.state", JSON.stringify(this.state));

      //20230725 CCL+ 新增儲存pivot_column_select
      const bodyParameters = {
        account: this.LoginUserinfo.account,
        reportid: "Turnkey_WIP",
        pivotlayout: JSON.stringify(this.state),
        pivot_column_select: this.lstmyquery.pivoT_COLUMN_SELECT,
      };

      //20230811 CCL+ PivotLayoutXML改存到PivotLayout1 PS:欄位需已調整到VARCHAR2(32767)
      let rest = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/SavePivot1",
        bodyParameters,
        config
      );
      if (!rest.success) {
        console.log(rest);
      }
    },

    //LOAD 沒問題
    //20230807 CCL*修正SummaryType顯示
    async LoadPivotGridViewStatus() {
      console.log("LoadPivotGridViewStatus");

      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.LoginUserinfo.account,
        reportid: this.reportid,
      };

      //20230818 CCL+ PivotLayoutXML改存到PivotLayout1,讀取也改成讀取PivotLayout1的值送到前端
      let respfield = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/GetPivot1",
        bodyParameters,
        config
      );
      if (respfield.data.success) {
        let summaryFields = JSON.parse(
          JSON.parse(respfield.data.payload)
        ).fields;

        let testArray = summaryFields.map((item) => {
          return {
            name: item.dataField,
            items: [
              {
                parentItem: item.dataField,
                name: "selectMenu",
                value: "unselect",
              },
            ],
          };
        });

        //20230731 CCL+, Summary Type的submenuitem要根據Hidden/Showing Dialog內的item來顯示
        let pivot_column_select = this.lstmyquery.pivoT_COLUMN_SELECT;
        let aryPivotColumnSel = pivot_column_select.split("^");
        //let showArray = []; //20230823 CCL- for SummaryType scroll 改放在全域Data
        let perfArray = null;
        let tempArray = [];
        //20230823 CCL+ for SummaryType scroll
        let screenShowArray = [];

        for (var i = 0; i < testArray.length; i++) {
          if (
            aryPivotColumnSel.includes(testArray[i].name) &&
            testArray[i].name != "RN"
          ) {
            //20230803 CCL+ 修改testArray的name為"name(groupName)"
            var tmpAryItem = this.getGroupNameByColumnName(testArray[i].name);
            tempArray.push(tmpAryItem);
            //showArray.push(testArray[i]);
          }
        }

        perfArray = tempArray.sort(function (a, b) {
          //return a.groupIndex - b.groupIndex;
          if (a.groupIndex > b.groupIndex) {
            return 1;
          } else if (a.groupIndex == b.groupIndex) {
            if (a.index > b.index) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return -1;
          }
        });

        //20230824 CCL+ 修正由於showArray已改為全域變數,所以每次Hide/Show視窗按下Query紐,testArray變動,要先重新清空
        if (this.showArray.length > 0) {
          //方法1
          //this.showArray.length = 0;
          //方法2
          this.showArray = [];
        }

        for (i = 0; i < perfArray.length; i++) {
          //替換為CaptionName
          var colCaptionName = this.getCaptionNameByColumnName(
            perfArray[i].colname
          );

          //20230823 CCL- showArray.push({
          this.showArray.push({
            //20230808 CCL- name: perfArray[i].colname + '(' + perfArray[i].groupname + ')' ,
            index: i, //20230823 CCL+ for SummaryType Scrolling
            menulev: 1, //20230823 CCL+ for SummaryType Scrolling
            name: colCaptionName + "(" + perfArray[i].groupname + ")",
            items: [
              {
                parentItem: perfArray[i].colname,
                name: "selectMenu",
                value: "unselect",
                menulev: 2, //20230823 CCL+ for SummaryType Scrolling
              },
            ],
          });
        }

        //20230823 CCL+ for SummaryType Scrolling,每次只顯示10筆
        const BlockItemsCount = 8; //5;//10;
        let newIndex = 0;
        if (this.showArray.length >= BlockItemsCount) {
          newIndex = BlockItemsCount;
        } else {
          newIndex = this.showArray.length;
        }

        for (i = 0; i < newIndex; i++) {
          screenShowArray.push(this.showArray[i]);
        }

        //20230801 CCL- this.menus[4].items = testArray;
        //20230823 CCL- for SummaryType Scrolling this.menus[4].items = showArray; //20230801 CCL+
        this.menus[4].items = this.showArray; //screenShowArray; //20230823 CCL+ for SummaryType Scrolling
        this.i++;

        //20230731 CCL+, PivotGrid的fields array內的areaData要根據Hidden/Showing Dialog內的item來顯示
        ///var perfArrayByColNa = null; //20230807 CCL+ 依colname做排序
        const pivotGridFieldChooer = this.$refs.gridfieldchooer.instance;
        var pivotfieldchooerdata = pivotGridFieldChooer.getDataSource();

        console.log("pivotfieldchooerdata", pivotfieldchooerdata);

        for (i = 0; i < pivotfieldchooerdata._fields.length; i++) {
          // && pivotgridobj._fields[i].area != "data"
          if (
            !aryPivotColumnSel.includes(
              pivotfieldchooerdata._fields[i].dataField
            ) ||
            pivotfieldchooerdata._fields[i].dataField == "RN"
          ) {
            pivotfieldchooerdata._fields[i].visible = false;
          } else {
            console.log(pivotfieldchooerdata._fields[i].dataField + '-----------'+this.currentHiddenShowColumn )
            console.log(pivotfieldchooerdata._fields[i])
            if(this.currentHiddenShowColumn.includes(pivotfieldchooerdata._fields[i].dataField))
            {
              console.log('in iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiccccccccccccccccccccccccccccccccccccccc')
              pivotfieldchooerdata._fields[i].area = undefined;
            }
            pivotfieldchooerdata._fields[i].visible = true;
          }
        }
        //this.showPivoGridFields = pivotfieldchooerdata._fields; //20230807 CCL+

        pivotGridFieldChooer.repaint();
console.log(respfield.data.payload)
        if (respfield.data.payload) {
          this.state = JSON.parse(JSON.parse(respfield.data.payload));

          console.log("this.state", this.state);
        } else {
          this.state = this.dataSource.state();
        }

        this.dataSource.state(this.state);

      }
    },

    /**
     * 把Summary Type menu和FieldChooser欄位的值改成和Hide/Show Dialog欄位值一樣是Caption
     * @param ColumnName
     */
    getCaptionNameByColumnName(ColumnName) {
      // console.log("getCaptionNameByColumnName");
      for (var i = 0; i < this.groupfields.length; i++) {
        //suB_COLUMNS
        var Sub_COLUMNS = this.groupfields[i].suB_COLUMNS;
        for (var j = 0; j < Sub_COLUMNS.length; j++) {
          if (Sub_COLUMNS[j].columN_NAME == ColumnName) {
            if (
              Sub_COLUMNS[j].columN_NAME != "" &&
              Sub_COLUMNS[j].grouP_NAME != ""
            ) {
              //return Sub_COLUMNS[j].grouP_NAME;
              var colCaption = Sub_COLUMNS[j].columN_CAPTION;

              return colCaption;
            }
          }
        }
      }
    },

    /**
     * 依colname做排序
     * @param ColumnName
     */
    getGroupNameByColumnName(ColumnName) {
      console.log("getGroupNameByColumnName");
      //20230803 CCL+ 取得group name

      //要照group排
      for (var i = 0; i < this.groupfields.length; i++) {
        //suB_COLUMNS
        var Sub_COLUMNS = this.groupfields[i].suB_COLUMNS;
        for (var j = 0; j < Sub_COLUMNS.length; j++) {
          if (Sub_COLUMNS[j].columN_NAME == ColumnName) {
            if (
              Sub_COLUMNS[j].columN_NAME != "" &&
              Sub_COLUMNS[j].grouP_NAME != ""
            ) {
              //return Sub_COLUMNS[j].grouP_NAME;
              var groupname = Sub_COLUMNS[j].grouP_NAME;

              var tempAryItem = {
                index: i * j + j,
                groupIndex: i,
                colname: ColumnName,
                groupname: groupname,
              };

              return tempAryItem;
            }
            ///break;
          }
        }
      }
    },

    //20230811 CCL+
    getGroupNameByColNa(ColumnName) {
      // console.log("getGroupNameByColNa");
      //要照group排
      for (var i = 0; i < this.groupfields.length; i++) {
        //suB_COLUMNS
        var Sub_COLUMNS = this.groupfields[i].suB_COLUMNS;
        for (var j = 0; j < Sub_COLUMNS.length; j++) {
          if (Sub_COLUMNS[j].columN_NAME == ColumnName) {
            if (
              Sub_COLUMNS[j].columN_NAME != "" &&
              Sub_COLUMNS[j].grouP_NAME != ""
            ) {
              //return Sub_COLUMNS[j].grouP_NAME;
              var groupname = Sub_COLUMNS[j].grouP_NAME;

              return groupname;
            }
          }
        }
      }
    },

    loadCookie() {
      let pivotGridFieldChooser = this.$cookies.get("PivotGridFieldChooser");
      if (pivotGridFieldChooser)
        this.dataSource = new PivotGridDataSource(pivotGridFieldChooser);
    },

    async setSummaryTypeList() {
      console.log("setSummaryTypeList");
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.LoginUserinfo.account,
        reportid: this.reportid,
      };
      let respfield = await this.axios.post(
        //20230725 CCL- PivotGrid Columns Field改成跟Hidden/Showing Columns畫面一樣 this.$store.state.axios_url + "/api/Standard/GetStandardField",
        this.$store.state.axios_url + "/api/Standard/GetStandardPivotField", //20230725 CCL+
        bodyParameters,
        config
      );
      if (!respfield.success) {
        this.groupfields = respfield.data.payload.lsT_GROUP_COLUMNS;
        //200230725 CCL+ CCL修正不立即雙向Model Binding,而是等到按下Wuery才設定
        this.lstmyquery = respfield.data.payload.obJ_N_RPT_CRITERION;

        //20230731 CCL+ 取得User登入後的MAINSQL
        this.displayUserMainSQLHtml(respfield.data.payload.mainsql);

        this.i++;
      }
    },

    /**
     * 更新圖表內容(修正不立即雙向Model Binding,而是等到按下Update才設定)
     */
    UpdateChart() {
      console.log("UpdateChart");
      console.log(this.settings);
      //0. select box Select 更新
      this.selChartType = this.chartType;

      //1. Generate Series from Columns
      if (this.settings.indexOf("GenerateSeriesfromColumns") !== -1) {
        console.log("is GenerateSeriesfromColumns");
        this.isGenSeriesfromColumns = true; //回傳true column當作Series
      } else {
        this.isGenSeriesfromColumns = false; //回傳false row當作Series
      }
      //2. 顯示Show Point Labels
      if (this.settings.indexOf("ShowPointLabels") !== -1) {
        console.log("is ShowPointLabels");
        this.isKeepPointLabels = true;
      } else {
        this.isKeepPointLabels = false;
      }

      const isShowColumnGrandTotals =
        this.settings.indexOf("ShowColumnGrandTotals") !== -1;
      const isShowRowGrandTotals =
        this.settings.indexOf("ShowRowGrandTotals") !== -1;

      //3. 如果顯示Column Grand Totals 重新bind一次
      // if (this.settings.indexOf("ShowColumnGrandTotals") !== -1) {
      //   console.log("is ShowColumnGrandTotals");
      const pivotGrid = this.$refs.grid.instance;
      const chart = this.$refs.chart.instance;

      //5.重設setChartRange change visual range
      if (this.settings.indexOf("ChangeY-AxisScaling") !== -1) {
        if (isShowColumnGrandTotals || isShowRowGrandTotals) {
          pivotGrid.bindChart(chart, {
            dataFieldsDisplayMode: "singleAxis", //"singleAxis" "splitAxes" "splitPanes"
            alternateDataFields: false,
            inverted: this.isGenSeriesfromColumns,
            argumentAxis: { visualrange: this.chart_visualRange },
            processCell(cellData) {
              cellData.visible = false;
              if (isShowColumnGrandTotals && isShowRowGrandTotals) {
                cellData.visible = true;
              } else if (!isShowColumnGrandTotals && isShowRowGrandTotals) {
                if (
                  !(
                    cellData.columnPath.length === 0 &&
                    cellData.rowPath.length === cellData.maxRowLevel
                  ) &&
                  cellData.rowPath.length === 0 &&
                  cellData.columnPath.length === cellData.maxColumnLevel
                ) {
                  cellData.visible = true;
                }
              } else if (isShowColumnGrandTotals && !isShowRowGrandTotals) {
                if (
                  cellData.columnPath.length === 0 &&
                  cellData.rowPath.length === cellData.maxRowLevel &&
                  !(
                    cellData.rowPath.length === 0 &&
                    cellData.columnPath.length === cellData.maxColumnLevel
                  )
                ) {
                  cellData.visible = true;
                }
              }

              return cellData;
            },
          });
        } else {
          pivotGrid.bindChart(chart, {
            dataFieldsDisplayMode: "singleAxis", //"singleAxis" "splitAxes" "splitPanes"
            alternateDataFields: false,
            inverted: this.isGenSeriesfromColumns,
            argumentAxis: { visualrange: this.chart_visualRange },
          });
        }
      } else {
        if (isShowColumnGrandTotals || isShowRowGrandTotals) {
          pivotGrid.bindChart(chart, {
            dataFieldsDisplayMode: "singleAxis", //"singleAxis" "splitAxes" "splitPanes"
            alternateDataFields: false,
            inverted: this.isGenSeriesfromColumns,
            processCell(cellData) {
              if (isShowColumnGrandTotals && isShowRowGrandTotals) {
                if (
                  (cellData.columnPath.length === 0 &&
                    cellData.rowPath.length === cellData.maxRowLevel) ||
                  (cellData.rowPath.length === 0 &&
                    cellData.columnPath.length === cellData.maxColumnLevel)
                ) {
                  cellData.visible = true;
                }
              } else if (!isShowColumnGrandTotals && isShowRowGrandTotals) {
                if (
                  !(
                    cellData.columnPath.length === 0 &&
                    cellData.rowPath.length === cellData.maxRowLevel
                  ) &&
                  cellData.rowPath.length === 0 &&
                  cellData.columnPath.length === cellData.maxColumnLevel
                ) {
                  cellData.visible = true;
                }
              } else if (isShowColumnGrandTotals && !isShowRowGrandTotals) {
                if (
                  cellData.columnPath.length === 0 &&
                  cellData.rowPath.length === cellData.maxRowLevel &&
                  !(
                    cellData.rowPath.length === 0 &&
                    cellData.columnPath.length === cellData.maxColumnLevel
                  )
                ) {
                  cellData.visible = true;
                }
              }
              return cellData;
            },
          });
        } else {
          pivotGrid.bindChart(chart, {
            dataFieldsDisplayMode: "singleAxis", //"singleAxis" "splitAxes" "splitPanes"
            alternateDataFields: false,
            inverted: this.isGenSeriesfromColumns,
          });
        }
      }
      //5.重設setChartRange change visual range
      if (this.settings.indexOf("ChangeY-AxisScaling") !== -1) {
        console.log("is ChangeY-AxisScaling");
        // const chart = this.$refs.chart.instance;
        // var argAxis = chart.getArgumentAxis();
        // argAxis.visualRange([this.AxisRange_Min, this.AxisRange_Max]);

        // console.log("chart_visualRange", this.AxisRange_Min);
        // console.log("chart_visualRange", this.AxisRange_Max);
        // console.log("chart_visualRange", this.chart_visualRange);
        this.chart_visualRange = [
          parseInt(this.AxisRange_Min),
          parseInt(this.AxisRange_Max),
        ];

        // this.chart_visualRange = [0, 100];
        //chart.resetVisualRange();
        //chart.zoomArgument(this.AxisRange_Min, this.AxisRange_Max);
      }
    },

    /**
     * 清空樞紐欄位(改成按下Query Button後才更新畫面 && 寫pivot_column_select項到DB)
     * @param pgroupfields
     */
    ClearPivotColumns(pgroupfields) {
      var i = 0;
      var j = 0;

      for (i = 0; i < pgroupfields.length; i++) {
        var Sub_Columns = pgroupfields[i].suB_COLUMNS;
        for (j = 0; j < Sub_Columns.length; j++) {
          //組合出新的pivoT_COLUMN_SELECT
          if (Sub_Columns[j].standardflag == 1) {
            Sub_Columns[j].standardflag = 0;
          }
        }
      }
    },

    /**
     * 查詢pivot (hidden/show 頁面按鈕事件)
     */
    async QueryPivotColumns() {
      console.log("start QueryPivotColumns");
      //#region 處理 欄位是否有進入 chooser
      let newPivot_ColumnSelect = "RN^";
      var i = 0;
      var j = 0;

      for (i = 0; i < this.groupfields.length; i++) {
        var Sub_Columns = this.groupfields[i].suB_COLUMNS;
        for (j = 0; j < Sub_Columns.length; j++) {
          //組合出新的pivoT_COLUMN_SELECT
          if (Sub_Columns[j].standardflag == 1) {
            newPivot_ColumnSelect =
              newPivot_ColumnSelect + Sub_Columns[j].columN_NAME + "^";
          }
        }
      }

      //去掉最後一個'^'
      newPivot_ColumnSelect = newPivot_ColumnSelect.substring(
        0,
        newPivot_ColumnSelect.length - 1
      );
      //#endregion

      this.lstmyquery.pivoT_COLUMN_SELECT = newPivot_ColumnSelect;

      console.log("groupfields", this.groupfields);
      console.log("newPivot_ColumnSelect", newPivot_ColumnSelect);
      console.log("QueryPivotColumns this.state", this.state);
     

      this.currentHiddenShowColumn.forEach((item) => {
        // let filterField = this.state.fields.find(x=>x.dataField == item.columN_NAME);
        // // eslint-disable-next-line no-unused-vars
        // filterField = {"dataField": item.columN_NAME,
        // "summaryType": "sum"};

        this.state.fields.forEach((x) => {
          if (x.dataField == item) {
            x.dataField = item;
            x.summaryType = "sum" ;
            x.area = undefined;
            x.areaIndex = undefined;
          }
        });
      });
      console.log("currentHiddenShowColumn  this.state", this.state);

      //寫回DB
      await this.savePivotGridViewStatus();

      //讀回DB回復狀態
      //改放在按下Columns menu時呼叫 this.LoadPivotColumnSelectStatus();

      //載入State
      this.LoadPivotGridViewStatus(); //此函式會更新PivotGrid

      //
      this.dataSource.reload();

      //關閉視窗
      this.hiddenPopup("isHiddingShowVisible");

      //20230824 CCL* 修正當Hide/Show視窗按下Query時,testArray有改變,需要重新ReLoad,SummaryType menu才會UP/DOWN Button功能正常
      //重新載入資料
      //this.simulatePushF5Keyboard();
      console.log("QueryPivotColumns this.state", this.state);

      console.log("end QueryPivotColumns");
    },

    /**
     * 根據讀回的pivoT_COLUMN_SELECT 回復狀態
     */
    async LoadPivotColumnSelectStatus() {
      console.log("LoadPivotColumnSelectStatus");
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.LoginUserinfo.account,
        reportid: this.reportid,
      };

      let respfield = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/GetPivotColumnSelect",
        bodyParameters,
        config
      );
      if (respfield.data.success) {
        this.lstmyquery.pivoT_COLUMN_SELECT = respfield.data.payload;
      }

      //test this.showpivoT_COLUMN_SELECT = this.lstmyquery.pivoT_COLUMN_SELECT;
      //取出State內的pivoT_COLUMN_SELECT; 更新回groupfields的standardflag
      var columns = this.lstmyquery.pivoT_COLUMN_SELECT.split("^");
      var i = 0,
        j = 0;
      for (i = 0; i < this.groupfields.length; i++) {
        var Sub_Columns = this.groupfields[i].suB_COLUMNS;
        for (j = 0; j < Sub_Columns.length; j++) {
          if (columns.includes(Sub_Columns[j].columN_NAME)) {
            Sub_Columns[j].standardflag = 1;
          } else {
            Sub_Columns[j].standardflag = 0;
          }
        }
      }
    },

    /**
     * summaryType切換時
     * @param e
     * @param parentItem
     */
    changeSelectionPriority(e, parentItem) {
      console.log("e.value", e.value);
      console.log("parentItem", parentItem);

      const vm = this;

      //方法一
      // let foundItem = vm.dataSource._fields.find((item) => item.dataField === parentItem);
      // vm.$set(foundItem, "summaryType", e.value);
      // vm.dataSource.reload();

      //方法二
      vm.state.fields.find((item) => {
        if (item.dataField === parentItem) {
          //20230801 CCL- vm.$set(item, "summaryType", e.value);
          //20230801 CCL+, summaryType一定要設小寫字串才有作用:"sum","min","max","avg","count","custom"
          vm.$set(item, "summaryType", e.value.toLowerCase());
        }
      });
      vm.dataSource.state(vm.state);
    },

    /**
     * 視窗顯示或隱藏
     * @param name 視窗屬性名稱
     */
    hiddenPopup(name) {
      //  關閉 Popup 會呼叫的
      this[name] = false;

    },

    /**
     * 匯出
     * @param newVal 按鈕value
     */
    exportGrid(newVal) {
      if (newVal == "Pdf") {
        const doc = new jsPDF();
        exportPivotGridToPdf({
          jsPDFDocument: doc,
          component: this.dataGrid,
          customDrawCell: (e) => {
            if (
              e.gridCell.rowType === "data" &&
              e.gridCell.column.dataField === "Picture"
            ) {
              doc.addImage(
                e.gridCell.value,
                "PNG",
                e.rect.x,
                e.rect.y,
                e.rect.w,
                e.rect.h
              );
              e.cancel = true;
            }
          },
        }).then(() => {
          doc.save("Turnkey_WIP.T.1.01969_Pivot.pdf");
        });
      } else if (newVal == "Excel") {
        const fileName = "Turnkey_WIP.T.1.01969_Pivot";
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(fileName);

        exportPivotGridToExcel({
          component: this.dataGrid,
          worksheet,
          autoFilterEnabled: true,
        }).then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              fileName + ".xlsx"
            );
          });
        });
      } else if (newVal == "Csv") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Turnkey_WIP.T.1.01969_Pivot");
        exportPivotGridToExcel({
          component: this.dataGrid,
          worksheet: worksheet,
        }).then(function () {
          workbook.csv.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "Turnkey_WIP.T.1.01969_Pivot.csv"
            );
          });
        });
      }
    },

    /**
     * report info 視窗 隱藏事件
     * @param name
     */
    onQueryHidden(name) {
      // this.isPopupVisible = false;
      //  關閉 Popup 會呼叫的
      this[name] = false;
    },

    /**
     * 從localStorage讀取UserAgentInfo並顯示
     */
    displayUserAgenInfo() {
      let localStorageUserAgentInfo = JSON.parse(
        localStorage.getItem("UserAgentInfo")
      );
      if (localStorageUserAgentInfo.data.payload != null) {
        var userAgentInfo = localStorageUserAgentInfo.data.payload;
        var AllAgentInfo = "";
        //20230704 CCL* let PSpanlineHead = '<p><span style="font-size: medium; color: #000000" >';
        let PSpanlineHead =
          '<p><span style="font-size: medium; color: #000000; " >';
        let PSpanlineTail = "</span></p>";

        AllAgentInfo +=
          PSpanlineHead + "Mode=" + userAgentInfo.Mode + PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead + "Device=" + userAgentInfo.Device + PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead + "OSName=" + userAgentInfo.OSName + PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead +
          "BrowserName=" +
          userAgentInfo.BrowserName +
          PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead +
          "BrowserVersion=" +
          userAgentInfo.BrowserVersion +
          PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead +
          "Orientation=" +
          userAgentInfo.Orientation +
          PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead + "OnTouch=" + userAgentInfo.OnTouch + PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead + "Width=" + userAgentInfo.screenWidth + PSpanlineTail;
        AllAgentInfo +=
          PSpanlineHead +
          "Height=" +
          userAgentInfo.screenHeight +
          PSpanlineTail;

        this.UserAgentInfoHtml = AllAgentInfo;
      }
    },

    /**
     * 取得User登入後的MAINSQL
     * @param mainsql
     */
    displayUserMainSQLHtml(mainsql) {
      if (mainsql !== null) {
        var AllUserMainSQLInfo = "";
        let PSpanlineHead =
          '<p><span style="font-size: medium; color: #000000" >';
        let PSpanlineTail = "</span></p>";

        AllUserMainSQLInfo +=
          PSpanlineHead + "MAINSQL=" + mainsql + PSpanlineTail;

        this.UserMainSQLInfoHtml = AllUserMainSQLInfo;
      }
    },

    /**
     * 取得User按Query後Condition組合出的CombineSQL
     * @param combinesql
     */
    displayUserCombineSQLHtml(combinesql) {
      if (combinesql != "") {
        var AllUserCombineSQLInfo = "";
        let PSpanlineHead =
          '<p><span style="font-size: medium; color: #ff0000; font-weight: bold;" >';
        let PSpanlineTail = "</span></p>";

        AllUserCombineSQLInfo +=
          PSpanlineHead + "SQL=" + combinesql + PSpanlineTail;

        this.UserCombineSQLInfoHtml = AllUserCombineSQLInfo;
      }
    },

    /**
     * 選單生成子選單觸發事件
     * @param {memu show 物件} e
     */
    onSubmenuShown: function (e) {
      const pvMenu = document.getElementsByClassName(
        "dx-context-menu pivotMenu"
      );

      if (e.rootItem.innerText.indexOf("Summary Type") > 0) {
        if (e.submenu._userOptions.items.length > 5) {
          for (let i = 0; i < pvMenu.length; i++) {
            pvMenu[i].classList.add("customerMenuStyle");
          }
        }
      } else {
        for (let i = 0; i < pvMenu.length; i++) {
          pvMenu[i].classList.remove("customerMenuStyle");
        }
      }
    },

    /**
     * 選單變更是件
     * @param newVal
     */
    changeSelect(newVal) {
      //alert(newVal);
      if (newVal.includes("View Chart")) {
        this.isChartPopupVisible = true;
      } else if (newVal.includes("Info")) {
        console.log("in ReportInfo");
        this.isInfoPopupVisible = true;
      } else if (newVal.includes("Columns")) {
        console.log("in hidding");
        //讀回DB回復狀態
        this.LoadPivotColumnSelectStatus(); //20230725 CCL+ 改成按下Query Button後才更新畫面 && 寫pivot_column_select項到DB
        this.isHiddingShowVisible = true;
        //this.hiddingColumn();
      } else if (newVal.includes("Return Query")) {
        this.$router.push("/demo?keep=1");
      } else if (newVal.includes("Load")) {
        console.log("in Load");
        this.loadCookie();
      } else if (newVal.includes("Save")) {
        console.log("in Save");
        this.savePivotGridViewStatus();
      }
      // 改成下方 changeSelectionPriority()
      // else if (
      //   newVal.includes("Count") ||
      //   newVal.includes("Sum") ||
      //   newVal.includes("Min") ||
      //   newVal.includes("Max")
      // ) {

      //   this.dataSource._fields
      //     .filter((task) => task.area == "data")
      //     .forEach((item) => (item.summaryType = newVal.toLowerCase()));

      //   this.dataSource.reload();
      // }
      else if (newVal.includes("Excel") || newVal.includes("Csv")) {
        console.log("in File");
        this.exportGrid(newVal);
      }
    },

    /**
     * 一段時間後沒動作自動跳轉登出(1hr)
     */
    reCalculate() {
      clearTimeout(this.TimerId);

      this.TimerId = setTimeout(
        () => this.$router.push({ path: "/" }),
        3600000
      ); // path 為 "/" 既為根目錄，5000為毫秒
      //this.TimerId =  setTimeout(() => this.$router.push({ path: "/" }), 10000); // path 為 "/" 既為根目錄，5000為毫秒
    },

    /**
     * 開啟 左邊選單中的 模式選擇視窗
     */
    openMode() {
      this.isPopupVisible = true;
    },

    /**
     * 隱藏顯示checkbox 事件
     * @param row
     */
    setColumnCheckChange(e, row) {
      if (e.target.checked) {
        this.currentHiddenShowColumn.push(row.columN_NAME);
      }
      console.log("111111111111");
      console.log(e);
      console.log(row);
    },

    //#region 20240619 整理當下沒用到的method

    //20230824 CCL* 修正當Hide/Show視窗按下Query時,testArray有改變,需要重新Load(),SummaryType menu才會UP/DOWN Button功能正常
    //simulatePushF5Keyboard() {
    //  const selfurl = window.location.href;
    //  window.location.replace(selfurl); //方法1
    //  //window.location.href = selfurl; //方法2
    //  window.location.reload(true);
    //},

    //20230823 CCL+ for SummaryType Scrolling
    // menuItemClick(e) {
    //   //const { itemData, itemElement, itemIndex } = e;
    //   ///const { itemData, itemElement } = e;
    //   const { itemData } = e;

    //   const menu = this.menuobj;
    //   const BlockItemsCount = 8; //5;//10;

    //   let screenShowArray = [];
    //   //let SubItems = itemData.items;
    //   let newFirstItemIndex = 0;
    //   let newEndItemIndex = 0;

    //   let factor = 0;
    //   let remainder = 0;
    //   if (this.showArray.length <= BlockItemsCount) {
    //     factor = 0;
    //     remainder = 0;
    //   } else {
    //     let floatnum = this.showArray.length / BlockItemsCount;
    //     factor = Math.floor(floatnum);
    //     remainder = this.showArray.length % BlockItemsCount;
    //   }

    //   if (itemData.alt == "DOWN" && itemData.menulev == 1) {
    //     //if(this.CurrentBlockIndex == 0) { 已到最上面Block}

    //     //SummaryType Up Item
    //     //screenShowArray每次最多顯示10筆Item, ShowArray則是存放所有SummaryType Items

    //     //this.showPivoGridFields = this.CurrentBlockIndex + "; " + factor + "; " + remainder; //0; 1; 2

    //     if (factor > 0 && this.CurrentBlockIndex < factor) {
    //       this.CurrentBlockIndex++;
    //     }

    //     //this.showPivoGridFields = this.CurrentBlockIndex + "; " + factor + "; " + remainder; //1; 1; 2

    //     if (factor == 0) {
    //       //如果factor == 0 不做動作,
    //       this.CurrentBlockIndex = 0;
    //       newFirstItemIndex = 0;
    //       newEndItemIndex = this.showArray.length;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     } else if (factor > 0 && this.CurrentBlockIndex < factor) {
    //       //如果factor != 0 , 做動作
    //       //0~9 -> Block0, 10~19 -> Block1

    //       newFirstItemIndex = this.CurrentBlockIndex * BlockItemsCount;
    //       newEndItemIndex = newFirstItemIndex + BlockItemsCount;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     } else if (factor > 0 && factor == this.CurrentBlockIndex) {
    //       newFirstItemIndex = this.CurrentBlockIndex * BlockItemsCount;
    //       newEndItemIndex = newFirstItemIndex + remainder;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     }

    //     // screenShowArray.push({
    //     //   index: -1,
    //     //   menulev: 1,
    //     //   name: "", //"UP",
    //     //   alt: "UP",
    //     //   icon: require("@/assets/img/MenuItem_UP_Arrow.png"),
    //     // });

    //     for (var i = newFirstItemIndex; i < newEndItemIndex; i++) {
    //       screenShowArray.push(this.showArray[i]);
    //     }

    //     //20230823 CCL+ for SummaryType Scrolling
    //     // screenShowArray.push({
    //     //   index: newEndItemIndex + 1, //this.showArray.length + 1,
    //     //   menulev: 1,
    //     //   name: "", //"DOWN",//this.showArray.length + 1,
    //     //   alt: "DOWN",
    //     //   icon: require("@/assets/img/MenuItem_DOWN_Arrow.png"),
    //     // });

    //     this.menus[4].items = screenShowArray;
    //     menu.option("items", []);
    //     menu.option("items", this.menus); //20230824 CCL+ 要動態重新指定新items array,並生效,要加這一行; Raised Events: optionChanged
    //     //this.showPivoGridFields = screenShowArray.length;
    //     //this.showPivoGridFields += this.menus[4].items;
    //     menu.repaint();
    //     ///this.showPivoGridFields = this.menus[4].items;
    //     //this.showPivoGridFields = itemElement.innerHTML;
    //     //menu.dataSource.reload();
    //     //this.dataSource.state(this.state);
    //   }

    //   if (itemData.alt == "UP" && itemData.menulev == 1) {
    //     //if(this.CurrentBlockIndex == 0) { 已到最上面Block}

    //     //SummaryType Up Item
    //     //screenShowArray每次最多顯示10筆Item, ShowArray則是存放所有SummaryType Items

    //     //this.showPivoGridFields = this.CurrentBlockIndex + "; " + factor + "; " + remainder; //0; 1; 2

    //     if (factor > 0 && this.CurrentBlockIndex > 0) {
    //       this.CurrentBlockIndex--;
    //     }

    //     //this.showPivoGridFields = this.CurrentBlockIndex + "; " + factor + "; " + remainder; //1; 1; 2

    //     if (factor == 0) {
    //       //如果factor == 0 不做動作,
    //       this.CurrentBlockIndex = 0;
    //       newFirstItemIndex = 0;
    //       newEndItemIndex = this.showArray.length;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     } else if (factor > 0 && this.CurrentBlockIndex > 0) {
    //       //如果factor != 0 , 做動作
    //       //0~9 -> Block0, 10~19 -> Block1

    //       newFirstItemIndex = this.CurrentBlockIndex * BlockItemsCount;
    //       newEndItemIndex = newFirstItemIndex + BlockItemsCount;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     } else if (factor > 0 && this.CurrentBlockIndex == 0) {
    //       newFirstItemIndex = this.CurrentBlockIndex * BlockItemsCount;
    //       newEndItemIndex = newFirstItemIndex + BlockItemsCount;

    //       //this.showPivoGridFields = this.CurrentBlockIndex + "; " + newFirstItemIndex + "; " + newEndItemIndex;
    //     }

    //     // screenShowArray.push({
    //     //   index: -1,
    //     //   menulev: 1,
    //     //   name: "", //"UP",
    //     //   alt: "UP",
    //     //   icon: require("@/assets/img/MenuItem_UP_Arrow.png"),
    //     // });

    //     for (i = newFirstItemIndex; i < newEndItemIndex; i++) {
    //       screenShowArray.push(this.showArray[i]);
    //     }

    //     //20230823 CCL+ for SummaryType Scrolling
    //     // screenShowArray.push({
    //     //   index: newEndItemIndex + 1, //this.showArray.length + 1,
    //     //   menulev: 1,
    //     //   name: "", //"DOWN",//this.showArray.length + 1,
    //     //   alt: "DOWN",
    //     //   icon: require("@/assets/img/MenuItem_DOWN_Arrow.png"), // /assets/img/MenuItem_DOWN_Arrow.png
    //     // });

    //     this.menus[4].items = screenShowArray;
    //     menu.option("items", []);
    //     menu.option("items", this.menus); //20230824 CCL+ 要動態重新指定新items array,並生效,要加這一行; Raised Events: optionChanged
    //     //this.showPivoGridFields = screenShowArray.length;
    //     //this.showPivoGridFields += this.menus[4].items;
    //     menu.repaint();
    //     ///this.showPivoGridFields = this.menus[4].items;
    //     //this.showPivoGridFields = itemElement.innerHTML;
    //     //menu.dataSource.reload();
    //     //this.dataSource.state(this.state);
    //   }
    // },

    //預先樞紐
    // async settinggroup() {
    //   //let lstmyquery = JSON.parse(sessionStorage.getItem("lstmyquery"));
    //   let fields = JSON.parse(sessionStorage.getItem("allfields"));

    //   //console.log(fields);
    //   let inputfields = [];

    //   fields.forEach((element) => {
    //     let item = {};
    //     item.caption = element.columN_CAPTION + "(" + element.grouP_NAME + ")";
    //     item.dataField = element.columN_NAME;
    //     //item.area = "column";
    //     if (element.columN_FORMAT == "{0:d}") {
    //       item.dataType = "date";
    //     }
    //     //item.dataType = "date";
    //     item.headerFilter = {
    //       allowSearch: true,
    //     };
    //     inputfields.push(item);
    //   });

    //   for (let i = 0; i < inputfields.length; i++) {
    //     this.dataSource._fields.push(inputfields[i]);
    //   }
    // },

    // openChart() {
    //   this.isChartPopupVisible = true;
    // },

    // cancelClick() {
    //   //this.state = this.dataSource.state();
    //   //this.dataSource.reload();
    // },

    // itemClick(e) {
    //   console.log("e", e);
    //   if (e.itemData != null) {
    //     this.changeSelect(e.itemData.name);
    //   }
    // },

    //LOAD 沒問題
    /* 20230807 CCL- Orgin
    async LoadPivotGridViewStatus() {
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.LoginUserinfo.account,
        reportid: this.reportid,
      };

      let respfield = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/GetPivotXML",
        bodyParameters,
        config
      );
      if (respfield.data.success) {
        let summaryFields = JSON.parse(JSON.parse(respfield.data.payload)).fields;

        let testArray = summaryFields.map((item) => {
          return {
            name: item.dataField,
            items: [
              {
                parentItem: item.dataField,
                name: "selectMenu",
                value: "unselect",
              },
            ],
          };
        });

        //20230731 CCL+, Summary Type的submenuitem要根據Hidden/Showing Dialog內的item來顯示
        let pivot_column_select = this.lstmyquery.pivoT_COLUMN_SELECT;
        let aryPivotColumnSel = pivot_column_select.split('^');
        let showArray = [];
        for(var i=0; i<testArray.length; i++)
        {
          if(aryPivotColumnSel.includes(testArray[i].name) && testArray[i].name != "RN")
          {
              showArray.push(testArray[i]);
          } 
        }        

        //20230801 CCL- this.menus[4].items = testArray;
        this.menus[4].items = showArray; //testArray; //20230801 CCL+
        this.i++

        //20230731 CCL+, PivotGrid的fields array內的areaData要根據Hidden/Showing Dialog內的item來顯示
        const pivotGridFieldChooer = this.$refs.gridfieldchooer.instance;
        var pivotfieldchooerdata = pivotGridFieldChooer.getDataSource(); 
        //this.showPivoGridFields = pivotgridobj._fields; //this.dataSource._fields;
        for(i=0; i<pivotfieldchooerdata._fields.length; i++)
        {
          // && pivotgridobj._fields[i].area != "data"
          if(!aryPivotColumnSel.includes(pivotfieldchooerdata._fields[i].dataField) || 
              pivotfieldchooerdata._fields[i].dataField == "RN")
          {
            pivotfieldchooerdata._fields[i].visible = false;
          } else {
            pivotfieldchooerdata._fields[i].visible = true;
          } 
        }     
        pivotGridFieldChooer.repaint();        


        if (respfield.data.payload) {
          this.state = JSON.parse(JSON.parse(respfield.data.payload));

          console.log("this.state",this.state);
        } else {
          this.state = this.dataSource.state();
        }

        this.dataSource.state(this.state);
      }
    },
    */

    // saveCookie() {
    //   console.log(this.dataSource.getDataSource());
    //   console.log(this.dataSource.instance());
    //   console.log(this.dataSource.fields);
    //   // this.$cookies.set("PivotGridFieldChooser", this.dataSource, 60 * 60 * 24 * 30);
    //   // this.$cookies.set("token","GH1.1.1689020474.1484362313","60s");
    // },

    // onSubmenuShowing: function (e) {
    //   console.log("show", e);
    //   setTimeout(function () {
    //     if (
    //       e.component._visibleSubmenu &&
    //       e.component._visibleSubmenu._overlay.content().height() > 250
    //     )
    //       e.component._visibleSubmenu._overlay
    //         .content()
    //         .find(".dx-submenu")
    //         .height(250)
    //         .dxScrollView({});
    //     this.i++;
    //   });
    // },

    //#endregion
  },
  mounted() {
    console.log("mounted");
    //20230718 CCL* 反註解,使Chart有用
    const pivotGrid = this.$refs.grid.instance;
    const chart = this.$refs.chart.instance;
    pivotGrid.bindChart(chart, {
      dataFieldsDisplayMode: "singleAxis", //"singleAxis" "splitAxes" "splitPanes"
      alternateDataFields: false,
      inverted: false,
    });

    //20230731 CCL+ 從localStorage讀取UserAgentInfo///
    this.displayUserAgenInfo();

    //20230823 CCL+ 一段時間後沒動作自動跳轉登出(1hr)
    document.onmousedown = this.reCalculate;
    document.onmousemove = this.reCalculate;
    this.reCalculate();
  },

  created() {
    const lui = sessionStorage.getItem("LoginUserinfo");
    if (lui === undefined || lui === null) {
      //location.href=`http://localhost:8080/#/login`;
      location.replace(`/#/login`);
    }
    this.LoginUserinfo = JSON.parse(sessionStorage.getItem("LoginUserinfo"));
    this.reportid = "Turnkey_WIP";

    //載入資料
    let bodyParameters = JSON.parse(sessionStorage.getItem("bodyParameters"));
    this.Load(bodyParameters);

    // 這邊註解 但不確定有沒有影響
    // const pivotGrid = this.$refs.grid.instance;
    // const chart = this.$refs.chart.instance;
    // pivotGrid.bindChart(chart, {
    //   dataFieldsDisplayMode: "splitPanes",
    //   alternateDataFields: false,
    // });

    // const dataSource = pivotGrid.getDataSource();
    // //const dataSource =this.data;
    // setTimeout(() => {
    //   dataSource.expandHeaderItem("row", ["North America"]);
    //   dataSource.expandHeaderItem("column", [2013]);
    // }, 0);

    this.setSummaryTypeList();
  },
};
</script>

<style lang="scss">
.container {
  position: relative;
  margin-top: 20px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  top: 29px;
  bottom: 5px;
  left: 420px;
  width: 180px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.bottom-bar {
  width: 396px;
  text-align: right;
  margin-top: 5px;
}

#applyButton {
  margin-right: 10px;
}

.option-editor {
  margin-top: 5px;
}

.type-setting {
  display: flex;

  margin-bottom: 16px;

  .dx-field-label {
    width: 100px;
  }

  .dx-field-value {
    width: 170px !important;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 16px;

  .scaling {
    width: 200px;
    border: 1px solid #8b8b8b;
    border-radius: 5px;
    padding: 12px;
    text-align: end;
    background-color: #f7f7f7;

    input {
      width: 70px;
    }
  }
}

.update-btn {
  width: 68px;
  height: 27px;
  color: #000000;
  font: normal 12px Tahoma;
  vertical-align: middle;
  border: 1px solid #7f7f7f;
  background: #e0dfdf url(https://portal.asecl.com.tw/DXR.axd?r=2_4-uvo76)
    repeat-x top;
  // padding: 1px;
  cursor: pointer;
}

.layout-option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  input {
    display: none;

    &:checked ~ span {
      opacity: 0.5;
    }
  }

  .layout-img {
    height: 32px;
    width: 32px;
    display: inline-block;
    background-image: url("~@/assets/img/DXR.png");
    -background-image: url("~@/assets/img/DXR.png");
    background-repeat: no-repeat;
    background-color: transparent;

    &.Layout1 {
      background-position: 0px -32px;
    }
    &.Layout2 {
      background-position: -32px 0px;
    }
  }

  span {
    font-size: 0.7em;
    flex: 1;
  }
}

.change-mode {
  text-align: right;
  button {
    border-radius: 0;
    border: 1px solid #7f7f7f;
    cursor: pointer;
    padding: 5px 10px;
    span {
      display: block;
      background-image: url("~@/assets/img/DXR.png");
      -background-image: url("~@/assets/img/DXR.png");
      height: 16px;
      width: 16px;
      background-position: -96px -32px;
    }
  }
}

.dxpgCustForm_Glass {
  color: Black;
  font: 12px Tahoma;
  border: solid 1px #7eacb1;
  padding: 10px;
  margin-right: 5px;
  max-width: 320px !important;

  .dx-pivotgridfieldchooser {
    background-color: transparent;
  }
}

.pivotGrid-block {
  display: flex;

  .left-block {
    display: flex;
    flex-direction: column;

    .item-list {
      display: flex;
      flex-direction: column;

      margin-top: 0.5rem;
    }
  }
  .right-block {
    flex: 1;
  }
}

// .dx-context-menu > .dx-submenu {
//   background-color: red;
//   max-height: 350px !important;
//   overflow-y: scroll !important;
//   overflow-x: visible !important;
//   direction: rtl !important; /*更改顯示方向:左右*/
// }
// .dx-menu-item > .dx-submenu {
//   position: fixed !important;
//   direction: initial !important; /*更改顯示方向:左右*/
// }

.customerMenuStyle {
  .dx-submenu {
    max-height: 350px !important;
    overflow-y: scroll !important;
    overflow-x: visible !important;
    direction: rtl !important; /*更改顯示方向:左右*/
  }
  .dx-menu-item > .dx-submenu {
    position: fixed !important;
    direction: initial !important; /*更改顯示方向:左右*/
  }
}

//   .pivotMenu.dx-context-menu > .dx-menu-items-container {
//   max-height: 300px;
//   overflow-y: scroll;
//   overflow-x: visible;
// }

@media screen and (max-width: 767px) {
  .pivotGrid-block {
    display: block;
  }
}

.container {
  position: relative;
  margin-top: 20px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  top: 29px;
  bottom: 5px;
  left: 420px;
  width: 180px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.bottom-bar {
  width: 396px;
  text-align: right;
  margin-top: 5px;
}

#applyButton {
  margin-right: 10px;
}

.option-editor {
  margin-top: 5px;
}
#charts {
  overflow: auto;
}
svg.dxc.dxc-chart {
  zoom: 1.5;
}
</style>
