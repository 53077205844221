<template>
    <div class="content">
      <div class="page-title">
        <div class="title">Company Management</div>
        <div class="button-wrap">
          <button>
            <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
          </button>
          <button>
            <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
          </button>
        </div>
      </div>
      <p>
        <span class="label label-info">Picked Company: {{ this.queryCompanyId }}</span>
      </p>
      <div class="index-menu">
        <div class="menu-item" v-for="rpt in this.reports" :key="rpt.reportId">
          <a>
            <img src="~@/assets/img/TurnkeyWIP.png" :alt="rpt.iconFileName" width="100" />
            <span>{{ rpt.iconLabel  }}</span>
          </a>
        </div>
      </div>
      <router-link class="btn btn-sm btn-warning" to="/management/company/index">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>&nbsp;<span style="color: white;">Back</span>
          </router-link>
    </div>
</template>
<script>

let reports = [];
let queryCompanyId = '';

export default {
    data() {
        return {
          queryCompanyId,
          reports
        }
    },
    mounted() {
      this.GetQueryString();
      this.LoadData();
    },

    components: {},

    methods: {
      async LoadData() {
        const config = {
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token")
          },
        }
        let resp = await this.axios.get(
          //this.$store.state.axios_local_url + `/api/report/${this.queryCompanyId}`,
          this.$store.state.axios_url + `/api/report`,
          {},
          config
        );
        console.log(`===resp===`)
        console.log(resp);
        if (resp.status === 200 && resp.data.length > 0) {
          console.log(`===resp.data===`);
          console.log(resp.data);
          this.reports = [];
          this.reports = resp.data;
        }
      }, // end of LoadData()

      GetQueryString() {
        let qCompanyId = this.$route.query.companyid;
        if (qCompanyId === undefined) {
          qCompanyId = "DEMO";
        }
        this.queryCompanyId = qCompanyId;
        console.log(`===this.queryCompanyId===`);
        console.log(this.queryCompanyId);
      }, // end of GetQueryString()

      BuildImgSrc(name) {
        return `/img/${name}`;
      }

    },

    watch: {},
};
</script>