<template>
    <div class="content">
        <div class="page-title">
            <div class="title">Metafile Management Setting - Add New</div>
            <div class="button-wrap">
                <button>
                    <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
                </button>
                <button>
                    <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
                </button>
            </div>
        </div>
        <form>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label">Company</label>
                <div class="col-sm-10">
                    <span>{{ this.companyId }}</span>
                </div>
            </div>
            <div class="row mb-3">
                <label for="_reportType" class="col-sm-2 col-form-label"><span style="color:red;">*</span>Report Type</label>
                <div class="col-sm-10">
                    <DxTextBox label="Report Type" v-model="reportType" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="_iconlabel" class="col-sm-2 col-form-label"><span style="color:red;">*</span>ICON Label</label>
                <div class="col-sm-10">
                    <DxTextBox label="ICON Label" v-model="iconLabel" />
                </div>
            </div>
            <div class="row mb-3">
                <label for="_urlPath" class="col-sm-2 col-form-label">New Path</label>
                <div class="col-sm-10">
                    <DxTextBox id="_urlPath" label="Path" v-model="urlPath" />
                </div>
            </div>
        </form>
        <p/>
        <a class="btn btn-sm btn-warning" :href="buildMetafilesIndexLink()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>&nbsp;<span style="color: white;">Back</span>
        </a>&nbsp;&nbsp;
        <DxButton text="Save" @click="toSaveNew()" />
    </div>
</template>
<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';

let companyId = '';
let reportType = '';
let iconLabel = '';
let urlPath = '';
let target = '';

const apiConfig = {
  headers: {
    "Authorization": "bearer " + sessionStorage.getItem("token"),
    "Content-Type": "application/json"
  }
};

export default {
    data() {
        return {
            companyId,
            reportType,
            iconLabel,
            urlPath,
            target
        };
    },

    components: {
    DxTextBox,
    DxButton
},

    mounted() {
        this.getQueryString();
    },

    methods: {
        getQueryString() {
            let qCompanyId = this.$route.query.companyid;
            console.log(`===qCompanyId===`);
            console.log(qCompanyId);
            if (qCompanyId === undefined) {
                qCompanyId = "DEMO";
            }
            this.companyId = qCompanyId;
            console.log(`===this.queryCompanyId===`);
            console.log(this.companyId);
        }, // end of GetQueryString()

        async toSaveNew() {
            if (this.iconLabel.length === 0) {
                alert(`ICON Label 必填`);
                return;
            }
            if (this.reportType.length === 0) {
                alert(`Report Type 必填`);
                return;
            }
            if (!this.urlPath || this.urlPath.length === 0) {
                this.urlPath = '  ';
            }
            if (!this.target || this.target.length === 0) {
                this.target = '_blank';
            }
            const newData = [{
                "iconLabel": this.iconLabel,
                "companyId": this.companyId,
                "urlPath": this.urlPath,
                "target": this.target,
                "reportType": this.reportType
            }];
            console.log(newData);
            const resp = await this.axios.post(
                // this.$store.state.axios_local_url + `/api/metafile`,
                this.$store.state.axios_url + `/api/metafile`,
                newData,
                apiConfig
            );
            console.log(resp);
            if (resp.status === 200) {
                this.reportType = '';
                this.iconLabel = '';
                this.urlPath='';
                alert(`Add OK`);
            }
        }, // end of toSaveNew

        buildMetafilesIndexLink() {
            return `/#/management/company/metafile?companyid=${this.companyId}`;
        }
    }
}
</script>