<template>
  <div class="login-page">
    <div class="form-wrap">
      <img src="~@/assets/img/logo.png" alt="" />
      <div class="inner-form">
        <div class="title">
          Customer Service<br />
          Infomation System
        </div>
        <div class="line"></div>
        <div class="form">
          <slot />

        </div>
      </div>
      <div class="tip">Browser support IE 10+,Chrome,Opera Could be better</div>
      

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-page {
  display: flex;
  justify-content: center;

  background-image: url("~@/assets/MasterPage_Background_login.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  .form-wrap {
    width: 344px;

    margin: auto 0;
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    .inner-form {
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      background-color: rgba(#fff, 0.4);
      .title {
        background-color: #303b3b;
        color: #fff;
        padding: 10px 20px;
        font-size: 1.5em;
        text-align: center;
      }
      .line{
        height: 9px;
        border-bottom: 4px solid #303b3b;
      }
      .form {
        padding: 30px;

        input {
          border-radius: 5px;
          background-color: #FFF;
        }

        .dx-button.dx-button-default {
          background-color: #368d96;
        }

        .login-form .link a{
          color: #FFF!important;
        }
      }
    }
    .tip{
      color: #FFF;
      padding: 10px;

    }
  }
}


.screen-large {
  .footer {
    display: none;
  }
}
</style>
