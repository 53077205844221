<template>
    <div class="content">
        <div class="page-title">
            <div class="title">Group Info</div>
            <div class="button-wrap">
                <button>
                    <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
                </button>
                <button>
                    <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
                </button>
            </div>
        </div>
        <p>Group Info</p>
        <DxTabPanel
          :height="260"
          :data-source="data"
          :selected-index="selectedIndex"
          :loop="false"
          :animation-enabled="true"
          :swipe-enabled="true"
        >
        <template #title="{ data: c }">
            <span>{{ c.tabName }}</span>
        </template>
        <template #item="{ data: company }">
            <div class="tabpanel-item">
                <div>
                    <p>{{ company.tab }} List</p>
                    <p>Grid</p>
                </div>
            </div>
        </template>
        </DxTabPanel>
    </div>
</template>
<script>
import DxTabPanel from 'devextreme-vue/tab-panel';


export default {
    data() {
        return {
            selectedIndex: 0,
            data: [
                {"name": "A Name", "fullName": "A Very Long Name", "code": "ASE", "tabName": "Company Configuration", "tab": "Company"},
                {"name": "B Name", "fullName": "Huge Long Name Bb", "code": "____", "tabName": "Group Configuration", "tab": "Group"},
                {"name": "C Name", "fullName": "Huge Long Name CC", "code": "__cc__", "tabName": "Account Configuration", "tab": "Account"},
                {"name": "D Name", "fullName": "Huge Long Name dd", "code": "___DD_", "tabName": "FTP Configuration", "tab": "FTP"},
            ],
            tabList: [ 'Company Configuration', 'Group Configuration', 'Account Configuration', 'FTP Configuration' ]
        }
    },

    components: {
        DxTabPanel
    },

    methods: {},

    watch: {},
};
</script>