<template>
    <div class="content">
        <div class="page-title">
            <div class="title">Company Management</div>
            <div class="button-wrap">
                <button>
                    <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
                </button>
                <button>
                    <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
                </button>
            </div>
        </div>
        <div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h6 class="panel-title">Function Link</h6>
            </div>
            <div class="panel-body">
              <router-link class="btn btn-success" href="javascript:;" to="/management/company/group-info">
                <span class="label">Group Info</span>
              </router-link>&nbsp;
              <a class="btn btn-success" href="javascript:;">
                <span class="label">System Role Assignment</span>
              </a>
            </div>
          </div>
          <p/>
        </div>
        <div>
          <DxDataGrid
            :data-source="companies"
            :remote-operations="false"
            :allow-column-reordering="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :show-row-lines="true"
            :show-borders="true"
            :width="'100%'"
          >
            <DxColumn
              data-field="id"
              caption="COMPANY NAME"
              data-type="text"
              width="200"
            />
            <DxColumn
              data-field="fullName"
              caption="COMPANY FULLNAME"
              data-type="text"
              :width="450"
            />
            <DxColumn
              data-field="isActive"
              data-type="boolean"
              caption="ACTIVE"
            />
            <DxColumn
              data-field="id"
              data-type="text"
              caption="METAFILE MANAGEMENT"
              cell-template="metafileCellTemplate"
              :allow-filtering="false"
            >
            </DxColumn>
            <DxColumn
              data-field="id"
              data-type="text"
              caption="REPORT AUTHORIZATION"
              cell-template="reportCellTemplate"
              :allow-filtering="false"
            />
            <template #activeCellTemplate="{ data }">
              <input type="checkbox" class="form-check-input" :checked="SetCheckboxChecked(data.value)" onclick="return false;" />
            </template>
            <template #metafileCellTemplate="{ data }">
              <a class="btn btn-sm btn-primary" :href="BuildMetafileLink(data.value)" data-description="METAFILE MANAGEMENT">
                <svg xmlns="http://www.w3.org/2000/svg" style="color: white;" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
                </svg>&nbsp;<span style="color: white;">Link</span>
              </a>
            </template>
            <template #reportCellTemplate="{ data }">
              <a class="btn btn-sm btn-primary" :href="BuildReportAuthorizationLink(data.value)"  data-description="REPORT AUTHORIZATION">
                <svg xmlns="http://www.w3.org/2000/svg" style="color: white;" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
                </svg>&nbsp;<span style="color: white;">Link</span>
              </a>
            </template>
            <DxFilterRow :visible="true" />
            <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="true"
              :show-info="false"
              :show-navigation-buttons="true"
            />
            <DxPaging :page-size="10"/>
          </DxDataGrid>
          <p/>
          <router-link class="btn btn-sm btn-danger" to="/management/company/index">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>&nbsp;<span style="color: white;">Back</span>
          </router-link>
        </div>
    </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging
} from 'devextreme-vue/data-grid';
const dataGridRef = "dataGrid";

const pageSizes = [10, 25, 50, 100];

let companies = [];

export default {
    data() {
        return {
            dataGridRef,
            pageSizes,
            companies
        }
    },
    mounted() {
      this.Load();
    },

    components: {
        DxDataGrid,
        DxColumn,
        DxFilterRow,
        DxPager,
        DxPaging
    },

    methods: {
      async Load() {
        const config = {
          headers: {
            Authorization: "bearer " + sessionStorage.getItem("token"),
            AccessToken: "bearer " + sessionStorage.getItem("token")
          },
        }
        let resp = await this.axios.get(
          // this.$store.state.axios_local_url + "/api/company",
          this.$store.state.axios_url + "/api/company",
          {},
          config
        );
        console.log(`===resp===`)
        console.log(resp);
        if (resp.status === 200 && resp.data.length > 0) {
          console.log(`===resp.data===`);
          console.log(resp.data);
          this.companies = [];
          //this.companies.push(resp.data);
          this.companies = resp.data;
        }
        console.log(`===companies===`);
        console.log(companies);
      }, // end of Load()

      BuildMetafileLink(href) {
        return `/#/management/company/metafile?companyid=${href}`;
      }, // end of BuildMetafileLink(href)

      BuildReportAuthorizationLink(href) {
        return `/#/management/company/report-authoriaztion?companyid=${href}`;
      }, // end of BuildReportAuthorizationLink(href)

      SetCheckboxChecked(value) {
        return `checked="${value ? 'checked' : ''}"`;
      }
    },

    watch: {},
};
</script>
