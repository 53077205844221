import { JSEncrypt } from  'jsencrypt'
import axios from 'axios';

export default function(password) {
    // const api = `${window.BaseUrl.api}/auth/PublicKey`;
    const api = this.$store.state.axios_url + "/api/Login/PublicKey";

    return axios
      .get(api)
      .then(async (response) => {
        if (response.status === 200) {
          let jsEncrypt = new JSEncrypt();
          jsEncrypt.setPublicKey(response.data);
          let encrypted = jsEncrypt.encrypt(password);
          let encryptedTransfer = encodeURI(encrypted).replace(/\+/g, "%2B");

          return encryptedTransfer   
        }
      })

}