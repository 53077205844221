<template>
  <div>
    <div class="page-title">
      <div class="title">Production Info - Favorites</div>

      <div class="button-wrap">
        <button>
          <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
        </button>
        <button>
          <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
        </button>
      </div>
    </div>
    <div class="index-menu">
      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/AssyCycleTime.png" alt="" />
          <span>Assy Cycle Time</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/CPCycleTime.png" alt="" />
          <span>CP Cycle Time</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/TestYield.png" alt="" />
          <span>Test Yield</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo?rpid=Turnkey_WIP" class="menu-item">
          <img src="~@/assets/img/TurnkeyWIP.png" alt="" />
          <span>Turnkey WIP</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/AssyYield.png" alt="" />
          <span>Assy Yield</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/DieInput.png" alt="" />
          <span>Die Input</span>
        </router-link>
      </div>
      <div class="menu-item">
        <router-link to="/demo?rpid=Shipment">
          <img src="~@/assets/img/Shipment.png" alt="" />
          <span>Shipment</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/TestCycleTime.png" alt="" />
          <span>Test Cycle Time</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/CPYeild.png" alt="" />
          <span>CP Yield</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/NewInputOutput.png" alt="" />
          <span>New Input Output</span>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link to="/demo">
          <img src="~@/assets/img/DieReceive.png" alt="" />
          <span>Die Receive</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    const lui = sessionStorage.getItem("LoginUserinfo");
    if (lui === undefined || lui === null){
      //location.href=`http://localhost:8080/#/login`;
      location.replace(`/#/login`)
    }
  },

  methods: {},

  watch: {},
};
</script>

<style lang="scss" scoped>
</style>
