<template>
  <div class="custom-page">
    <div class="logo">
      <img src="~@/assets/img/MasterPage_Frame_Logo.png" alt="" />
    </div>
    <div class="nav">
      <div class="system-name">
        Customer Services<br />
        Information System
      </div>
      <div class="system-function">
        <router-link to="/management/index" class="nav-item">
          <img src="~@/assets/img/Shortcut_Management.png" alt="" /> 
          <span>Management</span>          
        </router-link>

         <router-link to="/home" class="nav-item">          
          <img src="~@/assets/img/Shortcut_Report.png" alt="" />
          <span>Production Info</span> 
        </router-link>

        <a class="nav-item">
          <img src="~@/assets/img/Shortcut_Subscribe.png" alt="" />
          <span>Subcribe</span> 
        </a>
        <a class="nav-item">
          <img src="~@/assets/img/Shortcut_ContactUs.png" alt="" />
          <span>ContactUs</span> 
        </a>
        <router-link to="/change-password/1" class="nav-item">
          <img src="~@/assets/img/Shortcut_Management.png" alt="" />
          <span>Change Password</span> 
        </router-link>
        <router-link to="/login" class="nav-item">
          <img src="~@/assets/img/Shortcut_Logout.png" alt="" />
          <span>Logout</span> 
        </router-link>
      </div>
    </div>
    <div class="content">
      <div class="content-inner">
        <DxScrollView ref="scrollViewRef">
          <slot />
        </DxScrollView>
      </div>
    </div>
  </div>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";
export default {
  components: {
    DxScrollView,
  }

};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

</style>
