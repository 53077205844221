import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './assets/bootstrap/dist/css/bootstrap.css';

import Vue from "vue";

import App from "./App";
import router from "./router";
import store from "./store";
import appInfo from "./app-info";
// cookies
import VueCookies from "vue-cookies";

// Loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.component("isLoading", Loading);

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.use(VueCookies);


//  密碼加密
import encryption_password from "@/assets/js/password/rsa.js";
Vue.prototype.encryption_password = encryption_password;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
