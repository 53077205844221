<template>
  <footer class="footer">
    <div class="mode-select">
      <span>
          <img @click="toggleMode('pc')" :class="{'active':currentMode==='pc'}" src="~@/assets/img/Shortcut_PC2.png" alt="" /></span>
      <span>        
          <img @click="toggleMode('pad')" :class="{'active':currentMode==='pad'}" src="~@/assets/img/Shortcut_PAD2.png" alt="" />
      </span>
    </div>
    <!--20230828 CCL* Copyright Yahome改成 ASECL-->
    Copyright © {{ new Date().getFullYear() }} ASECL
    <!-- Copyright © 2011-{{new Date().getFullYear()}} {{this.$appInfo.title}} Inc.
      <br />All trademarks or registered trademarks are property of their
      respective owners. -->
  </footer>
</template>

<script>

export default {
  name: "app",
  data() {
    return {
      currentMode:"pad"
    };
  },
  
  methods: {    
    toggleMode(mode) {
      this.currentMode = mode;
      if(mode==='pc') {
        document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
          "content",
          "width=device-width,initial-scale=1,maximum-scale=0.7,user-scalable=yes,shrink-to-fit=no"
        );  
      }else{
        document
        .querySelector('meta[name="viewport"]')
        .setAttribute("content", "width=device-width,initial-scale=1");
      }
    }
  },

  mounted() {},

};
</script>

<style lang="scss">
// @import "../themes/generated/variables.base.scss";

.footer {
  .mode-select {
    // @media (min-width: 769px) {
    //   display: none;
    // }

    //20230828 CCL+ 辨別PC/PAD 來決定是否顯示footer的PC/PAD圖示Bar
    @media screen and (min-width: 769px){
        display: none; //block;
        //visibility: hidden; //visible; //hidden;
    }    

    img{
      opacity: 0.5;

      &.active{
        opacity: 1;
      }
    }
  }
  padding: 10px 0;
  background-color: #082025;
  color: #ffffff;
  font-size: 10pt;
  text-align: center;
}
</style>
