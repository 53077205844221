<template>
  <div>
    <isLoading :active.sync="isLoading" loader="dots"></isLoading>
    <div class="page-title">
      <!--20230814 CCL+ Report Last Updated End time -->
      <!-- div class="title">Turnkey WIP </div -->
      <div class="title">
        {{ iconlabel }}
        <span class="reportlastupdendtime"
          >Last Updated:{{ reportLastUpdatedEndTime }}</span
        >
      </div>

      <div class="button-wrap">
        <button>
          <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
        </button>
        <button>
          <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
        </button>
      </div>
    </div>
    <!-- 20230707 CCL+啟用click勾選  :activeStateEnabled="true"   -->
    <div class="filter-block">
      <dxMenu
        id="menu"
        :data-source="menus"
        :show-first-submenu-mode="showSubmenuModes"
        :orientation="orientation"
        :hide-submenu-on-mouse-leave="hideSubmenuOnMouseLeave"
        display-expr="name"
        @item-click="menuItemClick"
        :selectByClick="true"
        :activeStateEnabled="true"
      >
      </dxMenu>
    </div>

    <!--20230828 CCL+ 新增no Data時要顯示的文字 noDataText  -->
    <DxDataGrid
      :ref="dataGridRef"
      key-expr="RN"
      :data-source="data"
      :focused-row-enabled="true"
      :focused-row-index="-1"
      :load-panel="loadPanel"
      :show-borders="true"
      :show-column-lines="showColumnLines"
      :show-row-lines="showRowLines"
      :column-auto-width="true"
      :allowColumnReordering="true"
      @exporting="onExporting"
      :allow-column-resizing="allowResizingMode"
      :column-resizing-mode="currentMode"
      :noDataText="'No data to diaplay'"
    >
      <!-- @option-changed="handlePropertyChange" -->
      <DxSorting mode="multiple" />
      <DxStateStoring
        :enabled="true"
        type="custom"
        :storage-key="storageKey"
        :custom-load="loadState"
        :custom-save="saveState"
        :saving-timeout="200"
      />

      <dx-column
        data-field="RN"
        caption="Row"
        :width="2"
        :cssClass="customerColumnStyle"
        :visible-index="column1Index"
        :fixed="true"
        :visible="false"
      />

      <dx-column
        v-for="(item, index) in groupfields"
        :key="index + 'groupfieldlevel1'"
        :data-field="item.columN_NAME"
        :caption="item.columN_CAPTION"
        :allowGrouping="item.allowGrouping"
        :visible="item.standardflag === 1 || item.standardflag == true"
        :cssClass="customerColumnStyle"
        :fixed="item.scorllfix"
      >
        <dx-column
          v-for="(item2, index2) in item.suB_COLUMNS"
          :key="index + '_' + index2 + 'groupfieldlevel2'"
          :data-field="item2.columN_NAME"
          :caption="item2.columN_CAPTION"
          :header-filter="item2.headerFilter"
          :allowFiltering="item2.allowFiltering"
          :allowGrouping="item2.allowGrouping"
          :cssClass="customerColumnStyle"
          :visible="item2.standardflag === 1 || item2.standardflag == true"
        />
      </dx-column>

      <dx-filter-row :visible="showFilterRow" />
      <dx-header-filter :visible="showHeaderFilter" />
      <dx-export :enabled="false" />
      <dx-paging :page-size="pagesize" />
      <dx-pager :show-page-size-selector="false" :show-info="true" />
      <DxScrolling mode="infinite" v-if="this.pagesize >= 99999" />
      <DxScrolling mode="standard" v-else />
    </DxDataGrid>

    <!-- Query Form -->
    <DxPopup
      :visible="isQueryFormPopupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :show-close-button="true"
      @hidden="onQueryHidden('isQueryFormPopupVisible')"
      :show-title="true"
      width="100%"
      maxWidth="750px"
      height="430px"
      container=".dx-viewport"
      :title="UserLastQueryTime"
    >
      <!-- 2.2.2 新增讀取N_RPT_CRITERION.UDT欄位值, 並轉成最後QueryForm Popup視窗按下Report鈕的時間 :title -->
      <!-- title="Query Condition            User's last time:2022/12/26 01:48:32 PM" -->
      <DxPosition my="center" />
      <DxScrollView width="100%" height="100%">
        <!-- 新的查詢畫面 -->
        <table class="query-table">
          <tr
            v-for="(row, idx) in this.queryFormMainRows"
            :key="idx + 'queryFormMainRows'"
          >
            <!-- 第一欄 pk fk -->
            <td style="width: 3%">
              <img
                src="~@/assets/img/Key.png"
                :alt="row.forcE_PROTECTION_CONDITION"
                v-if="row.forcE_PROTECTION_CONDITION === 'T'"
              />
              <img
                src="~@/assets/img/Flag_redHS.png"
                :alt="row.forcE_PROTECTION_CONDITION"
                v-else-if="row.forcE_PROTECTION_CONDITION === 'D'"
              />
              <img
                src="~@/assets/img/flag.png"
                :alt="row.forcE_PROTECTION_CONDITION"
                v-else
              />
            </td>
            <!-- 第二欄 查詢條件欄位 -->
            <td style="width: 52%">
              <input type="hidden" :value="row.forcE_PROTECTION_CONDITION" />
              <input type="hidden" :value="row.columN_NAME" />
              <DxSelectBox
                v-if="
                  row.forcE_PROTECTION_CONDITION === 'T' ||
                  row.forcE_PROTECTION_CONDITION === 'D'
                "
                width="auto"
                :data-source="genSelectionTextArray(row)"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="genSelectionTextArray(row)[0].value"
                :disabled="true"
                :search-enabled="false"
              />
              <DxSelectBox
                v-else-if="
                  !(
                    row.forcE_PROTECTION_CONDITION === 'T' ||
                    row.forcE_PROTECTION_CONDITION === 'D'
                  )
                "
                width="auto"
                :data-source="genTDElsesArray()"
                display-expr="text"
                value-expr="value"
                :accept-custom-value="true"
                :value="genSelectionTextArray(row)[0].value"
                placeholder=""
                :search-enabled="false"
                :noDataText="''"
                @selection-changed="onConditionColumnSet1($event, idx, row)"
              />
            </td>
            <!-- @selection-changed="onCondColumnSet1" -->

            <!-- 第三欄 查詢的條件 =, >, <, >= 等 -->

            <td style="width: 15%" v-if="row.columN_NAME == 'CUST_ID'">
              <dx-select-box
                width="100px"
                :data-source="CustIdQueryOptions"
                display-expr="value"
                value-expr="value"
                :search-enabled="true"
                placeholder=""
                :value="row.condition"
                @value-changed="onCondColumnSet($event, idx)"
              />
              <!-- :value="selectedConditionValue(idx, 1)[0].value" -->
            </td>
            <td style="width: 15%" v-else-if="row.columN_NAME == 'SDATE'">
              <dx-select-box
                width="100px"
                :data-source="SDateQueryOptions"
                display-expr="value"
                value-expr="value"
                :search-enabled="true"
                placeholder=""
                :value="row.condition"
                @value-changed="onCondColumnSet($event, idx)"
              />
            </td>
            <td style="width: 15%" v-else>
              <dx-select-box
                width="100px"
                :data-source="QueryOptions"
                display-expr="value"
                value-expr="value"
                :search-enabled="true"
                placeholder=""
                :value="row.condition"
                @value-changed="onCondColumnSet($event, idx)"
              />
            </td>

            <!-- 第四欄 查詢的條件值 -->
            <td style="width: 30%">
              <input type="hidden" name="idx" :value="idx" />
              <input
                type="hidden"
                :value="selectedConditionValue(idx, 3)[0].value"
              />
              <DxSelectBox
                v-if="idx == 0"
                width="auto"
                :data-source="condsAtColumn3R0"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R0[0].value"
                :accept-custom-value="false"
                :search-enabled="false"
                :noDataText="''"
              />
              <!-- @value-changed="onchangedAA" -->

              <DxSelectBox
                v-else-if="idx == 1"
                width="auto"
                :data-source="condsAtColumn3R1"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R1[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R1')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R1')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row1"
                @closed="onCombocClosed($event, 'condsAtColumn3R1')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R1')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R1')" -->
              <!-- @selection-changed="onCondColumnSet3(idx)" -->

              <DxSelectBox
                v-else-if="idx == 2"
                width="auto"
                :data-source="condsAtColumn3R2"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R2[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R2')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R2')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row2"
                @closed="onCombocClosed($event, 'condsAtColumn3R2')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R2')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R2')" -->
              <DxSelectBox
                v-else-if="idx == 3"
                width="auto"
                :data-source="condsAtColumn3R3"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R3[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R3')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R3')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row3"
                @closed="onCombocClosed($event, 'condsAtColumn3R3')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R3')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R3')" -->
              <DxSelectBox
                v-else-if="idx == 4"
                width="auto"
                :data-source="condsAtColumn3R4"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R4[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R4')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R4')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row4"
                @closed="onCombocClosed($event, 'condsAtColumn3R4')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R4')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R4')" -->
              <DxSelectBox
                v-else-if="idx == 5"
                width="auto"
                :data-source="condsAtColumn3R5"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R5[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R5')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R5')"
              />              
              <!-- @custom-item-creating="onCustomItemCreating_row5"
                @closed="onCombocClosed($event, 'condsAtColumn3R5')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R5')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R5')" -->
              <DxSelectBox
                v-else-if="idx == 6"
                width="auto"
                :data-source="condsAtColumn3R6"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R6[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R6')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R6')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row6"
                @closed="onCombocClosed($event, 'condsAtColumn3R6')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R6')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R6')" -->
              <DxSelectBox
                v-else-if="idx == 7"
                width="auto"
                :data-source="condsAtColumn3R7"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R7[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R7')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R7')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row7"
                @closed="onCombocClosed($event, 'condsAtColumn3R7')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R7')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R7')" -->
              <DxSelectBox
                v-else-if="idx == 8"
                width="auto"
                :data-source="condsAtColumn3R8"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R8[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R8')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R8')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row8"
                @closed="onCombocClosed($event, 'condsAtColumn3R8')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R8')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R8')" -->
              <DxSelectBox
                v-else-if="idx == 9"
                width="auto"
                :data-source="condsAtColumn3R9"
                display-expr="text"
                value-expr="value"
                placeholder=""
                :value="condsAtColumn3R9[0].value"
                :accept-custom-value="true"
                custom-item-create-event="focusout"
                :search-enabled="true"
                :noDataText="''"
                @custom-item-creating="(e) =>onCustomItemCreating(e,'condsAtColumn3R9')"
                @value-changed="(e) => onCustomItemChange(e, 'condsAtColumn3R9')"
              />
              
              <!-- @custom-item-creating="onCustomItemCreating_row9"
                @closed="onCombocClosed($event, 'condsAtColumn3R9')"
                @opened="onComboxOpened"
                @value-changed="onComboxValueChange($event, 'condsAtColumn3R9')"
                @focus-in="onComboxFocusin($event, 'condsAtColumn3R9')" -->
            </td>
          </tr>
        </table>

        <div>
          <!-- set Detail為defaule -->
          <!-- 20230710 CCL- Report Type: <input name="ReportType" type="radio" checked="true"  /> Detail
          <input name="ReportType" type="radio" /> Summary -->
          <!-- 20230710 CCL+ 增加判斷導引至Pivot頁面 -->
          <!-- 20230711 CCL* 拿掉checked="true", 改用v-model -->
          Report Type:
          <input
            name="ReportType"
            type="radio"
            value="Detail"
            v-model="pushQueryType"
          />
          Detail
          <input
            name="ReportType"
            type="radio"
            value="Summary"
            v-model="pushQueryType"
          />
          Summary

          <button class="dxButton mr-2" @click="resetQuery()">Reset</button>
          <button class="dxButton" @click="query()">Query</button>
        </div>
      </DxScrollView>
    </DxPopup>

    <!-- Report Info -->
    <DxPopup
      :visible="isReportInfoPopupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :close-on-outside-click="true"
      @hidden="onQueryHidden('isReportInfoPopupVisible')"
      :show-close-button="true"
      :show-title="true"
      :width="500"
      :height="400"
      container=".dx-viewport"
      title="Report Info"
    >
      <DxPosition my="center" />

      <!-- 2.13 Report Info:報表資訊 顯示 -->
      <DxScrollView
        id="scrollview"
        ref="scrollViewWidget"
        :scroll-by-content="scrollByContent"
        :scroll-by-thumb="scrollByThumb"
        :show-scrollbar="showReportInfoScrollbar"
        :bounce-enabled="pullDown"
        reach-bottom-text="Updating..."
      >
        <div
          id="ctl00_MasterPage_Content_ctl00_xPopupControl_ReportInfo_CSD-1"
          class="dxpcContentPaddings_Glass"
          style="overflow: auto; margin-right: 0px"
        >
          <table>
            <tbody>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <!--20230810 CCL* 拿掉寫死的Report ID Turnkey_WIP -->
                  <span style="font-size: medium; color: #000000"
                    >Report ID:{{ reportid }}
                    <p>
                      <span style="font-size: medium; color: #000000"
                        >User Login ID:{{ loginUserinfo.account }}</span
                      >
                    </p>
                    <p>
                      <span style="font-size: medium; color: #000000"
                        >User Role ID:{{ loginUserinfo.roleid }}</span
                      >
                    </p>
                    <p>
                      <span style="font-size: medium; color: #000000"
                        >User Company ID:{{ loginUserinfo.companyid }}</span
                      >
                    </p>
                    <!-- 2.13 Report Info:報表資訊 顯示 -->
                    <p v-if="loginUserinfo.roleid == 0">
                      <span style="font-size: medium; color: #000000">
                        &lt;&lt; Seesion lst for CSWEB Admin to debug only
                        &gt;&gt;
                      </span>
                    </p>
                    <!-- 20230704 CCL+ 加入Content Wrap style -->
                    <div
                      style="inline-size: 410px; overflow-wrap: break-word"
                      v-if="loginUserinfo.roleid == 0"
                      v-html="userAgentInfoHtml"
                    ></div>
                    <!-- 2.13.1 加入登入者的MAINSQL -->
                    <!--20230822 CCL- div style="inline-size: 410px; overflow-wrap: break-word;" 
                        v-if="loginUserinfo.roleid == 0" v-html="userMainSQLInfoHtml">
                    </div -->
                    <!--20230822 CCL+ 改成取得GetStandard返回Message,成功撈到Data,Message會傳回成功CombineSql -->
                    <div
                      style="inline-size: 410px; overflow-wrap: break-word"
                      v-if="loginUserinfo.roleid == 0"
                      v-html="userCombineSQLInfoHtml"
                    ></div>
                    <p><br /></p>
                  </span>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
        <!-- 2.13 Report Info:報表資訊 顯示 -->
      </DxScrollView>
    </DxPopup>

    <!-- 隱藏欄位開窗 -->
    <DxPopup
      :visible="isHiddingShowPopupVisible"
      :drag-enabled="true"
      :hide-on-outside-click="true"
      :close-on-outside-click="true"
      @hidden="onQueryHidden('isHiddingShowPopupVisible')"
      :show-close-button="true"
      :show-title="true"
      :width="1300"
      :height="680"
      container=".dx-viewport"
      title="Hiding/Showing grid columns"
      :resizeEnabled="true"
    >
      <DxPosition my="center" />

      <!--20230704 CCL- DxScrollView width="100%" height="100%" -->
      <DxScrollView
        id="scrollview2"
        ref="scrollViewWidget"
        :scroll-by-content="scrollByContent"
        :scroll-by-thumb="scrollByThumb"
        :show-scrollbar="showReportInfoScrollbar"
        :bounce-enabled="pullDown"
        reach-bottom-text="Updating..."
        height="100%"
        width="100%"
      >
        <div id="hiddenField" style="overflow: auto; margin-right: 0px">
          <!--20230810 CCL* 拿掉style="display: inline-block" 改成 style="display: block"-->
          <div
            v-for="(item, index) in groupfields"
            :key="index + 'groupfieldslevel1'"
            style="display: block"
          >
            <label>
              <!-- 20230717 CCL- 2.11.2 移除group checkbox -->
              <!-- 20230717 CCL- input type="checkbox" v-model="item.standardflag" / -->
              <span style="color: blue; font-weight: bold">{{
                item.columN_CAPTION
              }}</span>
            </label>
            <br />
            <div
              v-for="(item2, index2) in item.suB_COLUMNS"
              :key="index + '_' + index2 + 'groupfieldslevel2'"
              style="display: inline-block"
            >
              <label>
                <input
                  type="checkbox"
                  v-model="item2.standardflag"
                  @change="checkGroupfields($event, item)"
                />
                {{ item2.columN_CAPTION }}
              </label>
            </div>
            <hr />
          </div>
          <!-- 20230802 CCL+ 改成按下Query Button後才更新畫面 && 寫column_select項到DB  -->
          <div>
            <span
              ><button class="dxButton" @click="clearColumns(groupfields)">
                Clear
              </button></span
            >
            <!-- span style="margin-left: 30px;"><button class="dxButton" @click="SelAllColumns(groupfields)">SelectAll</button></span -->
            <!--20230821 CCL* Query名稱改為Submit -->
            <span style="margin-left: 30px"
              ><button class="dxButton" @click="saveHideShowColumns()">
                Submit
              </button></span
            >
            <!-- div>{{ show_COLUMN_SELECT }}</div -->
          </div>
        </div>
      </DxScrollView>
    </DxPopup>

    <DxPopup
      :visible="isSavePopupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="300"
      :height="200"
      container=".dx-viewport"
      title="Save As New Report"
    >
      <DxPosition my="center" />

      <table class="custom-table">
        <tr>
          <td><input type="text" /></td>
        </tr>
        <tr style="text-align: end">
          <td>
            <button>Add</button>
          </td>
        </tr>
      </table>
    </DxPopup>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
//import data from "../data.json";
//import olddata from "../data.json";
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxExport,
  DxStateStoring,
  DxSorting,
  DxScrolling,
} from "devextreme-vue/data-grid";
import { DxSelectBox } from "devextreme-vue/select-box";

import "devextreme/data/odata/store";

import DxMenu from "devextreme-vue/menu";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";

import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

const dataGridRef = "dataGrid";

//查詢條件的下拉選項 (所有)
const QueryOptions = [
  {
    value: null,
  },
  {
    value: "=",
  },
  {
    value: ">=",
  },
  {
    value: "In",
  },
  {
    value: "<",
  },
  {
    value: ">",
  },
  {
    value: "<=",
  },
  {
    value: "Begins With", //20230724 CCL* Bug BEGLNS改成BEGINS
  },
  {
    value: "Contains",
  },
  {
    value: "Like",
  },
  {
    value: "<>",
  },
];
//查詢條件的下拉選項 (CUST_ID)
const CustIdQueryOptions = [
  {
    value: null,
  },
  {
    value: "=",
  },
  {
    value: "In",
  },
];
//查詢條件的下拉選項 (SDATE)
const SDateQueryOptions = [
  {
    value: null,
  },
  {
    value: "=",
  },
  {
    value: ">=",
  },
];
const CompletionOption = [
  {
    value: null,
  },
  {
    value: 25,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 55,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 75,
  },
  {
    value: 80,
  },
  {
    value: 85,
  },
  {
    value: 95,
  },
  {
    value: 100,
  },
];
const showSubmenuModes = {
  name: "onHover",
  delay: { show: 0, hide: 500 },
};

//查詢視窗 每筆資料(欄位)
let queryFormMainRows = [];
let criterions;

// let condsAtColumn3 = []; // DEMO, others

/* eslint-disable */
let condsAtColumn3R0 = []; // fxcking bad idea, but i cannt find a right way!!
let condsAtColumn3R1 = [];
let condsAtColumn3R2 = [];
let condsAtColumn3R3 = [];
let condsAtColumn3R4 = [];
let condsAtColumn3R5 = [];
let condsAtColumn3R6 = [];
let condsAtColumn3R7 = [];
let condsAtColumn3R8 = [];
let condsAtColumn3R9 = [];

export default {
  data() {
    return {
      isCustomItem:false,
      storageKey: "datagrid-state",
      tempGridStatus: null, //表格樣式暫存
      loginUserinfo: {},
      reportid: "",
      //for Login Page UserAgentInfo
      userAgentInfoHtml: "",
      showReportInfoScrollbar: "always", //onScroll, onHover, always, never
      pullDown: false,
      scrollByContent: true,
      scrollByThumb: true,
      //2.13.1 加入登入者的MAINSQL
      userMainSQLInfoHtml: "",
      //20230705 CCL+ 使其可以變成ComboBox可輸入/改變內部Text
      tmpValueList: [], //{}, PS:一定要是array [],不能是{},"",null; 不然畫面會異常
      //20230710 CCL+ 增加判斷導引至Pivot頁面
      pushQueryType: "Detail", //預設值為Detail
      //20230712 CCL+ 當subMenu Item 選擇時,加上Check Mark icon
      lastSelSubMenuItemObj: null,
      //20230814 CCL+ Report Last Updated End time
      reportLastUpdatedEndTime: "",
      //20230822 CCL+ 取得User按Query後Condition組合出的CombineSQL
      userCombineSQLInfoHtml: "",
      //20230823 CCL+ 一段時間後自動跳轉登出(1hr)
      timerId: null,
      //20230907 CCL+, 組出column_select
      show_COLUMN_SELECT: "",

      columns: [], //10個查詢條件顯示下拉選擇
      data: [],
      lastQueryDatetime: "",

      iconlabel: "",
      fields: [], //Gridview顯示欄位
      allfields: [], //Query顯示欄位
      groupfields: [], //Gridview 2層顯示欄位
      lstmyquery: [], //Post查詢條件
      queryFormMainRows: [], // query form 左側資料源：lsT_ALL_COLUMNS take 10
      criterions,

      condsAtColumn3R0: [{ text: "DEMO", value: "DEMO" }],
      condsAtColumn3R1: [{ text: "", value: "" }],
      condsAtColumn3R2: [{ text: "", value: "" }],
      condsAtColumn3R3: [{ text: "", value: "" }],
      condsAtColumn3R4: [{ text: "", value: "" }],
      condsAtColumn3R5: [{ text: "", value: "" }],
      condsAtColumn3R6: [{ text: "", value: "" }],
      condsAtColumn3R7: [{ text: "", value: "" }],
      condsAtColumn3R8: [{ text: "", value: "" }],
      condsAtColumn3R9: [{ text: "", value: "" }],

      pagesize: 10,
      //filtering: "",
      showHeaderFilter: false,
      showFilterRow: false,
      //Column Resizing :nextColumn/widget/disable
      currentMode: "widget",
      allowResizingMode: true,

      QueryOptions,
      //20230620 CCL+ for CUST_ID, SDATE 有不一樣的運算子條件欄位選項
      CustIdQueryOptions,
      SDateQueryOptions,
      CompletionOption,

      StatusLike: "",
      OwnerLike: "",
      PriorityLike: "",

      column1Index: 0,

      column1: false,
      column2: false,
      column3: false,

      showColumnLines: true,
      showRowLines: false,
      showSubmenuModes,
      orientation: "horizontal",
      hideSubmenuOnMouseLeave: false,
      currentProduct: null,
      dataGridRef,
      customerColumnStyle: "",
      isQueryFormPopupVisible: true,

      //2.2.2 新增讀取N_RPT_CRITERION.UDT欄位值, 並轉成最後QueryForm Popup視窗按下Report鈕的時間
      UserLastQueryTime: "",
      QueryUDTTime: "",

      //是否為reset 狀態
      isReset: false,
      //選單
      menus: [
        {
          name: "Query",
          icon: require("@/assets/img/Query.png"),
          //items: []
        },
        {
          name: "Layout",
          icon: require("@/assets/img/Layerout.png"),
          items: [
            {
              name: "Load",
            },
            {
              name: "Save",
              //items: []
            },
            {
              name: "Save As New Report",
              //items: []
            },
          ],
        },
        {
          name: "Export file",
          icon: require("@/assets/img/Export.png"),
          items: [
            {
              name: "CSV",
              icon: require("@/assets/img/CSV.jpg"),
            },
            {
              name: "Excel",
              icon: require("@/assets/img/CSV.jpg"),
            },
            {
              name: "PDF",
              icon: require("@/assets/img/PDF.jpg"),
              //items: []
            },
          ],
        },
        {
          name: "Filtering",
          icon: require("@/assets/img/Filter.png"),
          items: [
            {
              name: "No Filter",
            },
            {
              name: "Show Header Filter",
            },
            {
              name: "Show Filter Row",
            },
            {
              name: "Show Filter Row Menu",
            },
          ],
        },
        {
          name: "Pager Mode",
          icon: require("@/assets/img/Page.png"),
          items: [
            {
              name: "Show All Records",
            },
            {
              name: "Show Pager",
              items: [
                {
                  name: "10",
                },
                {
                  name: "20",
                },
                {
                  name: "30",
                },
                {
                  name: "40",
                },
                {
                  name: "50",
                },
              ],
            },
          ],
        },
        {
          name: "ScrollBar",
          icon: require("@/assets/img/ScrollBar.png"),
          items: [
            {
              name: "No ScrollBar",
            },
            {
              name: "Show ScrollBar",
              items: [
                {
                  name: "Frozen Column Set 1",
                },
                {
                  name: "Frozen Column Set 1~2",
                },
                {
                  name: "Frozen Column Set 1~3",
                },
              ],
            },
          ],
        },
        {
          name: "Column Resize",
          icon: require("@/assets/img/ColumnResize.png"),
          items: [
            {
              name: "Disabled",
            },
            {
              name: "Control",
            },
            {
              name: "Next Column",
            },
          ],
        },
        {
          name: "Grid Lines",
          icon: require("@/assets/img/Gridlines.png"),
          items: [
            {
              name: "None",
            },
            {
              name: "Both",
            },
            {
              name: "Horizontal",
            },
            {
              name: "Vertical",
            },
          ],
        },
        {
          name: "Grouping",
          icon: require("@/assets/img/Grouping.png"),
          items: [
            {
              name: "Pivot Grid",
            },
          ],
        },
        {
          name: "Columns",
          icon: require("@/assets/img/TableHS.png"),
          items: [
            {
              name: "hiding/showing",
            },
          ],
        },
        {
          name: "Styles",
          icon: require("@/assets/img/Style.png"),
          items: [
            {
              name: "Office2003Olive",
            },
            {
              name: "Office2003Blue",
            },
            {
              name: "Office2003Silver",
            },
            {
              name: "Office2010Black",
            },
            {
              name: "Office2010Blue",
            },
            {
              name: "Office2010Silver",
            },
            {
              name: "PlasticBlue",
            },
            {
              name: "RedWine",
            },
            {
              name: "SoftOrange",
            },
            {
              name: "Youthful",
            },
            {
              name: "Glass",
            },
          ],
        },
        {
          name: "Report Info",
          icon: require("@/assets/img/Help.png"),
          //items: []
        },
      ],
      isHiddingShowPopupVisible: false,
      isReportInfoPopupVisible: false,
      isSavePopupVisible: false,

      loadPanel: {
        //  表格 load 設定
        enabled: false,
      },

      //combox 使用
      isInOnCloseDropDown: false,
      isChangeDropDown: false,
      isFirstInDropdownFocus: true,
      currentComboxValue: "",

      CompletionOptionValue: "",
    };
  },
  created() {
    const lui = sessionStorage.getItem("LoginUserinfo");
    if (lui === undefined || lui === null) {
      //location.href=`http://localhost:8080/#/login`;
      location.replace(`/#/login`);
    }
  },
  beforeCreate() {},
  mounted() {
    this.loginUserinfo = JSON.parse(sessionStorage.getItem("LoginUserinfo"));

    //this.reportid = "Turnkey_WIP";
    let qReportId = this.$route.query.rpid;
    if (qReportId === undefined) {
      qReportId = "Turnkey_WIP";
    }
    this.reportid = qReportId;

    console.log(`mounted: this.pagesize ` + this.pagesize);

    let keeppara = this.$route.query.keep;
    //alert(keeppara);
    if (keeppara != 1) {
      sessionStorage.setItem("bodyParameters", null);
      sessionStorage.setItem("lstmyquery", null);
      sessionStorage.setItem("groupfields", null);
      sessionStorage.setItem("fields", null);
      sessionStorage.setItem("allfields", null);
    }
    //載入Report所有欄位
    this.LoadField();

    //Keep query load
    // if (sessionStorage.getItem("bodyParameters") != "null") {
    //   let bp = JSON.parse(sessionStorage.getItem("bodyParameters"));
    //   console.log('bp',bp)
    //   this.Load(bp);
    // }

    //從localStorage讀取UserAgentInfo///
    let userAgentInfo = JSON.parse(localStorage.getItem("UserAgentInfo"));
    this.displayUserAgenInfo(userAgentInfo.data.payload);

    //20230823 CCL+ 一段時間後沒動作自動跳轉登出(1hr)
    document.onmousedown = this.reCalculate;
    document.onmousemove = this.reCalculate;
    this.reCalculate();
  },
  computed: {
    dataGrid: function () {
      return this.$refs[dataGridRef].instance;
    },
    cssVars() {
      return {
        "--button-bg-color": this.columnBackgrondColor,
      };
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    onCustomItemCreating(e, currentList) {
       const existingItemIndex = this[currentList].findIndex((item) => item.value === e.text);
 
      if (existingItemIndex !== -1) {
        // 如果已經存在，將其移到第0位
        const existingItem = this[currentList].splice(existingItemIndex, 1)[0]; // 移除並返回該項目
        this[currentList].unshift(existingItem); // 將其插入到第0位
        this.isCustomItem = false; // 不標記為自訂項目
        e.customItem = null; // 阻止新增項目
        return;
      }
 
      // 如果不存在，則創建新的自訂項目並將其插入到第0位
      this.isCustomItem = true; // 標記為自訂項目
      const newItem = { text: e.text, value: e.text };
      this[currentList].unshift(newItem); // 將新項目插入到第0位
      e.customItem = newItem; // 返回新項目
    },
 
    onCustomItemChange(e, currentList) {
      if (this.isCustomItem) {
        // 自訂項目時跳過處理
        console.log("這是新增不處理chanage");
        this.isCustomItem = false; // 重置標記，避免影響下一次變更
        return;
      }
      // 處理正常的 value changed 邏輯
      const existingItemIndex = this[currentList].findIndex((item) => item.value === e.value);
 
      if (existingItemIndex !== -1) {
       
        console.log("有找到所以處理chanage",existingItemIndex);
        // 如果已經存在，將其移到第0位
        const existingItem = this[currentList].splice(existingItemIndex, 1)[0]; // 移除並返回該項目
        this[currentList].unshift(existingItem); // 將其插入到第0位
        this.isCustomItem = false; // 不標記為自訂項目
        e.customItem = null; // 阻止新增項目
        return;
      }
    },
    
    /**
     * 載入Gridview的資料 (按下load 按鈕、query按鈕、 mounted 會呼叫)
     * @param {bodyParameters} bp
     */
    async Load(bp) {
      console.log("=======>>>>>>>>>> Load");
      this.$store.dispatch("updateLoading", true);

      let sqlwhere = [];

      this.lstmyquery = [];

      if (this.queryFormMainRows[0]) {
        if (this.condsAtColumn3R0[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[0].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[0].condition,
            value: this.condsAtColumn3R0[0]?.value,
          });
        }

        //第一個條件
        let resultfields0 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[0].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[0].columN_NAME,
          condition: this.queryFormMainRows[0].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R0[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields0[0].textfield),
        });

        //第二個條件
        if (this.condsAtColumn3R1[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[1].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[1].condition,
            value: this.condsAtColumn3R1[0].value,
          });
        }
        let resultfields1 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[1].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[1].columN_NAME,
          condition: this.queryFormMainRows[1].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R1[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields1[0].textfield),
        });

        //第三個條件
        if (this.condsAtColumn3R2[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[2].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[2].condition,
            value: this.condsAtColumn3R2[0].value,
          });
        }
        let resultfields2 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[2].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[2].columN_NAME,
          condition: this.queryFormMainRows[2].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R2[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields2[0].textfield),
        });

        //第四個條件
        if (this.condsAtColumn3R3[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[3].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[3].condition,
            value: this.condsAtColumn3R3[0].value,
          });
        }
        let resultfields3 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[3].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[3].columN_NAME,
          condition: this.queryFormMainRows[3].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R3[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields3[0].textfield),
        });

        //第五個條件
        if (this.condsAtColumn3R4[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[4].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[4].condition,
            value: this.condsAtColumn3R4[0].value,
          });
        }
        let resultfields4 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[4].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[4].columN_NAME,
          condition: this.queryFormMainRows[4].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R4[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields4[0].textfield),
        });

        //第六個條件
        if (this.condsAtColumn3R5[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[5].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[5].condition,
            value: this.condsAtColumn3R5[0].value,
          });
        }
        let resultfields5 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[5].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[5].columN_NAME,
          condition: this.queryFormMainRows[5].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R5[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields5[0].textfield),
        });

        //第七個條件
        if (this.condsAtColumn3R6[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[6].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[6].condition,
            value: this.condsAtColumn3R6[0].value,
          });
        }
        let resultfields6 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[6].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[6].columN_NAME,
          condition: this.queryFormMainRows[6].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R6[0].value,
          readonly: true,
          valuelist: JSON.parse(resultfields6[0].textfield),
        });

        //第八個條件
        if (this.condsAtColumn3R7[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[7].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[7].condition,
            value: this.condsAtColumn3R7[0].value,
          });
        }
        let resultfields7 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[7].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[7].columN_NAME,
          condition: this.queryFormMainRows[7].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R7[0].value,
          readonly: true,
          valuelist: resultfields7[0]
            ? JSON.parse(resultfields7[0].textfield)
            : "",
        });

        //第九個條件
        if (this.condsAtColumn3R8[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[8].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[8].condition,
            value: this.condsAtColumn3R8[0].value,
          });
        }
        let resultfields8 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[8].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[8].columN_NAME,
          condition: this.queryFormMainRows[8].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R8[0].value,
          readonly: true,
          valuelist: resultfields8[0]
            ? JSON.parse(resultfields8[0].textfield)
            : "",
        });

        //第十個條件
        if (this.condsAtColumn3R9[0].value) {
          sqlwhere.push({
            name: this.queryFormMainRows[9].columN_NAME,
            dbtype: "string",
            condition: this.queryFormMainRows[9].condition,
            value: this.condsAtColumn3R9[0].value,
          });
        }
        let resultfields9 = this.allfields.filter((event) =>
          event.columN_NAME.includes(this.queryFormMainRows[9].columN_NAME)
        );

        this.lstmyquery.push({
          type: "text",
          field: this.queryFormMainRows[9].columN_NAME,
          condition: this.queryFormMainRows[9].condition,
          control: "MultipleComboBox",
          value: this.condsAtColumn3R9[0].value,
          readonly: true,
          valuelist: resultfields9[0]
            ? JSON.parse(resultfields9[0].textfield)
            : "",
        });
      }

      console.log(`sqlwhere=>\n${JSON.stringify(sqlwhere)}`);

      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      let bodyParameters = {
        account: this.loginUserinfo.account,
        reportid: this.reportid,
        formrow: 0,
        torow: 0,
        //sql: 'select * from VW_CS_WIP_TURNKEY nolock',
        sqlwhere: sqlwhere,
      };

      if (bp != null) {
        bodyParameters = bp;
      }

      console.log(`bodyParameters ==>`, bodyParameters);
      let respcount = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/CountStandard",
        bodyParameters,
        config
      );
      let queryDatetime = new Date().toLocaleString();
      this.lastQueryDatetime = queryDatetime;

      if (!respcount.success) {
        this.data = [];
        let countResult = respcount.data.payload;
        if (countResult > 3000) {
          countResult = 3000; // 2024-2-16 updated, total records now, is over 100_000, would crash the app!
        }

        //TODO: useless part, remove it!
        if (this.pagesize >= 99999) {
          let resp = await this.axios.post(
            this.$store.state.axios_url + "/api/Standard/GetStandard/infinity",
            {
              account: this.loginUserinfo.account,
              reportid: this.reportid,
              step: 0,
              sqlwhere: sqlwhere,
            },
            config
          );
          if (!resp.success) {
            let tmp = resp.data.payload;
            this.data.push(tmp);
            //TODO: how about this.displayUserCombineSQLHtml(resp.data.message);?
          }
        } else {
          let limit = 1000;
          let page = parseInt(countResult / limit);
          let less = countResult % limit;
          if (less > 0) {
            page += 1;
          }
          for (let i = 0; i < page; i++) {
            if (i === 0) {
              //  讓首次的內容可先顯示 loading 圖示
              this.loadPanel = { enabled: true };
              this.pagesize =
                sessionStorage.getItem("psize") == null
                  ? 10
                  : parseInt(sessionStorage.getItem("psize"));
            } else {
              this.loadPanel = { enabled: false };
            }

            let f = parseInt(i * limit);
            let t = parseInt(f + limit);
            bodyParameters.formrow = f;
            bodyParameters.torow = t;
            let resp = await this.axios.post(
              this.$store.state.axios_url + "/api/Standard/GetStandard",
              bodyParameters,
              config
            );
            if (!resp.success) {
              if (this.lastQueryDatetime == queryDatetime) {
                let tmp = resp.data.payload;
                for (let i = 0; i < tmp.length; i++) {
                  this.data.push(tmp[i]);
                }
              }
            }
            //20230822 CCL+ 改成取得GetStandard返回Message,成功撈到Data,Message會傳回成功CombineSql
            if (resp.message != "") {
              //let respjson = JSON.stringify(resp);
              //this.showDebug = resp.data.message;//respjson.data.message;
              this.displayUserCombineSQLHtml(resp.data.message);
            }
          }
        }
        //20230906 CCL* 當CountStandard 回傳0筆時, 不會執行上面那段for迴圈,不會呼叫GetStandard
        //導致ReportInfo視窗內的SQL為空,後端CountStandard也改為message回傳sql語句
        if (respcount.data.message != "" && parseInt(countResult) == 0) {
          //if(respcount.data.message != "" )
          this.displayUserCombineSQLHtml(respcount.data.message);
        }

        sessionStorage.setItem(
          "bodyParameters",
          JSON.stringify(bodyParameters)
        );
      }
      this.$store.dispatch("updateLoading", false);
    },
    /**
     * 載入Gridview動態欄位 (只有mounted呼叫)
     */
    async LoadField() {
      console.log("Method start async LoadField");
      const config = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
          AccessToken: "Bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.loginUserinfo.account,
        reportid: this.reportid,
        companyid: this.loginUserinfo.companyid,
      };

      let respfield = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/GetStandardField",
        // this.$store.state.axios_local_url + "/api/Standard/GetStandardField",
        bodyParameters,
        config
      );
      this.$store.dispatch("updateLoading", false);

      console.log(`===/api/Standard/GetStandardField===`, respfield);

      if (!respfield.success) {
        this.lsT_ALL_COLUMNS = respfield.data.payload.lsT_ALL_COLUMNS;
        this.criterions = respfield.data.payload.obJ_N_RPT_CRITERION;

        //取得所有欄位名稱
        this.fields = respfield.data.payload.lsT_N_RPT_COLUMNS;
        this.allfields = this.lsT_ALL_COLUMNS; // 20231110: need to use new query form api to grab correct data
        this.iconlabel = respfield.data.payload.iconlabel;

        this.lsT_ALL_COLUMNS.slice(0, 10).map((row, idx) => {
          this.queryFormMainRows.push({ ...row, condition: "" });
        });
        console.log(`===this.queryFormMainRows===`, queryFormMainRows);

        //20230814 CCL+ Report Last Updated End time
        this.reportLastUpdatedEndTime =
          respfield.data.payload.reporT_LASTUPDATED_ENDTIME;

        //取得User登入後的MAINSQL
        this.displayUserMainSQLHtml(respfield.data.payload.mainsql);

        //取得所有顯示在Gridview欄位上

        //Keep Hidden field form
        // if (sessionStorage.getItem("groupfields") != "null") {
        //   this.groupfields = JSON.parse(sessionStorage.getItem("groupfields"));
        // } else {
        this.groupfields = respfield.data.payload.lsT_GROUP_COLUMNS;
        //20230807 CCL+ 修正this.groupfields 為空,GetGridStatus()回傳的groupfields值是空的
        sessionStorage.setItem("groupfields", JSON.stringify(this.groupfields));
        // }

        //設定Query畫面的10個下拉欄位(dx-select-box的內容)
        this.columns = []; //[{ text: "", value: "" }];

        //設定Query畫面預設的條件式(共10個欄位)
        let mycriterion = respfield.data.payload.obJ_N_RPT_CRITERION;
        this.lstmyquery = [];

        //2.2.2 取得N_RPT_CRITERION.UDT欄位值////
        this.UserLastQueryTime =
          "Query Condition            User's last time:" +
          this.getUserLastOueryTime(mycriterion);

        //Keep Query form
        console.log("session lstmyquery", sessionStorage.getItem("lstmyquery"));

        if (
          sessionStorage.getItem("lstmyquery") != null &&
          sessionStorage.getItem("lstmyquery") != "null"
        ) {
          this.lstmyquery = JSON.parse(sessionStorage.getItem("lstmyquery"));

          if (!this.lstmyquery[7]?.field){
            this.queryFormMainRows[7] = [];
          }
          if (!this.lstmyquery[8]?.field){
            this.queryFormMainRows[8] = [];
          }

          if (!this.lstmyquery[9]?.field){
            this.queryFormMainRows[9] = [];
          }

        } else {
          //第一個條件
          //q = this.addquery(mycriterion.columN_CONDITION);
          // if (q != null) {
          //   this.lstmyquery.push(q);
          // }
          let q = this.addquery(mycriterion.columN_CONDITION1);
          // if (q != null) {
          // this.condsAtColumn3R1.splice(
          //   0,
          //   this.condsAtColumn3R1.length,
          //   { text: q.value, value: q.value }
          // );
          // this.condsAtColumn3R1[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第二個條件
          q = this.addquery(mycriterion.columN_CONDITION2);
          // if (q != null) {
          // this.condsAtColumn3R2.splice(
          //   0,
          //   this.condsAtColumn3R2.length,
          //   { text: q.value, value: q.value }
          // );
          // this.condsAtColumn3R2[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第三個條件
          q = this.addquery(mycriterion.columN_CONDITION3);
          // if (q != null) {
          // this.condsAtColumn3R3.splice(
          //   0,
          //   this.condsAtColumn3R3.length,
          //   { text: q.value, value: q.value }
          // );
          // this.condsAtColumn3R3[0].value = q.value;
          this.lstmyquery.push(q);

          // }
          //第四個條件
          q = this.addquery(mycriterion.columN_CONDITION4);
          // if (q != null) {
          // this.condsAtColumn3R4.splice(
          //   0,
          //   this.condsAtColumn3R4.length,
          //   { text: q.value, value: q.value }
          // );
          // this.condsAtColumn3R4[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第五個條件
          q = this.addquery(mycriterion.columN_CONDITION5);
          // if (q != null) {
          // this.condsAtColumn3R5.splice(
          //   0,
          //   this.condsAtColumn3R5.length,
          //   { text: q.value, value: q.value }
          // );
          // this.condsAtColumn3R5[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第六個條件
          q = this.addquery(mycriterion.columN_CONDITION6);
          // if (q != null) {
          q.readonly = false;
          // this.condsAtColumn3R6[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第七個條件
          q = this.addquery(mycriterion.columN_CONDITION7);
          // if (q != null) {
          //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
          q.readonly = false;

          // this.condsAtColumn3R7[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第八個條件
          q = this.addquery(mycriterion.columN_CONDITION8);
          // if (q != null) {
          //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
          q.readonly = false;
          if (this.queryFormMainRows[7].columN_NAME != q.field && q.field) {
            let resultfields = this.allfields.filter(
              (event) => event.columN_NAME == q.field
            );
            this.queryFormMainRows[7] = resultfields[0];
          } else {
            this.queryFormMainRows[7] = [];
          }

          // this.condsAtColumn3R8[0].value = q.value;
          this.lstmyquery.push(q);
          // }
          //第九個條件
          q = this.addquery(mycriterion.columN_CONDITION9);
          // if (q != null) {
          //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
          q.readonly = false;
          if (this.queryFormMainRows[8].columN_NAME != q.field && q.field) {
            let resultfields = this.allfields.filter(
              (event) => event.columN_NAME == q.field
            );
            this.queryFormMainRows[8] = resultfields[0];
          } else {
            this.queryFormMainRows[8] = [];
          }

          // this.condsAtColumn3R9[0].value = q.value;
          this.lstmyquery.push(q);
          // }

          //第十個條件
          q = this.addquery(mycriterion.columN_CONDITION10);
          // if (q != null) {
          //   //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
          q.readonly = false;
          if (this.queryFormMainRows[9].columN_NAME != q.field && q.field) {
            let resultfields = this.allfields.filter(
              (event) => event.columN_NAME == q.field
            );
            this.queryFormMainRows[9] = resultfields[0];
          } else {
            this.queryFormMainRows[9] = [];
          }
          //   this.condsAtColumn3R9[0].value = q.value;
          this.lstmyquery.push(q);
          // }
        }

        this.setQueryFormConditionData();

        //20230908 CCL+ bug, LoadField時GetStandardField API回傳的obJ_N_RPT_CRITERION內的columN_SELECT回傳值
        //要設給this.show_COLUMN_SELECT, 到時執行saveGridStatus()才不會存""進後端,導致把DEFAULT_COLUMN寫回
        //變成顯示所有欄位
        //this.show_COLUMN_SELECT = mycriterion.columN_SELECT;

        //20230811 CCL* 由於Ajax postback時間差的關係,必須調整呼叫Getgridviewstatus()的位置至此
        this.getGridViewStatus();
        this.dataGrid.repaint(); //20230828 CCL* 打錯字dataGridRef -> dataGrid
      }
    },

    /**
     * 設定 query form 條件資訊
     */
    setQueryFormConditionData() {
      console.log("setQueryFormConditionData", this.lstmyquery);
      if (this.lstmyquery) {
        for (let index = 0; index < this.lstmyquery.length; index++) {
          if (
            this.lstmyquery[index].value !== "" &&
            this.lstmyquery[index].condition !== null
          ) {
            //第一個條件
            if (
              this.queryFormMainRows[0].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R0.splice(0, this.condsAtColumn3R0.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R0[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[0].condition =
                this.lstmyquery[index].condition;
            }
            //第二個條件
            if (
              this.queryFormMainRows[1].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R1.splice(0, this.condsAtColumn3R1.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R1[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[1].condition =
                this.lstmyquery[index].condition;
            }
            //第三個條件
            if (
              this.queryFormMainRows[2].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R2.splice(0, this.condsAtColumn3R2.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R2[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[2].condition =
                this.lstmyquery[index].condition;
            }
            //第四個條件
            if (
              this.queryFormMainRows[3].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R3.splice(0, this.condsAtColumn3R3.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R3[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[3].condition =
                this.lstmyquery[index].condition;
            }
            //第五個條件
            if (
              this.queryFormMainRows[4].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R4.splice(0, this.condsAtColumn3R4.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.queryFormMainRows[4].condition =
                this.lstmyquery[index].condition;
              this.condsAtColumn3R4[0].value = this.lstmyquery[index].value;
            }
            //第六個條件
            if (
              this.queryFormMainRows[5].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R5.splice(0, this.condsAtColumn3R5.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.queryFormMainRows[5].condition =
                this.lstmyquery[index].condition;
              this.condsAtColumn3R5[0].value = this.lstmyquery[index].value;
            }
            //第七個條件
            if (
              this.queryFormMainRows[6].columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R6.splice(0, this.condsAtColumn3R6.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.queryFormMainRows[6].condition =
                this.lstmyquery[index].condition;
              this.condsAtColumn3R6[0].value = this.lstmyquery[index].value;
            }
            //第八個條件
            if (
              this.queryFormMainRows[7]?.columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R7.splice(0, this.condsAtColumn3R7.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R7[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[7].condition =
                this.lstmyquery[index].condition;
            }
            //第九個條件
            if (
              this.queryFormMainRows[8]?.columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R8.splice(0, this.condsAtColumn3R8.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R8[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[8].condition =
                this.lstmyquery[index].condition;
            }
            //第十個條件
            if (
              this.queryFormMainRows[9]?.columN_NAME ==
              this.lstmyquery[index].field
            ) {
              this.condsAtColumn3R9.splice(0, this.condsAtColumn3R9.length, {
                text: this.lstmyquery[index].value,
                value: this.lstmyquery[index].value,
              });
              this.condsAtColumn3R9[0].value = this.lstmyquery[index].value;
              this.queryFormMainRows[9].condition =
                this.lstmyquery[index].condition;
            }
          }
        }
      }
    },

    //20230804 CCL+ 修正因為讀回getGridViewStatus()把整理過的lstmyquery又蓋回來DB沒整理過的值
    //每次讀回GridViewStatus()都要再呼叫此函式把latmyquery整理一次
    toProcesslstmyquery(readLstMyQuery) {
      console.log("toProcesslstmyquery");

      //設定Query畫面預設的條件式(共10個欄位)
      //let mycriterion = respfield.data.payload.obJ_N_RPT_CRITERION;
      this.lstmyquery = [];
      var processLstMyQuery = [];
      var conditionAry = [];
      let condition = "";
      //由JSON先產回原來COLUMN_CONDITIONx 欄位的值
      for (var i = 0; i < readLstMyQuery.length; i++) {
        condition = null; //clear
        if (i == 0) {
          condition = readLstMyQuery[i].value; //length == 1
        } else if (i == 1) {
          //if(Readlstmyquery[i].field == "" && Readlstmyquery[i].value == "")
          //{
          //   condition = "CUST_ID;=;MultipleComboBox;DEMO";
          //} else {
          condition =
            readLstMyQuery[i].field +
            ";" +
            readLstMyQuery[i].condition +
            ";" +
            readLstMyQuery[i].control +
            ";" +
            readLstMyQuery[i].value;
          //+ ";" + Readlstmyquery[i].valuelist; //length == 4
          //}
        } else {
          if (readLstMyQuery[i].field == "" && readLstMyQuery[i].value == "") {
            condition = null;
          } else {
            condition =
              readLstMyQuery[i].field +
              ";" +
              readLstMyQuery[i].condition +
              ";" +
              readLstMyQuery[i].control +
              ";" +
              readLstMyQuery[i].value;
            //+ ";" + Readlstmyquery[i].valuelist; //length == 4
          }
        }

        conditionAry.push(condition);
      }

      //Keep Query form
      if (sessionStorage.getItem("lstmyquery") != "null") {
        this.lstmyquery = JSON.parse(sessionStorage.getItem("lstmyquery"));
      } else {
        // console.log(`=== conditionAry[0] ===`, conditionAry[0]);

        let q = this.addquery(conditionAry[0]);
        //if (q != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // }
        q = this.addquery(conditionAry[1]);
        // if (q != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // }
        q = this.addquery(conditionAry[2]);
        // if (q != null && conditionAry[2] != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // } //20230705 CCL+修正Bug如果一開始Db內的columN_CONDITIONx 欄位的值為空
        // //或是上一次value為空把columN_CONDITIONx 欄位的值清為空 時, 必須給固定的2~6 field預設值
        // //不然readonly,control,..沒設等等會使顯示畫面會異常
        // else {
        //   processLstMyQuery.push(
        //     this.getlstmyqueryDefaultSetup("Device(Lot Info)")
        //   );
        //   //this.lstmyquery.push( this.getlstmyqueryDefaultSetup("Device(Lot Info)"));
        // }
        q = this.addquery(conditionAry[3]);
        // if (q != null && conditionAry[3] != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // } //20230705 CCL+修正Bug如果一開始Db內的columN_CONDITIONx 欄位的值為空
        // //或是上一次value為空把columN_CONDITIONx 欄位的值清為空 時, 必須給固定的2~6 field預設值
        // //不然readonly,control,..沒設等等會使顯示畫面會異常
        // else {
        //   processLstMyQuery.push(
        //     this.getlstmyqueryDefaultSetup("L/C(Basic Info)")
        //   );
        //   //this.lstmyquery.push( this.getlstmyqueryDefaultSetup("L/C(Basic Info)"));
        // }
        q = this.addquery(conditionAry[4]);
        // if (q != null && conditionAry[4] != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // } //20230705 CCL+修正Bug如果一開始Db內的columN_CONDITIONx 欄位的值為空
        // //或是上一次value為空把columN_CONDITIONx 欄位的值清為空 時, 必須給固定的2~6 field預設值
        // //不然readonly,control,..沒設等等會使顯示畫面會異常
        // else {
        //   processLstMyQuery.push(
        //     this.getlstmyqueryDefaultSetup("Package(Basic Info)")
        //   );
        //   //this.lstmyquery.push( this.getlstmyqueryDefaultSetup("Package(Basic Info)"));
        // }
        q = this.addquery(conditionAry[5]);
        // if (q != null && conditionAry[5] != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // } //20230705 CCL+修正Bug如果一開始Db內的columN_CONDITIONx 欄位的值為空
        // //或是上一次value為空把columN_CONDITIONx 欄位的值清為空 時, 必須給固定的2~6 field預設值
        // //不然readonly,control,..沒設等等會使顯示畫面會異常
        // else {
        //   processLstMyQuery.push(
        //     this.getlstmyqueryDefaultSetup("PO No(Basic Info)")
        //   );
        //   //this.lstmyquery.push( this.getlstmyqueryDefaultSetup("PO No(Basic Info)"));
        // }
        q = this.addquery(conditionAry[6]);
        // if (q != null && conditionAry[6] != null) {
        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // } //20230705 CCL+修正Bug如果一開始Db內的columN_CONDITIONx 欄位的值為空
        // //或是上一次value為空把columN_CONDITIONx 欄位的值清為空 時, 必須給固定的2~6 field預設值
        // //不然readonly,control,..沒設等等會使顯示畫面會異常
        // else {
        //   processLstMyQuery.push(
        //     this.getlstmyqueryDefaultSetup("Wafter Lot No(Lot Info)")
        //   );
        //   //this.lstmyquery.push( this.getlstmyqueryDefaultSetup("Wafter Lot No(Lot Info)"));
        // }
        q = this.addquery(conditionAry[7]);
        // if (q != null) {
        //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
        q.readonly = false;

        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // }
        q = this.addquery(conditionAry[8]);
        // if (q != null) {
        //   //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
        q.readonly = false;

        processLstMyQuery.push(q);
        //   //this.lstmyquery.push(q);
        // }
        q = this.addquery(conditionAry[9]);
        // if (q != null) {
        //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
        q.readonly = false;

        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // }
        q = this.addquery(conditionAry[10]);
        // if (q != null) {
        //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
        q.readonly = false;

        processLstMyQuery.push(q);
        //this.lstmyquery.push(q);
        // }

        this.lstmyquery = processLstMyQuery;
        //this.showDebug = this.lstmyquery;
      }

      //this.showDebug = this.lstmyquery;
    },
    canAcceptCustomValue(value) {
      return value.length === 0;
    },
    genSelectionTextArray(row) {
      const arr = [];
      //arr.push(`${row.columN_CAPTION} (${row.grouP_NAME})`);
      const arrItem = {
        text: `${row.columN_CAPTION} (${row.grouP_NAME})`,
        value: row.columN_NAME,
      };
      arr.push(arrItem);
      return arr;
    },
    genTDElsesArray() {
      const qElses = this.allfields.filter((r) => {
        return "TD".indexOf(r.forcE_PROTECTION_CONDITION) < 0;
      });
      const arr = [];
      for (let q = 0; q < qElses.length; q++) {
        const arrItem = {
          text: `${qElses[q].columN_CAPTION} (${qElses[q].grouP_NAME})`,
          value: qElses[q].columN_NAME,
        };
        arr.push(arrItem);
      }
      return arr;
    },
    selectedConditionValue(idx, vIndex) {
      const arr = [];
      let criterion = this.conditionSelector(idx);
      if (!!criterion && criterion.length > 0) {
        //CUST_ID;In;MultipleComboBox;DEMO
        const sel = criterion.split(";")[vIndex];
        const box = { text: sel, value: sel };
        arr.push(box);
      }
      arr.push({ text: "", value: "" });
      return arr;
    },
    conditionSelector(idx) {
      let criterion = ";;;";
      switch (idx + 1) {
        case 1:
          criterion = this.criterions.columN_CONDITION1;
          break;
        case 2:
          criterion = this.criterions.columN_CONDITION2;
          break;
        case 3:
          criterion = this.criterions.columN_CONDITION3;
          break;
        case 4:
          criterion = this.criterions.columN_CONDITION4;
          break;
        case 5:
          criterion = this.criterions.columN_CONDITION5;
          break;
        case 6:
          criterion = this.criterions.columN_CONDITION6;
          break;
        case 7:
          criterion = this.criterions.columN_CONDITION7;
          break;
        case 8:
          criterion = this.criterions.columN_CONDITION8;
          break;
        case 9:
          criterion = this.criterions.columN_CONDITION9;
          break;
        case 10:
          criterion = this.criterions.columN_CONDITION10;
          break;
        default:
          break;
      }
      return criterion;
    },
    //20230705 CCL+ 使其可以變成ComboBox可輸入/改變內部Text
    getSelBoxValueListToTempObj(valuelist) {
      this.tmpValueList = valuelist;
    },
    /**
     * Func 加入查詢條件
     * @param strquery
     */
    addquery: function (strquery) {
      if (strquery == null) {
        let query1 = {};
        query1.type = "text";
        query1.field = "";
        query1.condition = "";
        query1.control = "";
        query1.value = "";
        query1.readonly = false;
        query1.valuelist = [];
        return query1;
      }

      //this.fields.filter(task=> task.field=task.field)

      // try {
      let myArray = strquery.split(";");
      let query1 = {};
      if (myArray.length == 4) {
        let result = this.columns.filter((event) =>
          event.value.includes(myArray[0])
        );

        let resultfields = this.allfields.filter((event) =>
          event.columN_NAME.includes(myArray[0])
        );

        query1.type = result[0]?.type;
        query1.field = myArray[0];

        query1.condition = myArray[1];
        query1.control = myArray[2];
        query1.value = myArray[3];
        query1.readonly = true;
        query1.valuelist = JSON.parse(resultfields[0].textfield);
        return query1;
      } else if (myArray.length == 1) {
        query1.type = "text";
        query1.field = "";
        query1.condition = "";
        query1.control = "";
        query1.value = myArray[0];
        query1.readonly = true;
        query1.valuelist = [];
        return query1;
      } else {
        query1.type = "";
        query1.field = "";
        query1.condition = "";
        query1.control = "";
        query1.value = "";
        query1.readonly = true;
        query1.valuelist = [];
        return query1;
      }
      // } catch (error) {
      //   return null;
      // }
    },
    /**
     * 載入資料
     */
    GetGridData() {
      console.log("=======>>>>>>>>>> GetGridData");
      this.Load();

      if (
        this.CompletionOptionValue != "" &&
        this.CompletionOptionValue != null
      ) {
        this.data = this.data.filter(
          (searchResult) =>
            searchResult.Task_Completion == this.CompletionOptionValue
        );
      }

      if (this.StatusLike != "") {
        this.data = this.data.filter((searchResult) =>
          searchResult.Task_Status.match(this.StatusLike)
        );
      }
      if (this.OwnerLike != "") {
        this.data = this.data.filter(
          (searchResult) => searchResult.Task_Owner_ID == this.OwnerLike
        );
      }
      if (this.PriorityLike != "") {
        this.data = this.data.filter(
          (searchResult) => searchResult.Task_Priority == this.PriorityLike
        );
      }

      //20230710 CCL+ 增加判斷導引至Pivot頁面
      if (this.pushQueryType == "Detail") {
        //20230703 CCL+ 加入只要按下QueryForm的Query按鈕,就儲存QueryForm狀態
        this.saveGridViewStatus(false); //this.savegridviewstatus(); //

        //20230808 CCL+ 根據讀回的COLUMN_SELECT 回復狀態
        //搬移到按下QueryForm的Query()內,此時LoadField()才會跑完,this.groupfields才有值
        //20230811 CCL-由於AJAX postback回來有個時間差,此呼叫不能放此 this.Getgridviewstatus(); //一開始GridView的欄位顯示要依照COLUMN_SELECT 的值回復狀態

        //20230814 CCL* 要把目前dxGridView狀態也儲存才能跟Hide/Show視窗狀態一致
        this.saveGridViewStatus(true);
      } else if (this.pushQueryType == "Summary") {
        //與按下"Pivot Grid" menu不同的是,它是選Detail按下QueryForm 的Query後Load() Report Page完,才會儲存"bodyParameters"
        // 但是Login OK --> 跳出QueryForm window 因為沒選Detail而是選Summary按Query,此時Report頁面還沒Load()載入,
        //所以sessionStorage.getItem("bodyParameters") 為null
        //所以必須在此生成"bodyParameters" 物件存進sessionStorage

        //必須把更改後的最新this.lstmyquery狀態,存進bodybodyParameters,然後存進sessionStorage
        //之後cp-grouping頁面會從session取出

        //20231120, to save form status

        this.saveGridViewStatus(false);

        let sqlwhere = [];

        for (let index = 0; index < this.lstmyquery.length; index++) {
          if (
            this.lstmyquery[index].value !== "" &&
            this.lstmyquery[index].condition !== null
          ) {
            let where = {};
            where.name = this.lstmyquery[index].field;
            where.condition = this.lstmyquery[index].condition;
            where.value = this.lstmyquery[index].value;
            if (this.lstmyquery[index].type === "number") {
              where.dbtype = "int";
            } else {
              where.dbtype = "string";
            }

            sqlwhere.push(where);
          }
        }

        let bodyParameters = {
          account: this.loginUserinfo.account,
          reportid: this.reportid,
          formrow: 0,
          torow: 0,
          //sql: 'select * from VW_CS_WIP_TURNKEY nolock',
          sqlwhere: sqlwhere,
        };

        sessionStorage.setItem(
          "bodyParameters",
          JSON.stringify(bodyParameters)
        );

        //必須組出給cp-grouping頁面Load時,第一步CountStandard要用到的bodyParameters
        //並存在sessionStorage

        //20230808 CCL+ 根據讀回的COLUMN_SELECT 回復狀態
        //必須儲存this.groupfields 狀態到sessionStorage,讓Pivot畫面按下returnQuery回來此頁面能回復狀態
        sessionStorage.setItem("groupfields", JSON.stringify(this.groupfields));
        this.$router.push("/demo2");
      }
    },

    /**
     * 點擊 Query Form 裡面的 reset 按鈕事件
     */
    async resetQuery() {
      // this.isReset=true;
      this.lsT_ALL_COLUMNS = [];
      this.queryFormMainRows = [];
      this.lstmyquery = [];
      this.condsAtColumn3R0[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R1[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R2[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R3[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R4[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R5[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R6[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R7[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R8[0] = [{ text: "", value: "" }];
      this.condsAtColumn3R9[0] = [{ text: "", value: "" }];
      //載入Report所有欄位
      this.LoadField();

      return;

      this.isReset = true;
      console.log("resetQuery");
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      const bodyParameters = {
        account: this.loginUserinfo.account,
        reportid: this.reportid,
        companyid: this.loginUserinfo.companyid,
      };

      let respfield = await this.axios.post(
        this.$store.state.axios_url + "/api/Standard/GetStandardField",
        // this.$store.state.axios_local_url + "/api/Standard/GetStandardField",
        bodyParameters,
        config
      );
      this.lsT_ALL_COLUMNS = [];
      this.queryFormMainRows = [];
      this.lsT_ALL_COLUMNS = respfield.data.payload.lsT_ALL_COLUMNS;
      console.log(respfield.data.payload.lsT_ALL_COLUMNS);
      this.lsT_ALL_COLUMNS.slice(0, 10).map((row, idx) => {
        this.queryFormMainRows.push({ ...row, condition: "" });
      });
      console.log(this.queryFormMainRows);

      //設定Query畫面預設的條件式(共10個欄位)
      let mycriterion = respfield.data.payload.obJ_N_RPT_CRITERION;
      this.lstmyquery = [];

      //Keep Query form
      if (sessionStorage.getItem("lstmyquery") != "null") {
        this.lstmyquery = JSON.parse(sessionStorage.getItem("lstmyquery"));
      } else {
        //第一個條件
        let q = this.addquery(mycriterion.columN_CONDITION1);
        // if (q != null) {
        this.lstmyquery.push(q);
        // }
        //第二個條件
        this.queryFormMainRows[1].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION2);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R1.splice(0, this.condsAtColumn3R1.length, {
            text: q.value,
            value: q.value,
          });
        } else {
          this.condsAtColumn3R1 = [{ text: "", value: "" }];
        }
        //第三個條件
        this.queryFormMainRows[2].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION3);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R2.splice(0, this.condsAtColumn3R2.length, {
            text: q.value,
            value: q.value,
          });
        } else {
          this.condsAtColumn3R2 = [{ text: "", value: "" }];
        }
        //第四個條件
        this.queryFormMainRows[3].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION4);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R3.splice(0, this.condsAtColumn3R3.length, {
            text: q.value,
            value: q.value,
          });
        } else {
          this.condsAtColumn3R3 = [{ text: "", value: "" }];
        }
        //第五個條件
        this.queryFormMainRows[4].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION5);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R4.splice(0, this.condsAtColumn3R4.length, {
            text: q.value,
            value: q.value,
          });
        } else {
          this.condsAtColumn3R4 = [{ text: "", value: "" }];
        }
        //第六個條件
        this.queryFormMainRows[5].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION6);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R5.splice(0, this.condsAtColumn3R5.length, {
            text: q.value,
            value: q.value,
          });
        }
        //第七個條件
        this.queryFormMainRows[6].condition = "";
        q = this.addquery(mycriterion.columN_CONDITION7);
        this.lstmyquery.push(q);
        if (q != null) {
          this.condsAtColumn3R6.splice(0, this.condsAtColumn3R6.length, {
            text: q.value,
            value: q.value,
          });
        }

        //第八個條件
        this.queryFormMainRows[7].condition = "";
        // this.condsAtColumn3R7 = [{ text: "", value: "" }];
        q = this.addquery(mycriterion.columN_CONDITION8);
        q.readonly = false;
        console.log(q);
        if (q.field) {
          // if (this.queryFormMainRows[7].columN_NAME != q.field && q.field) {
          let resultfields = this.allfields.filter(
            (event) => event.columN_NAME == q.field
          );
          this.queryFormMainRows[7] = resultfields[0];
          console.log(this.queryFormMainRows);
          // }
        }
        // this.onConditionColumnSet1({e:{selectedItem:{value:q.field}}},7)
        this.lstmyquery.push(q);

        //第九個條件
        this.queryFormMainRows[8].condition = "";
        // this.condsAtColumn3R8 = [{ text: "", value: "" }];
        q = this.addquery(mycriterion.columN_CONDITION9);
        q.readonly = false;
        if (q.field) {
          if (this.queryFormMainRows[8].columN_NAME != q.field && q.field) {
            let resultfields = this.allfields.filter(
              (event) => event.columN_NAME == q.field
            );
            this.queryFormMainRows[8] = resultfields[0];
          }
        }
        this.lstmyquery.push(q);
        // if (q != null) {
        //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false
        // this.condsAtColumn3R8[0].value = q.value;
        // }
        //第十個條件
        this.queryFormMainRows[9].condition = "";
        // this.condsAtColumn3R9[0] = [{ text: "", value: "" }];
        q = this.addquery(mycriterion.columN_CONDITION10);
        q.readonly = false;
        if (q.field) {
          if (this.queryFormMainRows[9].columN_NAME != q.field && q.field) {
            let resultfields = this.allfields.filter(
              (event) => event.columN_NAME == q.field
            );
            this.queryFormMainRows[9] = resultfields[0];
          }
        }
        this.lstmyquery.push(q);
        // if (q != null) {
        //20230821 CCL* bug,addQuery()產出的物件readonly預設為true,columN_CONDITION7~10,要手動改為false

        // this.condsAtColumn3R9[0].value = q.value;
        // }
        console.log(" this.queryFormMainRows", this.queryFormMainRows);
        console.log(" this.lstmyquery", this.lstmyquery);
      }
      this.setQueryFormConditionData();

      this.isReset = false;

      console.log(this.queryFormMainRows);
    },
    /**
     * 查詢條件第二欄 欄位變更事件
     */
    onConditionColumnSet1(e, idx) {
      console.log("onConditionColumnSet1", this.isReset);
      console.log("onConditionColumnSet1", e.selectedItem.value);
      if (!this.isReset) {
        if (e.selectedItem.value != this.queryFormMainRows[idx].columN_NAME) {
          var currentCondition = this.queryFormMainRows[idx].condition;
          let resultfields = this.allfields.filter(
            (event) => event.columN_NAME == e.selectedItem.value
          );
          this.queryFormMainRows[idx] = resultfields[0];
          this.lstmyquery[idx] = resultfields[0];
          this.lstmyquery[idx].condition = currentCondition;
        }
      }
    },

    //20230706 CCL+ 取得固定寫死的預設值
    getlstmyqueryDefaultSetup(columntext) {
      let item = this.columns.find((column) => {
        //  查詢是用哪個條件
        return column.text === columntext;
      });

      //給預設初始值,才會使會使顯示畫面正常
      let querydefaultsetup = {
        type: "text",
        field: item.value, //field 固定寫死
        condition: "", //預設為空白""
        control: "MultipleComboBox", //預設為MultipleComboBox
        value: "", //預設為""
        readonly: true,
        valuelist: [], //20230706 CCL* PS: valuelist的物件型態一定要設為array [],不可是null,'',{}; 不然會異常
      };

      return querydefaultsetup;
    },

    /**
     * 點擊 Query Form 裡面的 query 按鈕事件
     */
    query() {
      //let psize = sessionStorage.getItem("psize")==null ? 10 : parseInt(sessionStorage.getItem("psize"));
      //this.pagesize = psize;

      this.GetGridData();
      this.isQueryFormPopupVisible = false;
    },
    /**
     * 取得 grid view status (LoadField 會呼叫)
     */
    async getGridViewStatus() {
      console.log("getGridViewStatus");
      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };

      //20230807 CCL+ 修正this.groupfields 為空,GetGridStatus()回傳的groupfields值是空的
      if (this.groupfields == null || this.groupfields.length == 0) {
        this.groupfields = sessionStorage.getItem("groupfields");
      }

      const bodyParameters = {
        account: this.loginUserinfo.account,
        reportid: this.reportid,
        companyid: this.loginUserinfo.companyid,
        groupfields: JSON.stringify(this.groupfields),
      };
      this.axios
        .post(
          this.$store.state.axios_url + "/api/Standard/GetGridStatus",
          // this.$store.state.axios_local_url + "/api/Standard/GetGridStatus",
          bodyParameters,
          config
        )
        .then((resp) => {
          if (resp.data.success) {
            let MM = resp.data.message.split("|");
            //設定上一次Columns隱藏表
            let sourceGroupFields = JSON.parse(MM[0]);
            sourceGroupFields.forEach((element) => {
              if (element.standardflag == false) {
                element.standardflag = 0;
              } else if (element.standardflag == true) {
                element.standardflag = 1;
              }
            });
            this.groupfields = sourceGroupFields;
            //設定上一次Query條件
            this.lstmyquery = JSON.parse(MM[1]);

            sessionStorage.setItem(this.storageKey, resp.data.payload);
            const state = JSON.parse(sessionStorage.getItem(this.storageKey)); //JSON.parse(resp.data.payload);//這邊改成call api 把那一包讀出來
            this.dataGrid.state(state);

            //20230804 CCL+ 修正因為讀回getGridViewStatus()把整理過的lstmyquery又蓋回來DB沒整理過的值
            //每次讀回GridViewStatus()都要再呼叫此函式把latmyquery整理一次
            //this.toProcesslstmyquery(this.lstmyquery);
          }
        });
    },
    /**
     * grid DxStateStoring load 事件
     */
    loadState() {
      return JSON.parse(localStorage.getItem(this.storageKey));
    },
    /**
     * 把目前grid狀態存到tempGridStatus字串
     * @param state
     */
    saveState(state) {
      // 這邊會把目前grid狀態存到tempGridStatus字串
      if (state) {
        for (let i = 0; i < state.columns.length; i++) {
          state.columns[i].filterValue = null;
        }
      }
      this.tempGridStatus = JSON.stringify(state);
    },
    /**
     * 儲存 grid 狀態
     * @param {是否儲存GridStatus} updateGridStatus
     */
    saveGridViewStatus(isSaveGridStatus) {
      console.log("=======>>>>>>>>>> saveGridViewStatus", this.lstmyquery);

      const config = {
        headers: {
          Authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      const bodyParameters = {
        account: this.loginUserinfo.account,
        reportid: this.reportid,
        tempGridStatus: isSaveGridStatus == true ? this.tempGridStatus : "",
        //fields: JSON.stringify(this.fields),

        lstmyquery: JSON.stringify(this.lstmyquery),
        groupfields: JSON.stringify(this.groupfields),
        //20230908 CCL-有bug column_select: this.show_COLUMN_SELECT, //20230908 CCL+, COLUMN_SELECT要照勾選的存
      };
      let rest = this.axios.post(
        this.$store.state.axios_url + "/api/Standard/SaveGridStatus",
        bodyParameters,
        config
      );
      if (!rest.success) {
        console.log(rest);
      }
    },
    /**
     * 一段時間後沒動作自動跳轉登出(1hr)
     */
    reCalculate() {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(
        () => this.$router.push({ path: "/" }),
        3600000
      ); // path 為 "/" 既為根目錄，5000為毫秒
      //this.timerId =  setTimeout(() => this.$router.push({ path: "/" }), 10000); // path 為 "/" 既為根目錄，5000為毫秒
    },

    //#region 選單相關

    /**
     * MENU 功能鍵點擊事件
     * @param {點擊的menu按鈕} e
     */
    menuItemClick(e) {
      if (e.itemData != null) {
        this.menuSelectChange(e.itemData.name);

        //20230712 CCL+ 當subMenu Item 選擇時,加上Check Mark icon
        //但是必須排除Query,Report Info,..等等非SubMenu的主Menu Item 的Click
        if (
          e.itemData.name != "Query" &&
          e.itemData.name != "Layout" &&
          e.itemData.name != "Export file" &&
          e.itemData.name != "Filtering" &&
          e.itemData.name != "Pager Mode" &&
          e.itemData.name != "ScrollBar" &&
          e.itemData.name != "Column Resize" &&
          e.itemData.name != "Grid Lines" &&
          e.itemData.name != "Grouping" &&
          e.itemData.name != "Columns" &&
          e.itemData.name != "Styles" &&
          e.itemData.name != "Report Info"
        ) {
          this.subMenuItemSelectChecked(e);
        }
      }
    },
    /**
     * 功能鍵變更
     * @param {新選到的項目名稱} newVal
     */
    menuSelectChange(newVal) {
      if (newVal.includes("Filter") || newVal.includes("Show Header")) {
        this.filtering(newVal);
      } else if (
        newVal.includes("No ScrollBar") ||
        newVal.includes("Frozen Column ")
      ) {
        this.scrollbar(newVal);
      } else if (
        newVal.includes("None") ||
        newVal.includes("Both") ||
        newVal.includes("Horizontal") ||
        newVal.includes("Vertical")
      ) {
        this.gridLines(newVal);
      } else if (
        newVal.includes("CSV") ||
        newVal.includes("PDF") ||
        newVal.includes("Excel")
      ) {
        this.exportGrid(newVal);
      } else if (
        newVal.includes("Office2003Olive") ||
        newVal.includes("Office2003Blue") ||
        newVal.includes("Office2003Silver") ||
        newVal.includes("Office2010Black") ||
        newVal.includes("Office2010Blue") ||
        newVal.includes("Office2010Silver") ||
        newVal.includes("PlasticBlue") ||
        newVal.includes("RedWine") ||
        newVal.includes("SoftOrange") ||
        newVal.includes("Youthful") ||
        newVal.includes("Glass")
      ) {
        if (newVal == "Office2003Olive") {
          // this.customerColumnStyle = "background-color:#FC4 !important";
          this.customerColumnStyle = "ColumnsAutomatic1";
        } else if (newVal == "Office2003Blue") {
          this.customerColumnStyle = "ColumnsAutomatic2";
        } else if (newVal == "Office2003Silver") {
          this.customerColumnStyle = "ColumnsAutomatic3";
        } else if (newVal == "Office2010Black") {
          this.customerColumnStyle = "ColumnsAutomatic4";
        } else if (newVal == "Office2010Blue") {
          this.customerColumnStyle = "ColumnsAutomatic5";
        } else if (newVal == "Office2010Silver") {
          this.customerColumnStyle = "ColumnsAutomatic6";
        } else if (newVal == "PlasticBlue") {
          this.customerColumnStyle = "ColumnsAutomatic7";
        } else if (newVal == "RedWine") {
          this.customerColumnStyle = "ColumnsAutomatic8";
        } else if (newVal == "SoftOrange") {
          this.customerColumnStyle = "ColumnsAutomatic9";
        } else if (newVal == "Youthful") {
          this.customerColumnStyle = "ColumnsAutomatic10";
        } else if (newVal == "Glass") {
          this.customerColumnStyle = "ColumnsAutomatic11";
        }
      } else if (
        newVal.includes("10") ||
        newVal.includes("20") ||
        newVal.includes("30") ||
        newVal.includes("40") ||
        newVal.includes("50") ||
        newVal.includes("Show All Records")
      ) {
        //.log("in PAGING");
        this.pageSizeChange(newVal);
      } else if (newVal.includes("Query")) {
        //20230811 CCL* 由於Ajax postback時間差的關係,必須調整呼叫Getgridviewstatus()的位置至此
        this.showPopup();
        this.getGridViewStatus();
        this.dataGrid.repaint(); //20230828 CCL* 打錯字dataGridRef -> dataGrid
        //this.GetGridData();
      } else if (newVal.includes("hiding/showing")) {
        this.isHiddingShowPopupVisible = true;
        //this.hiddingColumn();
      } else if (newVal.includes("Report Info")) {
        this.isReportInfoPopupVisible = true;
      } else if (newVal.includes("Save As New Report")) {
        this.isSavePopupVisible = true;
      } else if (newVal.includes("Load")) {
        if (this.data.length == 0) {
          this.Load();
        }

        this.getGridViewStatus();
        //const state = JSON.parse(sessionStorage.getItem(this.storageKey));//這邊改成call api 把那一包讀出來
        //this.dataGrid.state(state);
      } else if (newVal.includes("Save")) {
        //這邊呼叫api把tempGridStatus存進去
        //sessionStorage.setItem("fred", JSON.stringify(this.tempGridStatus));
        sessionStorage.setItem(this.storageKey, this.tempGridStatus);
        this.saveGridViewStatus(true);
      } else if (newVal.includes("Disabled")) {
        this.currentMode = "disabled";
        this.allowResizingMode = false;
      } else if (newVal.includes("Control")) {
        this.currentMode = "widget";
        this.allowResizingMode = true;
      } else if (newVal.includes("Next Column")) {
        this.currentMode = "nextColumn";
        this.allowResizingMode = true;
      } else if (newVal.includes("Pivot Grid")) {
        //儲存畫面上條件設定
        sessionStorage.setItem("lstmyquery", JSON.stringify(this.lstmyquery));

        this.groupfields.forEach((element) => {
          if (element.standardflag == false || element.standardflag == 0) {
            element.standardflag = 0;
          } else {
            element.standardflag = 1;
          }
        });

        sessionStorage.setItem("groupfields", JSON.stringify(this.groupfields));
        sessionStorage.setItem("fields", JSON.stringify(this.fields));
        sessionStorage.setItem("allfields", JSON.stringify(this.allfields));

        this.$router.push("/demo2");
      } else {
        // ? "#FC4" : "#CCC"
        console.log(newVal);
      }
    },

    /**
     * 資料筆數變更
     * @param {選單選中項目} newVal
     */
    pageSizeChange(newVal) {
      if (newVal == "Show All Records") {
        this.pagesize = 99999;
      } else {
        sessionStorage.setItem("psize", newVal);
        this.pagesize = parseInt(newVal);
      }
    },
    /**
     * 選單搜尋模式變更
     * @param {選單選中項目} newVal
     */
    filtering(newVal) {
      switch (newVal) {
        case "No Filter":
          this.showHeaderFilter = false;
          this.showFilterRow = false;
          break;
        case "Show Header Filter":
          this.showHeaderFilter = true;
          this.showFilterRow = false;
          break;
        case "Show Filter Row":
          this.showHeaderFilter = false;
          this.showFilterRow = true;
          break;
        case "Show Filter Row Menu":
          this.showHeaderFilter = true;
          this.showFilterRow = true;
          break;
        default:
          this.showHeaderFilter = false;
          this.showFilterRow = false;
      }
    },
    /**
     * 選單凍結項變更
     * @param {選單選中項目} newVal
     */
    scrollbar(newVal) {
      this.nonefix();
      switch (newVal) {
        case "No ScrollBar":
          break;
        case "Frozen Column Set 1":
          this.fixcol(1);
          break;
        case "Frozen Column Set 1~2":
          this.fixcol(2);
          break;
        case "Frozen Column Set 1~3":
          this.fixcol(3);
          break;
        default:
        // this.column1 = false;
        // this.column2 = false;
        // this.column3 = false;
      }
    },
    /**
     * scorllfix設定(disable all)
     */
    nonefix() {
      for (let index = 0; index < this.groupfields.length; index++) {
        //20230811 CCL- if (this.groupfields[index].standardflag === 1) {
        //20230811 CCL* bug srollfix沒作用
        if (this.groupfields[index].standardflag == true) {
          this.groupfields[index].scorllfix = false;
        }
      }
    },
    /**
     * scorllfix設定(enable)
     * @param {數量} limit
     */
    fixcol(limit) {
      let c = 1;
      for (let index = 0; index < this.groupfields.length; index++) {
        //20230811 CCL- if (this.groupfields[index].standardflag === 1) {
        //20230811 CCL* bug srollfix沒作用
        if (this.groupfields[index].standardflag == true) {
          this.groupfields[index].scorllfix = true;
          c++;

          if (c > limit) {
            break;
          }
        }
      }
    },

    /**
     * 選單資料框線變更
     * @param {選單選中項目} newVal
     */
    gridLines(newVal) {
      switch (newVal) {
        case "None":
          this.showColumnLines = false;
          this.showRowLines = false;
          break;
        case "Both":
          this.showColumnLines = true;
          this.showRowLines = true;
          break;
        case "Horizontal":
          this.showColumnLines = false;
          this.showRowLines = true;
          break;
        case "Vertical":
          this.showColumnLines = true;
          this.showRowLines = false;
          break;
        default:
          this.showColumnLines = true;
          this.showRowLines = true;
      }
    },

    /**
     * 匯出報表
     * @param {選單選中項目} newVal
     */
    exportGrid(newVal) {
      if (newVal == "PDF") {
        const doc = new jsPDF();
        exportDataGridToPdf({
          jsPDFDocument: doc,
          component: this.dataGrid,
          customDrawCell: (e) => {
            if (
              e.gridCell.rowType === "data" &&
              e.gridCell.column.dataField === "Picture"
            ) {
              doc.addImage(
                e.gridCell.value,
                "PNG",
                e.rect.x,
                e.rect.y,
                e.rect.w,
                e.rect.h
              );
              e.cancel = true;
            }
          },
        }).then(() => {
          doc.save("xGridView.pdf");
        });
      } else if (newVal == "Excel") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("xGridView");

        exportDataGridToExcel({
          component: this.dataGrid,
          worksheet,
          autoFilterEnabled: true,
        }).then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "xGridView.xlsx"
            );
          });
        });
      } else if (newVal == "CSV") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("xGridView");
        exportDataGridToExcel({
          component: this.dataGrid,
          worksheet: worksheet,
        }).then(function () {
          workbook.csv.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "xGridView.csv"
            );
          });
        });
      }
    },

    /**
     * 匯出事件
     * @param  e
     */
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("DataGrid");

      exportDataGridToExcel({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    },
    /**
     * subMenu Item 選擇時,加上Check Mark icon
     * @param e
     */
    subMenuItemSelectChecked(e) {
      //this.lastSelSubMenuItemObj 儲存的是上一次選擇的item物件elem集合物件
      //當選擇改變時,如果這次選擇的跟上次不同,把上次的<p> elem刪除,
      //本次選的Items內崁<p> elem
      ///const menuobj = e.itemData;
      let elem = e.itemElement;
      let elemChildSpan = elem.children[0]; //取得div 下的 span
      let imgelem = document.createElement("p");
      imgelem.className = "dx-menu-item-text my-item-checkedicon"; //PS: Class dx-menu-item-text一定要加,不然無法顯示item內容
      imgelem.setAttribute("id", "checkitem");

      //第一次點選
      if (this.lastSelSubMenuItemObj == null) {
        //儲存原來cssText
        ///var classattr = elemChildSpan.getAttribute('class');
        ///this.lastSelSubMenuItemObj = classattr;
        ///var newclassattr = classattr + " my-item-checkedicon";
        //elemChildSpan.style.cssText = elemChildSpan.style.cssText + " my-item-checkedicon"; //原來cssText後面加上顯示Icon css Text
        //elemChildSpan.appendChild(imgelem);

        //把本次點選的Item加上<p> Elem
        elemChildSpan.insertBefore(imgelem, elemChildSpan.children[0]);
        //儲存本次點選Item物件
        this.lastSelSubMenuItemObj = elemChildSpan;

        //selitemobj 加上icon
        ///elemChildSpan.setAttribute('class', newclassattr);
        ///menuobj.name = elemChildSpan.innerHTML;//newclassattr;//elemChildSpan.innerHTML;
        ///this.lastSelSubMenuItemObj = menuobj;
      } else {
        //把上次check的物件找出,刪除內部子childNode Element <p>
        let LastClickItemElemSet = this.lastSelSubMenuItemObj;
        let lastcheckitemelem = document.getElementById("checkitem");
        LastClickItemElemSet.removeChild(lastcheckitemelem);

        //把本次點選的Item加上<p> Elem
        elemChildSpan.insertBefore(imgelem, elemChildSpan.children[0]);
        //儲存本次點選Item物件
        this.lastSelSubMenuItemObj = elemChildSpan;

        //this.lastSelSubMenuItemObj.icon = null; //取消前置icon
        //menuobj.name = "AAAA";
        //menuobj.icon = "@/assets/img/Help.png";//require("@/assets/img/Help.png");
        //this.lastSelSubMenuItemObj = menuobj;
      }
    },
    //#endregion

    /**
     * 2.2.2 取得N_RPT_CRITERION.UDT欄位值
     * @param mycriterion
     */
    getUserLastOueryTime: function (mycriterion) {
      this.year = "";
      this.month = "";
      this.day = "";
      this.hh = "";
      this.mm = "";
      this.ss = "";
      this.tailmark = "";
      if (mycriterion.udt !== null) {
        this.QueryUDTTime = mycriterion.udt;

        this.year = this.QueryUDTTime.substring(0, 4);
        this.month = this.QueryUDTTime.substring(4, 6);
        this.day = this.QueryUDTTime.substring(6, 8);
        this.hh = this.QueryUDTTime.substring(9, 11);
        this.mm = this.QueryUDTTime.substring(11, 13);
        this.ss = this.QueryUDTTime.substring(13, 15);

        if (parseInt(this.hh) >= 12) {
          this.tailmark = "PM";
        } else {
          this.tailmark = "AM";
        }

        return (
          this.year +
          "/" +
          this.month +
          "/" +
          this.day +
          " " +
          this.hh +
          ":" +
          this.mm +
          ":" +
          this.ss +
          " " +
          this.tailmark
        );
      } else {
        return "";
      }
    },
    /**
     * Query Form 顯示
     */
    showPopup() {
      this.isQueryFormPopupVisible = true;
    },
    /**
     * Query Form 關閉
     */
    hidePopup() {
      this.isQueryFormPopupVisible = false;
    },
    /**
     * 依名稱將屬性設置為false (關閉 Popup 時使用)
     */
    onQueryHidden(name) {
      this[name] = false;
    },
    /**
     * 檢查 Groupfield 裡面 的欄位standardflag 並更新 Groupfield
     * @param event
     * @param groupField
     */
    checkGroupfields(event, groupField) {
      //20230828 CCL- ,即使無論目前的點擊項目是否以勾選,都要檢查一次,不然只有一個選項的group不會跑下面的code,把group設為顯示
      //20230828 CCL- const isCheck = event.target.checked;

      //20230828 CCL- if (!isCheck) {
      if (
        !groupField.suB_COLUMNS.some(
          (s) => s.standardflag === 1 || s.standardflag
        )
      ) {
        //20230828 CCL-改為設0 groupField.standardflag = false;
        groupField.standardflag = 0; //20230828 CCL+ 改為設0
      }
      //20230828 CCL* 修正Bug,當suB_COLUMNS之中其中一項有顯示,group就要設為顯示; 不然整個group會消失
      else {
        groupField.standardflag = 1;
      }
      //20230828 CCL- }
    },
    /**
     * 顯示 User登入後的MAINSQL (傳入呼叫 GetStandardField 後取得的 mainsql )
     * @param mainsql
     */
    displayUserMainSQLHtml(mainsql) {
      if (mainsql !== null) {
        var allUserMainSQLInfo = "";
        let pSpanlineHead =
          '<p><span style="font-size: medium; color: #000000" >';
        let PSpanlineTail = "</span></p>";

        allUserMainSQLInfo +=
          pSpanlineHead + "MAINSQL=" + mainsql + PSpanlineTail;

        this.userMainSQLInfoHtml = allUserMainSQLInfo;
      }
    },
    //20230822 CCL+ 取得User按Query後Condition組合出的CombineSQL //////////////////////////
    /**
     * 組合出的CombineSQL 並顯示 (傳入取得User按Query後Condition)
     * @param combinesql
     */
    displayUserCombineSQLHtml(combinesql) {
      if (combinesql != "") {
        var allUserCombineSQLInfo = "";
        let pSpanlineHead =
          '<p><span style="font-size: medium; color: #ff0000; font-weight: bold;" >';
        let PSpanlineTail = "</span></p>";

        allUserCombineSQLInfo +=
          pSpanlineHead + "SQL=" + combinesql + PSpanlineTail;

        this.userCombineSQLInfoHtml = allUserCombineSQLInfo;
      }
    },
    /**
     * 讀取UserAgentInfo並顯示 (傳入localStorage 中的UserAgentInfo)
     * @param {localStorage UserAgentInfo} userAgentInfo
     */
    displayUserAgenInfo(userAgentInfo) {
      if (userAgentInfo !== null) {
        var allAgentInfo = "";
        let pSpanlineHead =
          '<p><span style="font-size: medium; color: #000000" >';
        let PSpanlineTail = "</span></p>";

        allAgentInfo +=
          pSpanlineHead + "Mode=" + userAgentInfo.Mode + PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead + "Device=" + userAgentInfo.Device + PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead + "OSName=" + userAgentInfo.OSName + PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead +
          "BrowserName=" +
          userAgentInfo.BrowserName +
          PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead +
          "BrowserVersion=" +
          userAgentInfo.BrowserVersion +
          PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead +
          "Orientation=" +
          userAgentInfo.Orientation +
          PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead + "OnTouch=" + userAgentInfo.OnTouch + PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead + "Width=" + userAgentInfo.screenWidth + PSpanlineTail;
        allAgentInfo +=
          pSpanlineHead +
          "Height=" +
          userAgentInfo.screenHeight +
          PSpanlineTail;

        this.userAgentInfoHtml = allAgentInfo;
      }
    },
    //20230801 CCL+ 改成按下Query Button後才更新畫面 && 寫column_select項到DB
    /**
     * hidden/show popup 視窗裡的 clear 按鈕事件
     * @param pgroupfields
     */
    clearColumns(pgroupfields) {
      var i = 0;
      var j = 0;

      for (i = 0; i < pgroupfields.length; i++) {
        var Sub_Columns = pgroupfields[i].suB_COLUMNS;
        for (j = 0; j < Sub_Columns.length; j++) {
          //組合出新的pivoT_COLUMN_SELECT
          if (Sub_Columns[j].standardflag == 1) {
            Sub_Columns[j].standardflag = 0;
          }
        }

        //20230828 CCL*修正,當所有suB_COLUMNS都Hidden時,所屬的groupField Group也要設為0 隱藏
        pgroupfields[i].standardflag = 0;
      }
    },
    /**
     * hide & show 裡的submit 點擊事件
     */
    //20230803 CCL+ datagridview元件的顯示隱藏是存在在state內寫入DB的ClientLayout內
    //以"clientlayout@visibleIndex:visible:width:sortorder"的方式儲存
    //20230907 CCL-, 組出column_select saveHideShowColumns() {
    saveHideShowColumns() {
      //20230907 CCL+, 組出column_select
      //this.getColumnSelect(pgroupfields);
      sessionStorage.setItem(this.storageKey, this.tempGridStatus);
      this.saveGridViewStatus(true);

      //關閉視窗
      this.onQueryHidden("isHiddingShowPopupVisible");
    },

    //Equal
    /**
     * 查詢視窗第三個欄位 (條件) 變更事件
     * ( = <= >= like ...等)
     */
    onCondColumnSet(e, idx) {
      // console.log("onCondColumnSet", e + "---" + idx);
      var sel = e.value;
      this.queryFormMainRows[idx].condition = sel;
    },

    //#region 查詢條件下拉建立事件

    onCustomItemCreating_row0(e) {
      // console.log(`onCustomItemCreating:: `, this.isInOnCloseDropDown);
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R0.splice(
            0,
            this.condsAtColumn3R0.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row1(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R1.splice(
            0,
            this.condsAtColumn3R1.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row2(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R2.splice(
            0,
            this.condsAtColumn3R2.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row3(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R3.splice(
            0,
            this.condsAtColumn3R3.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row4(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R4.splice(
            0,
            this.condsAtColumn3R4.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row5(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R5.splice(
            0,
            this.condsAtColumn3R5.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row6(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R6.splice(
            0,
            this.condsAtColumn3R6.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row7(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R7.splice(
            0,
            this.condsAtColumn3R7.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row8(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R8.splice(
            0,
            this.condsAtColumn3R8.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },
    onCustomItemCreating_row9(e) {
      if (!this.isInOnCloseDropDown) {
        if (e && e.text) {
          const newCustomItem = { text: e.text, value: e.text };
          this.condsAtColumn3R9.splice(
            0,
            this.condsAtColumn3R9.length,
            newCustomItem
          );
        }
      }
      this.isInOnCloseDropDown = false;
    },

    //#endregion

    //#region 查詢視窗 combox 點擊相關

    /**
     * 關閉combox 下拉
     */
    onCombocClosed(event, name) {
      this.isInOnCloseDropDown = true;
      // this.isFirstInDropdownFocus = true;
      this.currentComboxValue = "";

      // switch (name) {
      //   case "condsAtColumn3R0":
      //     this.condsAtColumn3R0 = [{ text: "", value: "" }];
      //     // this.condsAtColumn3R0[0].text = "";
      //     break;
      //   case "condsAtColumn3R1":
      //     this.condsAtColumn3R1 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R2":
      //     this.condsAtColumn3R2 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R3":
      //     this.condsAtColumn3R3 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R4":
      //     this.condsAtColumn3R4 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R5":
      //     this.condsAtColumn3R5 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R6":
      //     this.condsAtColumn3R6 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R7":
      //     this.condsAtColumn3R7 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R8":
      //     this.condsAtColumn3R8 = [{ text: "", value: "" }];
      //     break;
      //   case "condsAtColumn3R9":
      //     this.condsAtColumn3R9 = [{ text: "", value: "" }];
      //     break;
      //   default:
      //     break;
      // }
    },
    /**
     * 開啟combox 下拉
     */
    onComboxOpened(e) {
      //this.isInOnCloseDropDown = false;
      //this.isChangeDropDown = false;
      //this.currentComboxValue = "";
      console.log("onComboxOpened", e);
    },
    /**
     * combox 變更內容
     */
    onComboxValueChange(e, name) {
      
      // //this.isChangeDropDown = false;
      // this.isFirstInDropdownFocus = true;
      // this.isInOnCloseDropDown = false;
      switch (name) {
        case "condsAtColumn3R0":
          this.condsAtColumn3R0[0].value = "DEMO"; //e.value; //event.event.target.value;
          break;
        case "condsAtColumn3R1":
          this.condsAtColumn3R1[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R1[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R2":
          this.condsAtColumn3R2[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R2[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R3":
          this.condsAtColumn3R3[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R3[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R4":
          this.condsAtColumn3R4[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R4[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R5":
          this.condsAtColumn3R5[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R5[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R6":
          this.condsAtColumn3R6[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R6[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R7":
          this.condsAtColumn3R7[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R7[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R8":
          this.condsAtColumn3R8[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R8[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        case "condsAtColumn3R9":
          this.condsAtColumn3R9[0].text = e.value ? e.value :""; //event.event.target.value;
          this.condsAtColumn3R9[0].value = e.value ? e.value :""; //event.event.target.value;
          break;
        default:
          break;
      }
    },
    /**
     * 焦點 點入combox
     */
    onComboxFocusin(event, name) {
      //判斷是否是第一次進focus
      // if (this.isFirstInDropdownFocus) {
      //   this.isFirstInDropdownFocus = false;
      this.isInOnCloseDropDown = false;
      this.currentComboxValue = event.event.target.value;
      // }
    },

    //#endregion
  },

  watch: {},

  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxExport,
    DxSelectBox,
    DxMenu,
    DxPopup,
    DxScrollView,
    DxPosition,
    // DxToolbarItem,
    DxStateStoring,
    //DxLoadPanel,
    //DxDropDownBox,
    //DxList,
    DxSorting,
    DxScrolling,
  },
};
</script>

<style lang="scss">
//20230814 CCL+ Report last Updated End Time
.reportlastupdendtime {
  margin-left: 10px;
  font-weight: normal;
  font-size: 0.8em;
}

.dx-menu-base .dx-icon {
  width: 16px !important;
  height: 16px !important;
}

.dx-header-row {
  .ColumnsAutomatic1 {
    color: #000;
    font-weight: normal !important;
    border: 1px solid #a4b26d !important;
    background: rgb(184, 198, 146);
    background: linear-gradient(
      0deg,
      rgba(184, 198, 146, 1) 0%,
      rgba(184, 198, 146, 1) 36%,
      rgba(215, 224, 186, 1) 61%,
      rgba(244, 247, 222, 1) 100%
    );
  }

  .ColumnsAutomatic2 {
    // background-color: rgb(96, 226, 150) !important;
    color: #000;
    font-weight: normal !important;
    border: 1px solid #4f93e3 !important;
    background: rgb(148, 182, 232);
    background: linear-gradient(
      0deg,
      rgba(148, 182, 232, 1) 0%,
      rgba(152, 186, 233, 1) 45%,
      rgba(175, 203, 240, 1) 55%,
      rgba(219, 234, 253, 1) 100%
    );
  }

  .ColumnsAutomatic3 {
    color: #000;
    font-weight: normal !important;
    border: 1px solid #9c9ab7 !important;
    background: rgb(156, 154, 183);
    background: linear-gradient(
      0deg,
      rgba(156, 154, 183, 1) 0%,
      rgba(165, 163, 190, 1) 36%,
      rgba(229, 229, 239, 1) 61%,
      rgba(243, 244, 250, 1) 100%
    );
  }

  .ColumnsAutomatic4 {
    color: #fff;
    font-weight: normal !important;
    border: 1px solid #484848 !important;
    background: rgb(109, 108, 110);
    background: linear-gradient(
      0deg,
      rgba(109, 108, 110, 1) 0%,
      rgba(109, 108, 110, 1) 36%,
      rgba(117, 116, 117, 1) 61%,
      rgba(117, 116, 117, 1) 100%
    );
  }

  .ColumnsAutomatic5 {
    color: #000;
    font-weight: normal !important;
    border: 1px solid #8ba0bc !important;

    background: rgb(228, 239, 250);
    background: linear-gradient(
      0deg,
      rgba(228, 239, 250, 1) 0%,
      rgba(228, 239, 250, 1) 36%,
      rgba(237, 244, 251, 1) 61%,
      rgba(237, 244, 251, 1) 100%
    );
  }

  .ColumnsAutomatic6 {
    color: #3c3c3c;
    font-weight: normal !important;
    border: 1px solid #a5acb5 !important;
    background: rgb(231, 235, 239);
    background: linear-gradient(
      0deg,
      rgba(231, 235, 239, 1) 0%,
      rgba(231, 235, 239, 1) 36%,
      rgba(244, 245, 249, 1) 61%,
      rgba(244, 245, 249, 1) 100%
    );
  }

  .ColumnsAutomatic7 {
    color: #fff;
    font-weight: normal !important;
    border: 1px solid #2c4182 !important;
    background: rgb(65, 86, 152);
    background: linear-gradient(
      0deg,
      rgba(65, 86, 152, 1) 0%,
      rgba(65, 86, 152, 1) 36%,
      rgba(85, 107, 173, 1) 61%,
      rgba(92, 114, 180, 1) 100%
    );
  }

  .ColumnsAutomatic8 {
    color: #fff;
    font-weight: normal !important;
    border: 1px solid #8a0a37 !important;
    background: rgb(242, 122, 164);
    background: linear-gradient(
      0deg,
      rgba(242, 122, 164, 1) 0%,
      rgba(207, 50, 105, 1) 31%,
      rgba(170, 18, 72, 1) 90%,
      rgba(232, 169, 191, 1) 100%
    );
  }

  .ColumnsAutomatic9 {
    color: #fff;
    font-weight: normal !important;
    border: 1px dotted #5c5c5c !important;
    background-color: #969696 !important;
  }

  .ColumnsAutomatic10 {
    color: #fff;
    font-weight: normal !important;
    border: 1px solid White !important;
    background-color: #9cc525 !important;
  }

  .ColumnsAutomatic11 {
    color: #000;
    font-weight: normal !important;
    border: 1px solid #7eacb1 !important;
    background: rgb(192, 221, 224);
    background: linear-gradient(
      0deg,
      rgba(192, 221, 224, 1) 0%,
      rgba(196, 226, 229, 1) 53%,
      rgba(208, 236, 238, 1) 66%,
      rgba(222, 242, 243, 1) 100%
    );
  }
}

.custom-table {
  width: 100%;

  tr:nth-child(odd) {
    background-color: #fdfdfd;
  }

  td {
    padding: 5px;

    input {
      border: none;
      width: 100%;
      background: rgba(51, 51, 51, 0.05);
      border-radius: 4px;
      border-bottom: 1px solid #818181;
      line-height: 50px;
    }
  }
}

.query-table {
  .dx-texteditor-buttons-container {
    border-top: 1px solid #a0a0a0;
    border-right: 1px solid #a0a0a0;
  }
  .dx-texteditor-input {
    background-color: #fff;
    padding: 0 !important;
    border: 1px solid #a0a0a0;
    min-height: unset;
    border-radius: 0;
    height: 25px;
  }

  .dx-widget.dx-button-mode-contained.dx-button-normal.dx-dropdowneditor-button {
    margin-right: 0;
  }
}

/**查詢視窗 */
@media only screen and (max-device-width: 768px) {
  /* Styles */
  .queryForm {
    width: 500px !important;
  }
}

//20230712 CCL+ 當subMenu Item 選擇時,加上Check Mark icon
.my-item-checkedicon {
  background-image: url("../assets/img/check.png");
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 0px;
  background-position: left;
}
</style>
