<template>
    <div class="content">
        <div class="page-title">
            <div class="title">Company Management</div>
            <div class="button-wrap">
                <button>
                    <img src="~@/assets/img/Shortcut_Favorites.png" alt="" />
                </button>
                <button>
                    <img src="~@/assets/img/Shortcut_MovingTop.png" alt="" />
                </button>
            </div>
        </div>
        <div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h6 class="panel-title">Function Link</h6>
            </div>
            <div class="panel-body">
              <a class="btn btn-success" :href="buildAddNewRouterLink()">
                <span class="label">Add New</span>
              </a>
            </div>
          </div>
          <p/>
        </div>
        <div>
          <p>
            <span class="label label-info">Company: {{ this.queryCompanyId }}</span>
          </p>
          <DxDataGrid
            key-expr="id"
            :ref="dataGridRefKey"
            :data-source="metafiles"
            :remote-operations="false"
            :allow-column-reordering="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :show-row-lines="true"
            :show-borders="true"
            :hover-state-enabled="true"
            :width="'100%'"
            @selection-changed="onGridSelectionChanged"
          >
            <DxSelection
              mode="single"
              select-all-mode="page"
            />
            <DxColumn
              data-field="iconLabel"
              caption="ICONLABEL"
              data-type="text"
              width="250"
            />
            <DxColumn
              data-field="urlPath"
              caption="PATH"
              data-type="text"
              width="300"
            />
            <DxColumn
              data-field="reportType"
              data-type="text"
              caption="REPORT TYPE"
            />
            <DxColumn
              data-field="id"
              data-type="text"
              caption="METAFILE SETTING"
              :allowSorting="false"
              cell-template="editCellTemplate"
            />
            <template #editCellTemplate="{ data }">
              <a class="btn btn-sm btn-info" :href="buildEditRouterLink(data.value)">Edit</a>
            </template>
            <DxSelection
              :select-all-mode="allMode"
              :show-check-boxes-mode="checkBoxesMode"
              mode="multiple"
            />
            <DxPager
              :visible="true"
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="true"
              :show-info="false"
              :show-navigation-buttons="true"
            />
            <DxPaging :page-size="10"/>
          </DxDataGrid>
          <p/>
          <router-link class="btn btn-sm btn-warning" to="/management/company/index">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>&nbsp;<span style="color: white;">Back</span>
          </router-link>
          &nbsp;&nbsp;
          <DxButton text="Delete" class="btn btn-sm btn-danger" @click="RemoveSelectedRow()" />
        </div>
    </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxSelection
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';

const dataGridRefKey = 'data-grid';

const pageSizes = [10, 25, 50, 100];
const apiConfig = {
  headers: {
    "Authorization": "bearer " + sessionStorage.getItem("token"),
    "Content-Type": "application/json"
  }
};
const onGridSelectionChanged = (e) => {
  console.log(e.selectedRowKeys);
  selectedDeletingIds = e.selectedRowKeys;
  console.log(selectedDeletingIds);
};

let metafiles = [];
let queryCompanyId = '';
let selectedDeletingIds = [];

export default {
    data() {
        return {
          dataGridRefKey,
          pageSizes,
          apiConfig,
          metafiles,
          queryCompanyId,
          selectedDeletingIds,
          onGridSelectionChanged
        }
    },
    mounted() {
      this.GetQueryString();
      this.LoadData();
    },

    components: {
        DxDataGrid,
        DxColumn,
        DxPager,
        DxPaging,
        DxSelection,
        DxButton
    },

    computed:{
      // dataGrid: () => {
      //       return this.$refs[dataGridRefKey].instance;
      // }
    },

    methods: {
      async LoadData() {
        let resp = await this.axios.get(
          // this.$store.state.axios_local_url + `/api/metafile/${this.queryCompanyId}`,
          this.$store.state.axios_url + `/api/metafile/${this.queryCompanyId}`,
          {},
          apiConfig
        );
        console.log(`===resp===`)
        console.log(resp);
        if (resp.status === 200 && resp.data.length > 0) {
          console.log(`===resp.data===`);
          console.log(resp.data);
          this.metafiles = [];
          this.metafiles = resp.data;
        }
      }, // end of LoadData()

      GetQueryString() {
        let qCompanyId = this.$route.query.companyid;
        console.log(`===qCompanyId===`);
        console.log(qCompanyId);
        if (qCompanyId === undefined) {
          qCompanyId = "DEMO";
        }
        this.queryCompanyId = qCompanyId;
        console.log(`===this.queryCompanyId===`);
        console.log(this.queryCompanyId);
      }, // end of GetQueryString()

      async RemoveSelectedRow() {
        console.log(`===selectedDeletingIds===`);
        console.log(selectedDeletingIds);
        console.log(`===queryCompanyId===`);
        console.log(this.queryCompanyId);
        console.log(`===this.metafiles===`);
        console.log(this.metafiles);
        if (selectedDeletingIds.length === 0) {
          return;
        }
        
        const resp = await this.axios.post(
          // this.$store.state.axios_local_url + `/api/metafile/delete`,
          this.$store.state.axios_url + `/api/metafile/delete`,
          { "ids": selectedDeletingIds },
          apiConfig
        );
        console.log(`===delete resp===`)
        console.log(resp);
        
        if (resp.status === 200) {
          this.LoadData();
        }



      }, // end of RemoveSelectedRow

      refreshDataGrid() {
        this.dataGrid.refresh();
      }, // end of refreshDataGrid

      buildAddNewRouterLink() {
        return `/#/management/company/metafile-new?companyid=${this.queryCompanyId}`;
      }, // end of buildAddNewRouterLink

      buildEditRouterLink(id) {
        return `/#/management/company/metafile-edit?key=${id}`;
      }

    },

    watch: {},
};
</script>